import Spinner from '../../../base/spiner';
import { Table } from 'antd';

const ModalTable = ({ accionModalSku, infoModalProduct }) => {

    const columns = [
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Talla",
            dataIndex: "talla",
            key: "talla",
        },
        {
            title: "Color",
            dataIndex: "color",
            key: "color",
        },
        {
            title: "Enviado a VTEX",
            dataIndex: "is_active",
            key: "is_active",
            render: (isActive) => (isActive ? "ENVIADO" : "POR ENVIAR"),
        },
    ];

    return (
        <div>
            <div
                className="modal"
                style={{ display: 'block' }}
                id="exampleModalCenter"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Detalles del Producto</h5>
                            <div className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={accionModalSku}>
                            </div>
                        </div>
                        <div className="modal-body my-4">
                            <Table columns={columns} dataSource={infoModalProduct} pagination={false} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show" style={{ zIndex: '1000' }}></div>
        </div>
    );
};

export default ModalTable;
