import React, { useState, useEffect } from 'react';
import Spinner from '../../../base/spiner';
import DataTable from 'react-data-table-component';
import Call from '../../../../config/Call';
import Swal from 'sweetalert2';

const TablaCarga = ({showModal, infoReferencia, setInfoReferencia}) => {
    const [sp, setSp] = useState(false);
    const [columns, setColumns] = useState([]);
    const [cargaData, setCargaData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const desktopColumns = [
        {
            name: 'Fecha',
            selector: row => {
                if (!row || !row.created) return 'No se encontraron datos';
                return new Date(row.created).toISOString().split('T')[0];
            },
            sortable: true,
        },
        {
            name: 'Id',
            selector: row => row?.id ?? 'No se encontraron datos',
            sortable: true,
        },
        {
            name: 'Cantidad',
            selector: row => row?.cantidad_referencias ?? 'No se encontraron datos',
            sortable: true,
        },
        {
            name: 'Procesados',
            selector: row => row?.cantidad_referencia_processadas ?? 'No se encontraron datos',
            sortable: true,
        },
        {
            name: 'Errores',
            selector: row => row?.cantidad_erroneos ?? 'No se encontraron datos',
            sortable: true,
        },
        {
            name: 'Estado',
            selector: row => row?.estado_detalle?.descripcion ?? 'No se encontraron datos',
            cell: row => (
                <div className="text-capitalize"  >
                    {row?.estado_detalle.descripcion.toLowerCase() ?? 'No se encontraron datos'}
                </div>
            ),
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <div className="text-capitalize btn-info2 py-2" onClick={() => moreInformation(row)}>
                    <i className="fas fa-eye"></i>
                </div>
            ),
            sortable: true,
        },
    ];

    const mobileColumns = [
        {
            name: 'Fecha',
            selector: row => {
                if (!row || !row.created) return 'No se encontraron datos';
                return new Date(row.created).toISOString().split('T')[0];
            },
            sortable: true,
        },
        {
            name: 'Errores',
            selector: row => row.cantidad_erroneos,
            sortable: true,
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                paddingLeft: '1px',
                paddingRight: '1px',
                fontSize: '15px',
            },
        },
        cells: {
            style: {
                paddingLeft: '2px',
                paddingRight: '2px'
            },
        },
    };

    useEffect(() => {
        const updateColumns = () => {
            if (window.innerWidth < 999) {
                setColumns(mobileColumns);
            } else {
                setColumns(desktopColumns);
            }
        };
        window.addEventListener('resize', updateColumns);
        updateColumns();

        return () => window.removeEventListener('resize', updateColumns);
    }, []);

    const moreInformation=(infoRef)=>{
        setInfoReferencia(infoRef)

    }
    const CargaList = async (page, limit) => {
        try {
            setSp(true);
            const res = await Call('GET', `api/producto_vtex/lista-cargue/?page=${page}&page_size=${limit}`, true, null, false);
            if (res && res.data) {
                setCargaData(res.data.results);
                setTotalRows(res.data.count);
            }
        } catch (error) {
            Swal.fire('Error', 'Hubo un error interno', 'error');
        } finally {
            setSp(false);
        }
    };

    useEffect(() => {
        if(!showModal)
            CargaList(currentPage, rowsPerPage)
    }, [currentPage, rowsPerPage, showModal]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    }

    const ExpandedComponent = ({ data }) => (
        <div className="fs-14 m-regular" style={{padding: '10px 20px'}}>
            <p className="m-regular"><strong>Cantidad:</strong> {data?.cantidad_referencias}</p>
            <p className="text-capitalize m-regular">
                <strong>Estado:</strong> {data?.estado_detalle && data.estado_detalle.descripcion.toLowerCase()}</p>
            <div className="text-capitalize btn-info2 py-2 fit-content" onClick={() => moreInformation(data)}>
                <i className="fas fa-eye"></i>
            </div>
        </div>
    );

    const NoDataComponent= (
        <div className="ticket-empty-modal pt-4">
            <div><i className="fas fa-box-open fs-20"></i></div>
            <div className="text-empty">En este momento no hay nada para mostrar</div>
        </div>
    )

    return (
        <div className="px-4 mt-3">
            {sp ? <Spinner/> : ''}
            <DataTable
                data={cargaData}
                columns={columns}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    paginationPerPage={rowsPerPage}
                    customStyles={customStyles}
                    responsive={true}
                    expandableRows={window.innerWidth < 999}
                    expandableRowsComponent={ExpandedComponent}
                    paginationComponentOptions={paginationComponentOptions}
                    noDataComponent={NoDataComponent}
            />
        </div>
    );
};

export default TablaCarga;
