import { useState } from 'react';
import Call from "../../../../config/Call";
import Spinner from '../../../base/spiner';

const ModalEdit = ({ accionModal, infoModalProduct }) => {
    const [formData, setFormData] = useState({ ...infoModalProduct });
    const [sp, setSp] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRegenerate = async () => {
        try {
          setSp(true);
          const { data } = await Call('POST', `/api/producto_vtex/referencias/generar/${formData.modelo}/`, true, null, false);
          setFormData({
            ...formData,
            name: data.name,
            description: data.description,
            description_short: data.description_short,
            meta_tag_description: data.meta_tag_description,
            vista_rapida: data.vista_rapida,
            title: data.title,
          });
          setSp(false);
        } catch (error) {
          setSp(false);
        }
      };

    const handleUpdate = async () => {
        try {
            setSp(true);
            const data = {
                name: formData.name,
                description: formData.description,
                description_short: formData.description_short,
                meta_tag_description: formData.meta_tag_description,
                vista_rapida: formData.vista_rapida,
                title: formData.title,
            }
            await Call('PATCH', `/api/producto_vtex/referencias/${formData.id}/`, true, data, false);
            setSp(false);
        } catch (error) {
            setSp(false);
        }
    };

    return (
        <div>
            {sp ? <Spinner/> : ''}
            <div
                className="modal"
                style={{ display: 'block' }}
                id="exampleModalCenter"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Editar producto</h5>
                            <div className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={accionModal}>
                            </div>
                        </div>
                        <div className="modal-body my-4">
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="referencia" className="m-regular fs-14 fw-400">Referencia</label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            value={formData.modelo}
                                            name="modelo"
                                            id="referencia"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="titulo" className="m-regular fs-14 fw-400">Título</label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            value={formData.name}
                                            name="name"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="descripcion" className="m-regular fs-14 fw-400">Descripción larga</label>
                                        <textarea
                                            className="form-control form-control-sm"
                                            value={formData.description}
                                            name="description"
                                            onChange={handleInputChange}
                                            id="descripcion"
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="tag" className="m-regular fs-14 fw-400">Vista rápida</label>
                                        <textarea
                                            className="form-control form-control-sm"
                                            value={formData.vista_rapida}
                                            name="vista_rapida"
                                            onChange={handleInputChange}
                                            id="tag"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="descripcion" className="m-regular fs-14 fw-400">Descripción corta</label>
                                        <textarea
                                            className="form-control form-control-sm"
                                            value={formData.description_short}
                                            name="description_short"
                                            onChange={handleInputChange}
                                            id="descripcion"
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="tag" className="m-regular fs-14 fw-400">Tag</label>
                                        <textarea
                                            className="form-control form-control-sm"
                                            value={formData.meta_tag_description}
                                            name="meta_tag_description"
                                            onChange={handleInputChange}
                                            id="tag"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="d-flex justify-content-center gap-20 w-100">
                                <button className="btn-general-green" onClick={handleRegenerate}>Regenerar</button>
                                <button className="btn-info2" onClick={handleUpdate}>Actualizar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show" style={{ zIndex: '1000' }}></div>
        </div>
    );
};

export default ModalEdit;
