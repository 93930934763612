import React, { useState, useEffect } from 'react';
import Spinner from '../../../base/spiner';
import DataTable from 'react-data-table-component';
import Call from '../../../../config/Call';
import Swal from 'sweetalert2';
import { Select, Space, Input } from 'antd';
import EditModal from './EditModal';

const TablaCompletados = () => {
    const [sp, setSp] = useState(false);
    const [columns, setColumns] = useState([]);
    const [completadosData, setcompletadosData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [isSendComerssia, setIsSendComerssia] = useState(false);
    const [searchReferencia, setSearchReferencia] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const desktopColumns = [
        {
            name: 'Fecha',
            selector: row => {
                if (!row || !row.created) return 'No se encontraron datos';
                return new Date(row.created).toISOString().split('T')[0];
            },
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row?.name ?? 'No se encontraron datos',
            sortable: true,
        },
        {
            name: 'Descripción corta',
            selector: row => row?.description_short ?? 'No se encontraron datos',
            sortable: true,
            align: 'right',
        },
        {
            name: 'Referencia',
            selector: row => row?.modelo ?? 'No se encontraron datos',
            sortable: true,
            align: 'center',
        },
        {
            name: 'Id cargue',
            selector: row => row?.cargue_id?.id ?? 'No se encontraron datos',
            sortable: true,
            align: 'center',
        },
        {
            name: 'Creado en VTEX',
            selector: row => {
                if (row?.is_send_comerssia === true) return 'Sí';
                if (row?.is_send_comerssia === false) return 'No';
                return 'No se encontraron datos';
            },
            sortable: true,
            align: 'center',
        },
        {
            name: 'Acciones',
            selector: row => row?.modelo ?? 'No se encontraron datos',
            sortable: true,
            align: 'center',
            cell: row => (
                row?.is_send_comerssia === false ? (
                    <div className="btn-info2 py-2" onClick={() => infoModalEdit(row)}>
                        Editar
                    </div>
                ) : null
            ),
        },
    ];

    const mobileColumns = [
        {
            name: 'Fecha',
            selector: row => {
                if (!row || !row.created) return 'No se encontraron datos';
                return new Date(row.created).toISOString().split('T')[0];
            },
            sortable: true,
        },
        {
            name: 'Referencia',
            selector: row => row?.modelo ?? 'No se encontraron datos',
            sortable: true,
        },
    ];

    const customStyles = {
        cells: {
            style: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        },
        headCells: {
            style: {
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '15px',
            },
        },
    };

    useEffect(() => {
        const updateColumns = () => {
            if (window.innerWidth < 999) {
                setColumns(mobileColumns);
            } else {
                setColumns(desktopColumns);
            }
        };
        window.addEventListener('resize', updateColumns);
        updateColumns();

        return () => window.removeEventListener('resize', updateColumns);
    }, []);

    const CompletadosList = async (page, limit) => {
        try {
            setSp(true);
            const res = await Call(
                'GET',
                `api/producto_vtex/referencias/history/?page=${page}&page_size=${limit}&is_send_comerssia=${isSendComerssia}&search=${searchReferencia}`,
                true,
                null,
                false
            );
            if (res && res.data) {
                setcompletadosData(res.data.results);
                setTotalRows(res.data.count);
            }
        } catch (error) {
            Swal.fire('Error', 'Hubo un error interno', 'error');
        } finally {
            setSp(false);
        }
    };

    useEffect(() => {
        CompletadosList(currentPage, rowsPerPage);
    }, [isSendComerssia, searchReferencia, currentPage, rowsPerPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const handleChange = (value) => {
        setIsSendComerssia(value);
    };

    const handleChangeReferencia = (e) => {
        const value = e.target.value;
        setSearchReferencia(value);
    };

    const infoModalEdit = (row) => {
        setSelectedRow(row);
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setSelectedRow(null);
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const ExpandedComponent = ({ data }) => (
        <div className="fs-14 m-regular" style={{ padding: '10px 20px'}}>
            <p className="m-regular"><strong>Nombre:</strong> {data.name && data.name.toLowerCase()}</p>
            <p className="m-regular"><strong>Descripción corta:</strong> {data.description_short && data.description_short.toLowerCase()}</p>
        </div>
    );

    const NoDataComponent= (
        <div className="ticket-empty-modal pt-4">
            <div><i className="fas fa-box-open fs-20"></i></div>
            <div className="text-empty">En este momento no hay nada para mostrar</div>
        </div>
    )

    return (
        <div className="px-4 mt-3">
            {sp ? <Spinner /> : ''}
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <Input
                    placeholder="Buscar por referencia"
                    style={{
                        width: 200,
                        padding: '5px',
                    }}
                    onChange={handleChangeReferencia}
                />
                <Select
                    defaultValue={false}
                    style={{
                        width: 200,
                    }}
                    onChange={handleChange}
                    options={[
                        {
                            value: false,
                            label: 'No enviado a vtex',
                        },
                        {
                            value: true,
                            label: 'Enviado a vtex',
                        },
                    ]}
                />
            </div>
            <Space wrap></Space>
            <EditModal isVisible={isModalVisible} data={selectedRow} onClose={closeModal} />
            <DataTable
                data={completadosData}
                columns={columns}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                paginationPerPage={rowsPerPage}
                customStyles={customStyles}
                responsive={true}
                expandableRows={window.innerWidth < 999}
                expandableRowsComponent={ExpandedComponent}
                paginationComponentOptions={paginationComponentOptions}
                noDataComponent={NoDataComponent}
            />
        </div>
    );
};

export default TablaCompletados;
