import {Routes, Route } from 'react-router-dom';

import {Formularios} from '../components/formularios/index.js';
import ListFormularios from '../components/formularios/listFormularios';
import {Comunidad} from '../components/base/Comunidad';
import AuditoriaLotes from '../components/produccion/autoriaLotes/index'
import UbicacionInventario from '../components/bodega/ubicacionesInventario/index'
import ConsultarUbicacion from '../components/bodega/consultar/index'
import Citas from '../components/bodega/citas/index.js'
import Mercar from '../components/bodega/mercar/index'
import Abastecimiento from '../components/producto/abastecimiento/index'
import Generar from '../components/producto/predistribucion/index.js'
import EnviosPendientes from '../components/envios/enviosPendientes'
import Traslados from '../components/tiendas/traslados/index'
import Habladores from '../components/tiendas/habladores/index'
import CrearHabladores from '../components/tiendas/habladores/createTalkers'
import VerHablador from '../components/tiendas/habladores/verSpeaker'
import Sondeos from '../components/tiendas/sondeos'
import Presupuestos from '../components/tiendas/presupuestos/index.js';
import PresupuestosVendedores from '../components/tiendas/presupuestos/sellersIndex.js'
import SondeosHistorial from '../components/tiendas/sondeos/historial'
import ReposicionInventario from '../components/tiendas/reposicion_inventario/IndexPage.js';
import Ingresos from '../components/gestion-humana/controlIngresos/index.js';
import Page404 from '../components/base/page404';
import Tareas from '../components/tareas/index.js';
import Index from '../components/vtex/carga_vtex/IndexPage.js';
const redirectIntranet = (Component) => {
  window.location.replace(process.env.REACT_APP_MENU_URL)
      
}

const AdminRouters = ()=>{
    return(
        <Routes>
            <Route path='/tareas/:id?' element={<Tareas/>}/>
            <Route path='/formularios/' element={<ListFormularios/>}/>
            <Route path='/formularios/:id?' element={<Formularios/>}/>
            <Route path='/produccion/auditoria-lotes/:id?' element={<AuditoriaLotes/>}/>
            <Route path='/bodega/ubicacion-inventario/:lote?/:cedi?/:ubicacion?/:destino?/:ref?/:tipo?/:undnecesarias?' element={<UbicacionInventario/>}/>
            <Route path='/bodega/consultar-ubicacion' element={<ConsultarUbicacion/>}/>
            <Route path='/bodega/mercar/:lote?/:tienda?' element={<Mercar/>}/>
            <Route path='/bodega/citas-bodega' element={<Citas/>}/>
            <Route path='/producto/predistribucion/abastecimiento/' element={<Abastecimiento/>}/>
            <Route path='/producto/predistribucion/generar' element={<Generar/>}/>
            <Route path='/envios/envios-pendientes' element={<EnviosPendientes/>}/>
            <Route path='/tiendas/traslados' element={<Traslados/>}/>
            <Route path='/tiendas/habladores' element={<Habladores/>}/>
            <Route path='/tiendas/crear-habladores/:id?' element={<CrearHabladores/>}/>
            <Route path='/tiendas/ver-hablador' element={<VerHablador/>}/>
            <Route path='/tiendas/sondeos' element={<Sondeos/>}/>
            <Route path='/tiendas/sondeos/historial' element={<SondeosHistorial/>}/>
            <Route path='/tiendas/cargar-presupuestos' element={<Presupuestos/>}/>
            <Route path='/tiendas/presupuestos-vendedores' element={<PresupuestosVendedores/>}/>
            <Route path='/tiendas/reposicion-inventario' element={<ReposicionInventario/>}/>
            <Route path='/vtex/carga-vtex' element={<Index/>}/>
            <Route path='/gh/control-ingresos' element={<Ingresos/>}/>
            <Route path='/bigjohn/comunidad' element={<Comunidad/>}/>
            <Route path='*' element={<Page404/>}/>
        </Routes>
    )
}
export {
  AdminRouters
}
