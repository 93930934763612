import React, {useState, useEffect, useRef} from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import Call from '../../../config/Call'
import Spinner from '../../base/spiner';
import {TitleLayout} from '../../base/TitleLayout'
import { Select } from 'antd';
import DataTableComp from '../../produccion/autoriaLotes/dataTableComp'
import $ from 'jquery';
import Swal from 'sweetalert2'

const { Option } = Select;

const UbicacionInventario = (props) =>{
    
    let datat = {
        titulo: "Ubicaciones de inventario"
    }

    let paramsMercar = useParams()
    const navigate = useNavigate();
    
    // const [time, setTime] =useState('')
    const [listBodegas, setListBodegas] = useState([])
    const [sp, setSp]= useState(false) // MOSTRAR/OCULTAR LOADER
    const [listUbicaciones, setListUbicaciones] = useState([])
    const [datoTabla, setDatoTabla] = useState([])
    const [cargado, setCargado] = useState(false)
    const [blocked, setblocked] = useState(false)
    const [habilitar, sethabilitar] = useState(false)
    const [showView,setShowView] = useState(true)
    const [habilitarUnd, setHabilitarUnd]= useState(true)
    const [stateKeyboard, setStateKeyboard]= useState(false)
    
    const [infoBodega, setInfoBodega]= useState({
        bodega: '',
        accion: '',
        ubicacion:'',
        plu:'',
        unidades:'',
    })
    const searchInput = useRef(null);
    
    const buscar=()=>{
        // let input=$('#plu')
        
        if((!habilitar) && (!habilitarUnd) && (infoBodega.ubicacion.length===6)){
            setTimeout(function(){
                $('#plu').focus();
            }, 100);
           
        }
        
        // if(!habilitar && habilitarUnd && (infoBodega.ubicacion.length===6)){
        //     setTimeout(function(){
        //         $('#plu').focus();
        //     }, 100);
        // }
    }
    useEffect(()=>{
        if(paramsMercar?.tipo === 'b'){
            localStorage.setItem('bodega', (JSON.stringify([])))   
            setDatoTabla([])     
        }else{
            if( localStorage.getItem('bodega')){
            
                let b= JSON.parse(localStorage.getItem('bodega'))     
                setDatoTabla(b)      
                setCargado(true)
             }else{
                 localStorage.setItem('bodega', (JSON.stringify(datoTabla)))
             }
        }
        
        
        listarBodegas()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
   
    useEffect(()=>{
        
    }, [datoTabla, cargado])// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(()=>{
        
    }, [cargado && localStorage.setItem('bodega', (JSON.stringify(datoTabla))), infoBodega])// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(()=>{
        
    }, [infoBodega,infoBodega.ubicacion, buscar()])// eslint-disable-line react-hooks/exhaustive-deps
   
    
    const listarBodegas = async()=>{
        setSp(true)
        //LISTA DE BODEGAS
        const res = await Call('GET', 'api/bodegas/cedis', true, null, false)
        setListBodegas(res.data.data)
        if(res.data.data.length > 0){
            if(paramsMercar.lote !== undefined){
                if(paramsMercar.destino === 't'){
                    const result = res.data.data.filter(word => word.id === parseInt(paramsMercar.cedi))
                    // setInfoBodega({
                    //     ...infoBodega,
                    //     bodega: result&& result[0].codigo,
                    //     accion: 'quitar',
                    // })
                    if(result.length === 0 ){
                        Swal.fire({
                            title: '',
                            icon: 'error',
                            html: 'No tiene permisos para acceder a esta bodega',
                            focusConfirm: false,
                            confirmButtonText:'Aceptar',
                            allowOutsideClick: false,
                            
                        })
                        setShowView(false)
                    }else{
                        setInfoBodega({
                                ...infoBodega,
                                bodega: result[0].codigo,
                                accion: 'quitar',
                                ubicacion: ''
                            })
                            buscarUbicaciones(result[0].codigo)
                            sethabilitar(false)
                        setShowView(true)
                    }
                }else if(paramsMercar.destino === 'tv'){
                    const result = res.data.data.filter(word => word.id === parseInt(paramsMercar.cedi))
                    // setInfoBodega({
                    //     ...infoBodega,
                    //     bodega: result[0].codigo,
                    //     accion: 'quitar',
                    // })
                    if(result.length === 0 ){
                        Swal.fire({
                            title: '',
                            icon: 'error',
                            html: 'No tiene permisos para acceder a esta bodega',
                            focusConfirm: false,
                            confirmButtonText:'Aceptar',
                            allowOutsideClick: false,
                            
                        })
                        setShowView(false)
                    }else{
                        setInfoBodega({
                                ...infoBodega,
                                bodega: result[0].codigo,
                                accion: 'quitar',
                                ubicacion: ''
                            })
                            buscarUbicaciones(result[0].codigo)
                            sethabilitar(false)
                        setShowView(true)
                    }
                }else{
                    const result = res.data.data.filter(word => word.codigo === paramsMercar.cedi)
                    // setInfoBodega({
                    //     ...infoBodega,
                    //     bodega: result[0].codigo,
                    //     accion: 'quitar',
                    // })
                    if(result.length === 0 ){
                        Swal.fire({
                            title: '',
                            icon: 'error',
                            html: 'No tiene permisos para acceder a esta bodega',
                            focusConfirm: false,
                            confirmButtonText:'Aceptar',
                            allowOutsideClick: false,
                            
                        })
                        setShowView(false)
                    }else{
                        setInfoBodega({
                                ...infoBodega,
                                bodega: result[0].codigo,
                                accion: 'quitar',
                                ubicacion: ''
                            })
                            buscarUbicaciones(result[0].codigo)
                            sethabilitar(false)
                        setShowView(true)
                    }
                }
            }else{
                setInfoBodega({
                    ...infoBodega,
                    bodega: res.data.data[0].codigo,
                    accion: 'ubicar'
                })
                buscarUbicaciones(res.data.data[0].codigo)
            }
        }else{
            Swal.fire({
                title: '',
                icon: 'error',
                html: 'No tienes bodegas asignadas',
                focusConfirm: false,
                confirmButtonText:'Aceptar',
                allowOutsideClick: false,
                
            })
            setShowView(false)
        }
        setSp(false)
    }
    const handleChange = (e,a)=>{
       if(a === undefined ){
        
            if(e.target.name === 'unidades'){
                if((e.target.value > 0) || ( e.target.value === '')){
                    setInfoBodega({
                        ...infoBodega,
                        [e.target.name]: e.target.value,
                    }) 
                    if(infoBodega.ubicacion.length === 5){
                        setTimeout(function(){
                            $('#plu').focus();
                        }, 100);
                    }
                }else{
                    Swal.fire({
                        title: '',
                        icon: 'error',
                        html: 'La cantidad indicada debe ser mayor a 0',
                        focusConfirm: false,
                        confirmButtonText:'Aceptar',                    
                    })
                }
            }else if(e.target.name === 'plu'){
                setInfoBodega({
                    ...infoBodega,
                    [e.target.name]: e.target.value,
                }) 
                if(infoBodega.ubicacion.length === 5){
                    setTimeout(function(){
                        $('#plu').focus();
                    }, 100);
                }
                if(e.target.value.length === 11){
                    setTimeout(function(){
                        $('#unidades').focus();
                    }, 100);
                }
            }else{
                setInfoBodega({
                    ...infoBodega,
                    [e.target.name]: e.target.value,
                }) 
                
                if(e.target.value.length === 6){
                    
                    setTimeout(function(){
                        $('#plu').focus();
                        $('#plu2').focus();
                    }, 100);
                }
            }
       }
       if(a){
            setInfoBodega({
                ...infoBodega,
                [a.name]: a.value,
            }) 
            if(a.name === 'bodega'){
                buscarUbicaciones(a.value)
            }
            if(paramsMercar.lote === undefined){
                if(a.name === 'bodega' || a.name === 'accion'){
                    setInfoBodega({
                        ...infoBodega,
                        [a.name]: a.value,
                        ubicacion: '',
                        plu: '',
                        unidades: ''
                    }) 
                }
                sethabilitar(false)
            }
            if(a.name === 'accion'){
                sethabilitar(false)
            }
            if(a.name === 'ubicacion'){
                if(a.value !== ''){
                        setTimeout(function(){
                            $('#plu').focus();
                        }, 100);
                    sethabilitar(false)
                }else{
                    
                    sethabilitar(true)
                }
             
            }
           localStorage.setItem('bodega', JSON.stringify([]) ) 
           setDatoTabla([])
       } 
       
    }
    const buscarUbicaciones = async(cod)=>{
        setSp(true)
        //LISTA DE UBICACIONES
        try {
            const res = await Call('GET', 'api/bodegas/ubicaciones/'+cod, true, {accion: infoBodega.accion, ubicacion:infoBodega.ubicacion, codigo: infoBodega.plu }, false)
            setListUbicaciones(res.data.data)
            if(paramsMercar.lote !== undefined){
                let ubi = res.data.data.filter(word => word.cod_ubicacion === paramsMercar.ubicacion)
                
                if(res.data.code === 1){
                    if(ubi.length> 0){
                        setInfoBodega({
                            ...infoBodega,
                            bodega: cod,
                            accion:'quitar',
                            ubicacion: ubi && ubi[0].cod_ubicacion
                        })
                        setSp(false)
                    }else{
                        Swal.fire({
                            title: '',
                            icon: 'error',
                            html: 'La ubicación no fue encontrada',
                            focusConfirm: false,
                            confirmButtonText:'Aceptar',
                            allowOutsideClick: false,
                            
                        })
                        setShowView(false)
                        setSp(false)
                    }
                }
            }else{
                setSp(false)
            }
            
        } catch (error) {
            Swal.fire({
                title: '',
                icon: 'error',
                html: 'Hubo un error, contacte con el administrador',
                focusConfirm: false,
                confirmButtonText:'Aceptar',
                allowOutsideClick: false,
                
            })
        }
       
    }
    const verificar =()=>{
        if(infoBodega.plu.length === 6){
            listUbicaciones && listUbicaciones.map((u)=>{
                if(u.cod_ubicacion === infoBodega.plu){
                    infoBodega.plu=''
                    infoBodega.ubicacion= u.cod_ubicacion
                    localStorage.setItem('bodega', JSON.stringify([]) ) 
                    setDatoTabla([])
                }  
                return null
            })
        }
        listUnidades()        
    }
   
    const listUnidades = async(e, tipo, dato) =>{
        
        let info ={}
        if(paramsMercar.lote === undefined){
            if(paramsMercar.destino === 't'){

            }else{
                info={
                    accion: infoBodega.accion,
                    bodega: infoBodega.bodega,
                    plu:infoBodega.plu,
                    ubicacion: infoBodega.ubicacion,
                    cod_ubicacion: paramsMercar.ubicacion,
                    unidades: (habilitarUnd ? parseInt(infoBodega.unidades) : 1)
                }
            }
        }else{

            if(paramsMercar.destino === 't'){
                info={
                    accion: infoBodega.accion,
                    bodega: infoBodega.bodega,
                    plu:infoBodega.plu,
                    ubicacion: infoBodega.ubicacion,
                    cod_ubicacion: paramsMercar.ubicacion,
                    lote: paramsMercar.lote,
                    referencia:paramsMercar.ref,
                    unidades: (habilitarUnd ? parseInt(infoBodega.unidades) : 1)
                }
            }else if(paramsMercar.destino === 'tv'){
                info={
                    accion: infoBodega.accion,
                    bodega: infoBodega.bodega,
                    cedi: paramsMercar.cedi,
                    plu:infoBodega.plu,
                    ubicacion: infoBodega.ubicacion,
                    cod_ubicacion: paramsMercar.ubicacion,
                    lote: paramsMercar.lote,
                    referencia:paramsMercar.ref,
                    unidades: (habilitarUnd ? parseInt(infoBodega.unidades) : 1),
                    necesarias:  paramsMercar.undnecesarias
                }
            }else{
                info={
                    accion: infoBodega.accion,
                    bodega: infoBodega.bodega,
                    plu:infoBodega.plu,
                    ubicacion: infoBodega.ubicacion,
                    lote: paramsMercar.lote,
                    destino:paramsMercar.destino,
                    referencia:paramsMercar.ref,
                    unidades: (habilitarUnd ? parseInt(infoBodega.unidades) : 1)
                }
            }
        }
        
        if((info.plu.trim() !== '') && info.ubicacion !== '' && info.accion !== '' ){
            setblocked(true)
            let res = null 
            if(paramsMercar.tipo === 'b'){
                res = await Call ('POST', `api/mercar/actualizar/${info.lote}/${info.referencia}/${info.plu}/${info.unidades}/${info.cod_ubicacion}/${info.accion}`, true, null, false )
            }else if(paramsMercar.tipo === 'tv'){
                res = await Call ('POST', `api/market/online_shop/status_location/market_units/${info.lote}/${info.referencia}/${info.plu}/${info.unidades}/${info.necesarias}/${info.cod_ubicacion}/${info.cedi}/${info.accion}`, true, null, false )
            }else{
                if((paramsMercar.lote !== undefined) && (paramsMercar.cedi !== undefined)){
                    //ROUTE WHEN YOU COME FROM MERCAR
                    res = await Call ('POST', `api/mercar/cedi/actualizar/${info.lote}/${info.bodega}/${info.referencia}/${info.plu}/${info.unidades}/${info.ubicacion}/${info.accion}`, true, info, false ) 
                }else{
                    res = await Call ('POST', 'api/bodegas/unidades/'+infoBodega.bodega, true, info, false )
                }
            }
            
            if(res.data.code === 1){
                if(paramsMercar.tipo && paramsMercar.tipo !== 'b'){
                    if(tipo === 'plu'){
                        cantidad(dato, res.data.data[0].referencia, infoBodega.unidades)
                    }else{
                        cantidad(info.plu, res.data.data[0].referencia, infoBodega.unidades)
                    }
                    $('#plu').focus();
                }else{
                    if(paramsMercar && paramsMercar.tipo === 'b'){
                        Swal.fire({
                            title: '',
                            icon: 'success',
                            html: res.data.message,
                            focusConfirm: false,
                            confirmButtonText:'Aceptar',
                            allowOutsideClick: false,
                            
                        })
                    }
                    if(tipo === 'plu'){
                        cantidad(dato, res.data.data[0].referencia, infoBodega.unidades)
                    }else{
                        if(paramsMercar && ((paramsMercar.tipo !== 'b') || !paramsMercar)){
                            
                            cantidad(info.plu, res.data.data[0].referencia, infoBodega.unidades)
                        }
                        
                    }
                    $('#plu').focus();
                }
                
                // setInfoBodega({...infoBodega, plu:'', unidades:''})
                infoBodega.plu=''
                infoBodega.unidades=''
                setblocked(false)
                
            }else{
                
                if(res.status === 200 && (res.data.code && res.data.code!==2)){
                    Swal.fire({
                        title: '',
                        icon: 'success',
                        html: res.data.message,
                        focusConfirm: false,
                        confirmButtonText:'Aceptar',
                        allowOutsideClick: false,
                        
                    })
                }else if(res.status === 200 && (!res.data.code )){
                    Swal.fire({
                        title: '',
                        icon: 'success',
                        html: res.data.message,
                        focusConfirm: false,
                        confirmButtonText:'Aceptar',
                        allowOutsideClick: false,
                        
                    })
                }else{
                    Swal.fire({
                        title: '',
                        icon: 'error',
                        html: res.data.message,
                        focusConfirm: false,
                        confirmButtonText:'Aceptar',
                        allowOutsideClick: false,
                        
                    })
                }
                
                if(tipo === 'ubicacion'){
                    setInfoBodega({
                        ...infoBodega,
                        ubicacion: dato,
                        plu:'',
                        unidades:''
                    })
                }else{
                    setInfoBodega({
                        ...infoBodega,
                        plu:'',
                        unidades:''
                    })
                }
                setblocked(false)
            }

        }

    }
    const cantidad =(dato, referencia, cantidad)=>{
        
        if(datoTabla.length>0){
                if(datoTabla.find(element => element.plu === dato)){
                    if(!habilitarUnd){
                        datoTabla.filter(word => word.plu === dato ? word.cantidad= parseInt(word.cantidad)+1: '')
                    }else{
                        datoTabla.filter(word => word.plu === dato ?word.cantidad= parseInt(word.cantidad)+parseInt(cantidad): '') 
                    }
                    
                   setDatoTabla([...datoTabla])
                   
                }else{
                    let d={
                        plu: dato,
                        referencia: referencia,
                        cantidad: (cantidad === ''? 1: cantidad )
                    }
                    setDatoTabla([...datoTabla, d]) 
                }
        }else{
            let d={
                plu: dato,
                referencia: referencia,
                cantidad: (cantidad === ''? 1: cantidad )
            }
            setDatoTabla([d])
        }
       datosGuardardos()
    }

    const validateUnd =()=>{
        setHabilitarUnd(!habilitarUnd)
        setInfoBodega({...infoBodega, unidades: ''})
        $('#plu').focus();
    }
    
    const datosGuardardos =()=>{
        let b=datoTabla
        
        localStorage.removeItem('bodega')
        localStorage.setItem('bodega', JSON.stringify(b))
    }
    const columns =[
        {
            name: 'PLU',
            cell: row => (
                <div>
                    {row.plu}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Referencia',
            cell: row => (
                <div>
                    {row.referencia}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Cantidad',
            cell: row => (
                <div>
                    {row.cantidad}
                </div>
            ),
            filterable: true,
        },
    ]
    const irAMercar =()=>{
        localStorage.setItem('returnMercar', 'true');
        if(paramsMercar.destino === 't'){
            navigate(`/bodega/mercar/${paramsMercar.lote}/b`)
        }else if(paramsMercar.destino === 'tv'){
            navigate(`/bodega/mercar/${paramsMercar.lote}/tv`)
        }else{
            navigate(`/bodega/mercar/${paramsMercar.lote}/${paramsMercar.destino}`)
        }
        
    }
    return(
        <div className='pb-4'>
            { sp ? <Spinner /> : ''}  {/*LOADER*/}
            <TitleLayout data={datat} clase={"text-start"} icon={true} seccion={'ubicacion-inventario'} />
            <div className='bg-white p-2 col-lg-8 col-md-8 col-sm-12 mx-auto' style={{height:'auto'}}>
                <div className='mb-4 mt-1 d-flex justify-content-between'>
                    {( paramsMercar && paramsMercar.lote !== undefined )?
                        <div onClick={irAMercar} className='btn-buscar fw-400 cursor-pointer ms-0'>
                           <i className="fas fa-shopping-cart"></i> Ir a Mercar
                        </div> 
                        :
                        <Link to="/bodega/consultar-ubicacion" className='btn-buscar fw-400 m-0' onClick={()=>{localStorage.setItem('bodega', JSON.stringify([]) )}}>
                            <i className="fas fa-boxes"></i> Consultar ubicaciones
                        </Link> 
                    }
                    <div className='mostrar-movil m-auto'>
                        <i className={`fas fa-keyboard fs-25 cursor-pointer ${stateKeyboard ? 'text-success': 'text-danger'}`} onClick={()=>{setStateKeyboard(!stateKeyboard)}}></i>
                    </div>
                </div>
                {
                    (showView && listBodegas) ?

                    <>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 my-2'>
                            <div className='label-decoracion'>
                                <span>Bodega</span>
                            </div>
                            <Select
                                className='w-100 form-control-sm-p'
                                showSearch
                                placeholder="Selecciona una bodega"
                                optionFilterProp="children"
                                onChange={(option, children)=>{handleChange(option,children)}}
                                // onSearch={onSearch}
                                value={infoBodega.bodega}
                                filterOption={(input, option) =>
                                (option.children).toLowerCase().includes(input.toLowerCase())
                                }
                                disabled={(paramsMercar.lote !== undefined)? true:false}
                            >
                                {
                                    listBodegas &&
                                    listBodegas.map((lb, indexlb)=>(
                                        <Option name="bodega" key={indexlb+lb.codigo} value={lb.codigo}>{lb.nombre}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12 my-2'>
                            <div className='label-decoracion'>
                                <span>Acción</span>
                            </div>
                            <Select
                                className='w-100 form-control-sm-p'
                                placeholder="Selecciona una bodega"
                                optionFilterProp="children"
                                onChange={(option,children)=>{handleChange(option,children)}}
                                filterOption={(input, option) =>
                                (option.children).toLowerCase().includes(input.toLowerCase())
                                }
                                value={infoBodega.accion}
                            >
                                <Option name="accion" value="ubicar">Ubicar</Option>
                                <Option name="accion" value="quitar">Quitar</Option>
                            </Select>
                        </div>
                    </div>
                    <div className='row pb-4'>
                        <div className='col-lg-12 col-md-12 col-sm-12 my-2'>
                            <div className='label-decoracion'>
                                <span>Ubicación</span>
                            </div>
                            <div className='ocultar-movil'>
                                <Select
                                    className='w-100 form-control-sm-p'
                                    showSearch
                                    placeholder="Selecciona una bodega"
                                    optionFilterProp="children"
                                    onChange={(option,children)=>{handleChange(option,children)}}
                                    // onSearch={onSearch}
                                    value={infoBodega.ubicacion}
                                    filterOption={(input, option) =>
                                    (option.children).toLowerCase().includes(input.toLowerCase())
                                    }
                                    disabled={(infoBodega.bodega !== '' && infoBodega.accion !== '' && paramsMercar.ubicacion === undefined)? false : (paramsMercar.ubicacion !== undefined ? true: true )}
                                >
                                    {
                                        listUbicaciones &&
                                        listUbicaciones.map((lu, indexlu)=>(
                                            <Option name="ubicacion" key={indexlu+lu.cod_ubicacion} value={lu.id}>{lu.cod_ubicacion}</Option>
                                        ))
                                    }
                                </Select>
                            </div>
                            <div className='mostrar-movil'>
                                <div className='d-flex justify-content-between'>
                                    <input type="text" 
                                        className='form-control form-control-sm-p form-color form-colord w-80' 
                                        // ref={searchInput}
                                        id="ubicacion"
                                        name="ubicacion"
                                        value={infoBodega.ubicacion}
                                        placeholder="Ubicación" 
                                        onChange={(e)=>(handleChange(e))}
                                        onKeyDown={(e)=> {
                                            if ([8, 46].includes(e.keyCode)) {
                                                return;
                                            }
                                            if (!/[0-9]/.test(String.fromCharCode(e.keyCode))) {
                                                e.preventDefault();
                                            }
                                            }}
                                        // onKeyUp={(e)=>((!habilitarUnd && e.keyCode === 13) ?verificar(e): (habilitarUnd && (e.target.value.length === 6) && (e.keyCode === 13)? verificar(e) :''))}
                                        disabled={(infoBodega.bodega !== '' && infoBodega.accion !== '' && paramsMercar.ubicacion === undefined)? false : (paramsMercar.ubicacion !== undefined ? true: true )}
                                        inputMode={stateKeyboard? 'text':'none'}
                                    />
                                    <span className='m-auto cursor-pointer'><i className="fas fa-trash fs-20 text-danger" onClick={()=>{setInfoBodega({...infoBodega, ubicacion:''})}}></i></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12 my-2'>
                            <div className='label-decoracion'>
                            <div className="form-check checklocation">
                                <input className="form-check-input" type="checkbox" value={!habilitarUnd} id="defaultCheck1" onChange={validateUnd} checked={habilitarUnd} />
                                    Indicar Cantidad
                                </div>
                            </div>
                        </div>
                        <div className={`col-sm-12  ${habilitarUnd ? 'col-md-6 col-lg-6' : 'col-md-12 col-lg-12'} my-2`}>
                            <div className='label-decoracion'>
                                <span>PLU</span>
                            </div>
                            {
                                // (infoBodega.bodega !== '' && infoBodega.ubicacion !== ''  && habilitar) ?
                                <>
                                <div className='d-flex justify-content-between'>
                                    <input type="text" 
                                        className='form-control form-control-sm-p form-color form-colord input-movil' 
                                        ref={searchInput}
                                        id="plu"
                                        name="plu"
                                        value={infoBodega.plu}
                                        placeholder="PLU" 
                                        onChange={(e)=>(handleChange(e))}
                                        onKeyUp={(e)=>((!habilitarUnd && e.keyCode === 13) ?verificar(e): (habilitarUnd && (e.target.value.length === 6) && (e.keyCode === 13)? verificar(e) :''))}
                                        disabled={habilitar}
                                        inputMode={stateKeyboard? 'text':'none'}
                                    />
                                    <div className='mostrar-movil m-auto'>
                                        <span className='pt-1 cursor-pointer'><i className="fas fa-trash fs-20 text-danger" onClick={()=>{setInfoBodega({...infoBodega, plu:''})}}></i></span>
                                    </div>
                                </div>

                                    
                                </>
                                // :
                                
                                // <input type="text" className='form-control form-control-sm form-color' placeholder="Leer PLU"  value={infoBodega.plu} disabled />
                            }
                        </div>
                        {
                            (habilitarUnd) &&
                            <div className='col-sm-12 col-md-6 col-lg-6 my-2'>
                                <div className='label-decoracion'>
                                    <span>Unidades</span>
                                </div>
                                    <div className='d-flex justify-content-between'>
                                        <input type="number" 
                                            className='form-control form-control-sm-p form-color form-colord input-movil' 
                                            ref={searchInput}
                                            id="unidades"
                                            name="unidades"
                                            value={infoBodega.unidades}
                                            placeholder="Unidades" 
                                            onChange={(e)=>(handleChange(e))}
                                            min={1}
                                            // onKeyUp={(e)=>(e.keyCode !== 48 ?verificar(e): cleanInput )}
                                            disabled={habilitar}
                                        />
                                        <div className='mostrar-movil m-auto'>
                                            <span className='pt-1 cursor-pointer'><i className="fas fa-trash fs-20 text-danger" onClick={()=>{setInfoBodega({...infoBodega, unidades:''})}}></i></span>
                                        </div>
                                    </div>
                            </div>
                        }
                        {
                            (habilitarUnd) &&
                            <div className='col-lg-12 col-md-12 col-sm-12 my-3 text-center'>
                                { (infoBodega.ubicacion !== '' && infoBodega.unidades !== '' &&  infoBodega.unidades !== 0) &&
                                    <div className='btn-buscar cursor-pointer' onClick={verificar}>
                                        Guardar
                                    </div>
                                    // :
                                    // <div className='btn-buscar cursor-pointer'>
                                    //     Guardar
                                    // </div>
                                }
                                
                            </div>
                        }
                    </div>
                    <hr className='w-80 m-auto mt-4' />
                    {
                        paramsMercar.lote !== undefined &&
                        <div style={{color: '#7e7e7e'}} className='pt-3 fw-600 fs-14'>
                           Referencia: {paramsMercar.ref}
                        </div>
                    }
                    {/*  ----------------------- DATATABLE ----------------------- */}
                    <div>
                        { datoTabla && (datoTabla.length > 0 && paramsMercar.tipo !== 'b') &&
                            <DataTableComp
                                columns={columns&& columns}
                                data={datoTabla&& datoTabla}
                                paginationPerPage={20}
                                noDataComponent={<span className="fs-16 text-center">En este momento no hay nada para mostrar</span>}
                            />
                        }
                        
                    </div>
                    </>
                    :''
                }
            </div>
        </div>
    )
}
export default UbicacionInventario;