import React, { useState, useEffect } from 'react';
import { TitleLayout } from '../../base/TitleLayout';
import Spinner from '../../base/spiner';
import { Table, Button } from "antd";
import Call from '../../../config/Call';
import { EyeOutlined } from '@ant-design/icons';
import DetailModal from './components/DetailModal';
import DaySelector from './components/DaySelector';

const IndexPage = () => {
  const [showSpinner, setShowSpinner] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedDay, setSelectedDay] = useState(1);

  let datat = {
    titulo: "Reposición Inventario"
  };

  const requestData = async (day = 1) => {
    setShowSpinner(true);
    const TODAY = 1;
    const endpoint = day === TODAY ? '/api/reposicion_inventario/1/' : '/api/reposicion_inventario/2/';
    const response = await Call('GET', endpoint, true, null, false);
    
    const dataWithKeys = response.data.map((item, index) => ({
      ...item,
      key: item.referencia || index,
    }));

    setDataSource(dataWithKeys);
    setShowSpinner(false);
  };

  useEffect(() => {
    requestData(selectedDay);
  }, [selectedDay]);

  const showModal = (record) => {
    setSelectedData(record);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedData(null);
  };

  const handleDayChange = (day) => {
    setSelectedDay(day);
  };

  const handleRefresh = (day) => {
    requestData(day);
  };

  const columns = [
    {
      title: 'Referencia',
      dataIndex: 'referencia',
      key: 'referencia',
      width: '80px',
      sorter: (a, b) => a.referencia.localeCompare(b.referencia),
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      responsive: ['lg'],
      width: '200px',
    },
    {
      title: 'Venta',
      dataIndex: 'venta',
      key: 'venta',
      align: 'center',
      width: '20px',
      sorter: (a, b) => a.venta - b.venta,
    },
    {
      title: 'Inventario',
      dataIndex: 'inventario',
      key: 'inventario',
      align: 'center',
      width: '20px',
      sorter: (a, b) => a.inventario - b.inventario,
    },
    {
      title: 'Acciones',
      key: 'acciones',
      width: '50px',
      render: (text, record) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => showModal(record)}
        />
      ),
    },
  ];

  return (
    <div style={{ textAlign: 'center', maxWidth: '1000px', margin: '0 auto' }}>
      {showSpinner && <Spinner />}

      {datat && (
        <TitleLayout
          data={datat}
          clase={"text-start"}
          icon={true}
          seccion={'reposicion'}
        />
      )}

    <DaySelector onDayChange={handleDayChange} onRefresh={handleRefresh} />

      <div style={{ overflowX: 'auto', textAlign: 'center', }}>
        <Table
          dataSource={dataSource}
          columns={columns}
          size={'middle'}
          pagination={{ position: ['bottomCenter'], pageSize: 100 }}
        />
      </div>

      {selectedData && (
        <DetailModal
          isVisible={isModalVisible}
          data={selectedData}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default IndexPage;
