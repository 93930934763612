import React, {useState, useEffect} from 'react'
import Call from '../../../../config/Call'
import Spinner from '../../../base/spiner';
import { Select } from 'antd';
import Swal from 'sweetalert2';

const { Option } = Select;
const ESTANTERIAS=1
const CANASTAS=2
const PPAL='ppal'

const Transfer = (props) => {

    const [sp, setSp]= useState(false)
    const [ listBodegas, setListBodegas ]= useState([])
    const [ data, setData ]= useState({bodega:'', type:''})
    const [ listTYpe, setListType ] = useState([])
    const [listOrigin, setListOrigin]=useState([])
    const [buscar, setBuscar]= useState({ppal:'', modal:''})
    const [ showModal, setShowModal] = useState(false)
    const [listModal, setListModal]= useState([])
    const [listModalDestination, setModalDestination]=useState([])
    const [origin, setOrigin] = useState({
        can: [],
        completed: false,
        referencia: []
    });
    const [destination, setDestination] = useState('')
    const [time, setTime] =useState('') //MODIFICAR TIME
    const [showTransfer, setShowTransfer]=useState(false)
    const [showSearchModal, setShowSearchModal ] = useState(false)
    const optionsType=[
        {value: ESTANTERIAS, label:'Columnas', name:2},
        {value: CANASTAS, label:'Canastas', name:2}
    ]

    useEffect(()=>{
        setListBodegas(props.listBodegas)
        
    },[])

    const handleChangeSelect = (options, children)=>{
        if(children.name === ESTANTERIAS){
            setData({
                bodega:options,
                type:''
            })
            setListType([])
            setListOrigin([])
        }
        if(children.name === CANASTAS){
            setData({
                ...data,
                type:options
            })
            data.type=options
            listData()
            listDestination()
        }
        setBuscar({ppal:'', modal:''})
    }
    const listData = async(bodega)=>{
       
            try {
                setSp(true)
                const logic = data.type === 1? 'estanterias': 'canastas'
                const warehouse = bodega? bodega : data.bodega
                const res = await Call('GET', `/api/bodegas/listado_eliminar/${logic}/${warehouse}/transferir`, true, null, false);
                let list=[]
                
                for (let i in res.data.data ){
                    if(data.type === ESTANTERIAS){
                        let listEstanterias={
                            col: res.data.data[i],
                            status:false
                        }
                        list.push(listEstanterias)
                    }
                    
                    if(data.type === CANASTAS){
                        let arrayRef=[]
                        for(let j in res?.data?.data[i]?.referencias){
                          
                                let ref= {
                                    referencia: res.data.data[i].referencias[j].referencia,
                                    cantidad: res.data.data[i].referencias[j].cantidad,
                                    status:false
                                }
                                arrayRef.push(ref)
                           } 
                           let d={
                            can: res.data.data[i].cod_ubicacion,
                            status:false,
                                referencias:arrayRef
                            }
                            list.push(d)
                        }
                        
                    
                }
                
                setListType(list)
                setListOrigin(list)
                setSp(false)
            } catch (error) {
                alert(error)
            }
    }
    const handleChange=(e, option)=>{
        
        let list=[...listOrigin]
        if(data.type === ESTANTERIAS){
                list.forEach(columna => {
                    columna.status = columna.col === e.target.value;
                });
                setOrigin(e.target.value);
                listDestination(e.target.value);
        }else{
            let otherReference=[]
            for(let canastas in list){
                for(let referencia in list[canastas].referencias){
                    if((list[canastas].can !== option.can) && (list[canastas].referencias[referencia].status)){   
                        otherReference.push(list[canastas].referencias[referencia].referencia)
                    }   
                }
                if(otherReference.length=== 0 ){
                    if(list[canastas].can === e.target.value){
                        if(otherReference.length=== 0){
                            if(list[canastas].can === e.target.value){
                                for(let referencia in list[canastas].referencias){
                                    list[canastas].referencias[referencia].status=true
                                }
                                list[canastas].status= true
                                const miCheckbox = document.getElementsByName(`${e.target.value}-all`);
                                miCheckbox[0].checked = true
                            }
                        }else{
                            if(list[canastas].can === e.target.value ){
                                for(let referencia in list[canastas].referencias){
                                    list[canastas].referencias[referencia].status=false
                                }
                               
                                list[canastas].status=false
                            }
                            setListType(list)
                        }
                        
                    }else{
                        if(list[canastas].can === e.target.value ){
                            for(let referencia in list[canastas].referencias){
                                list[canastas].referencias[referencia].status=false
                            }
                           
                            list[canastas].status=false
                        }
                        setListType(list)
                    }
                }
                
            }
            if(otherReference.length>0){
                Swal.fire({
                    title: '',
                    icon: 'error',
                    html: 'Hay referencias seleccionadas en otra canasta, desmárquelas',
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                })
                const miCheckbox = document.getElementsByName(`${e.target.value}-all`);
                miCheckbox[0].checked = false
                for(let canastas in list){
                    if(list[canastas].can === e.target.value ){
                        for(let referencia in list[canastas].referencias){
                            list[canastas].referencias[referencia].status=false
                        }
                       
                        list[canastas].status=false
                    }
                    setListType(list)
                }
                
            }else{
                setListType(list)
                setOrigin({
                    can:e.target.value,
                    completed:true,
                    referencia: option,
                })
                setShowModal(true)
            }
           
        }
       
    }
    const canastaInconplete=()=>{
        const listReferencias= [...listTYpe]
        const refSelected=[]
        
        for(let canasta in listReferencias){
            for(let referencia in listReferencias[canasta].referencias){
                if(listReferencias[canasta].referencias[referencia].status){
                    let dato={
                        codigo: listReferencias[canasta].can,
                        referencia:listReferencias[canasta].referencias[referencia].referencia,
                        cantidad:parseInt(listReferencias[canasta].referencias[referencia].cantidad)
                    }
                    refSelected.push(dato)
                }
            }   
        }

        const canastasSeleccionadas = refSelected.map(ref => ref.codigo);

        let listFinish={
            can: canastasSeleccionadas,
            completed: false,
            referencia: refSelected,
        }
        
        setOrigin(listFinish)
        setShowModal(true)

    }

    const listDestination=async(dato)=>{
        try {
            setSp(true)
            let logic = ''
            if (data.type === ESTANTERIAS) {
                logic = 'estanterias'
                if (!dato) return
            }
            if (data.type === CANASTAS) {
                logic = 'canastas'
            }
            const bodega = data.bodega
            const res = await Call('POST', `api/bodegas/listado_destino/${logic}/${bodega}`, true, {transfer_type:(data.type=== CANASTAS? null : dato)}, false)
            let array=[]
            if(data.type === ESTANTERIAS){
                let estanterias=res.data?.data
                for(let estanteria in estanterias){
                    let data={
                        col: estanterias[estanteria],
                        status:false
                    }
                    array.push(data)
                }
                setModalDestination(array)
                setListModal(array)
                setShowModal(true)
                setSp(false)
            }else{
                let canastas=res.data?.data
                for(let canasta in canastas){
                    let data={
                        can: canastas[canasta],
                        status:false
                    }
                    array.push(data)
                }
                setModalDestination(array)
                setListModal(array)
                // setShowModal(true)
                // setOrigin(dato)
                setSp(false)
            }
        } catch (error) {
            setSp(false)
        }
       
    }
    const handlechangeSearch=(e, type)=>{
        if(type === PPAL){
            setBuscar({...buscar,
                ppal:e.target.value
            })
            if(e.target.value !== ''){
                clearTimeout(time) 
                setTime(setTimeout(() => 
                {   
                    let res=[]
                    if(data.type === 1){
                        res= listOrigin.filter(elemento => elemento.col.includes( `${e.target.value.trim()}`))
                    }else{
                        if(e.target.value.length>6){
                            
                            for(let canasta in listOrigin){
                                let search = listOrigin[canasta].referencias.filter(elemento => (elemento.referencia.includes( `${e.target.value.trim()}`) ))
                                if(search.length>0){
                                    res.push(listOrigin[canasta]) 
                                }     
                            }
                        }else{
                            res= listOrigin.filter(elemento => (elemento.can.includes( `${e.target.value}`) ))
                        }
                    }
                    setListType(res)        
                }, 800))  
            }else{
                setListType(listOrigin)
            }
        }else{
            setBuscar({...buscar,
                modal:e.target.value
            })
            if(e.target.value !== ''){
                clearTimeout(time) 
                setTime(setTimeout(() => 
                {   
                    let res= []
                    if(data.type === 1){
                        res= listModalDestination.filter(elemento => elemento.col.includes( `${e.target.value}`))
                    }else{
                        res=listModalDestination.filter(elemento => elemento.can.includes( `${e.target.value}`))
                    }
                    setListModal(res)
                    
                    if(res.length===0){
                        setShowSearchModal(true)
                    }
                            
                }, 800))  
            }else{
                setListModal(listModalDestination)
            }
        }   
       
    }
    const handleChangeModal=(e)=>{
        const selectedValue = e.target.value
        setDestination(selectedValue)

        const updateStatus = (item, condition) => {
            item.status = condition;
        };
    
        listModalDestination.forEach(destination => {
            const isSelected = data.type === 1 
                ? destination.col === selectedValue 
                : destination.can === selectedValue;
            updateStatus(destination, isSelected);
        });       
    }
    const handleCheckboxChange=(e, item)=>{
        
        const updatedCheckedItems = [...listTYpe];
        let itemsSelected=[]
        let referencias=[]
        for(let canasta in updatedCheckedItems){
            for(let referencia in updatedCheckedItems[canasta].referencias){
                if((e.target.name === updatedCheckedItems[canasta].referencias[referencia].referencia) && (updatedCheckedItems[canasta].can === item.can)){

                    updatedCheckedItems[canasta].referencias[referencia].status=e.target.checked
                    if(e.target.checked){
                        
                        let ref={
                            referencia: updatedCheckedItems[canasta].referencias[referencia].referencia,
                            status:updatedCheckedItems[canasta].referencias[referencia].status,
                            cantidad: updatedCheckedItems[canasta].referencias[referencia].cantidad
                        }
                            referencias.push({can: updatedCheckedItems[canasta].can, referencias:ref})
                            itemsSelected.push(referencias)
                    }
                }
            }
        }
        let cont='' //REFERENCIAS CANASTA ACTUAL
        let otherReference=[] // REFERENCIAS CANASTA DIFERENTES A LA ACTUAL
        for(let canasta in updatedCheckedItems){
           if(updatedCheckedItems[canasta].can === item.can){
                cont = updatedCheckedItems[canasta].referencias.filter(item=> item.status)
            }else{
            
            let other = updatedCheckedItems[canasta].referencias.filter(item=> item.status)

                if(other.length>0){
                    otherReference.push(...other)
                }
           }
            
        }
    
        let haySeleccionados = false;

        if (cont.length === item.referencias.length) {
            const miCheckbox = document.getElementsByName(`${item.can}-all`);
            miCheckbox[0].checked = true;
            
            updatedCheckedItems.filter(canasta => (
                (canasta.can === item.can) && (item.status = true, item.completed = true)
            ));
            
            setOrigin({
                can: item.can,
                completed: true,
                referencia: item.referencias,
            });
        } else {
            const miCheckbox = document.getElementsByName(`${item.can}-all`);
            miCheckbox[0].checked = false;

            updatedCheckedItems.filter(canasta => (
                (canasta.can === item.can) && (item.status = false, item.completed = false)
            ));
        }

        for (let canasta in updatedCheckedItems) {
            const cantRef = updatedCheckedItems[canasta].referencias.filter(ref => ref.status);
            if (cantRef.length > 0) {
                haySeleccionados = true;
                break;
            }
        }

        setShowTransfer(haySeleccionados);
        setListType(updatedCheckedItems);
    }
    
    const handleCheckboxAll = (e, datos) => {
        const updatedCheckedItems = [...listTYpe];
    
        for (let canasta in updatedCheckedItems) {
            if (updatedCheckedItems[canasta].can === datos.can) {
                if (e.target.checked) {
                    updatedCheckedItems[canasta].status = true;
                    updatedCheckedItems[canasta].completed = true;
    
                    for (let referencia of updatedCheckedItems[canasta].referencias) {
                        referencia.status = true;
                    }
    
                    setOrigin({
                        can: datos.can,
                        completed: true,
                        referencia: datos.referencias,
                    });

                } else {
                    updatedCheckedItems[canasta].status = false;
                    for (let referencia of updatedCheckedItems[canasta].referencias) {
                        referencia.status = false; 
                    }
                }
            }
        }
    
        const anyReferencesSelected = updatedCheckedItems.some(canasta => 
            canasta.referencias.some(ref => ref.status)
        );

        setShowTransfer(anyReferencesSelected);
        setListType(updatedCheckedItems);
    };
    
    const handleChangeCant=(e, pos)=>{
        
        let updatingData=[...origin.referencia]

        updatingData[pos].cantidad=parseInt(e.target.value)
        setOrigin({
            ...origin,
            referencia:updatingData
        })
        setShowModal(true)

    }
    const closeModal=()=>{
        setShowModal(false)
        setDestination('')
        setOrigin('')
        setShowTransfer(false)
        setBuscar({
            ...buscar, 
            modal:''
        })
        if(data.type===1){
            for(let j in listOrigin){
            
                listOrigin[j].status=false
            }
            setListType(listOrigin)
        }else{
            for(let canastas in listOrigin){
                listOrigin[canastas].status=false
                for(let referencia in listOrigin[canastas].referencias){
                    listOrigin[canastas].referencias[referencia].status=false
                }
            }
            const checkboxes = document.querySelectorAll('input[type="checkbox"]');
            
            checkboxes.forEach(checkbox => {
                checkbox.checked = false;
            });

            for(let canastas in listModal){
                listModal[canastas].status=false
            }
            setListType(listOrigin)
        }
        
        
    }
    const transfer=async()=>{
        let transferData={}
        if(data.type === ESTANTERIAS){
            transferData= {
                transfer:{
                    "origin": origin,
                    "destination": destination
                }
            }
        }else{
                transferData={
                    "canasta":{
                      "codigo": origin.can,
                      "referencias_seleccionadas": origin.referencia,
                      "canasta_destino": destination
                    }
                  }
        }
        
        Swal.fire({
            title: "",
            text: "¿Desea realizar la transferencia?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#23b991",
            cancelButtonColor: "#d33",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar"
            }).then(async(result) => {
                if (result.isConfirmed) {
                    try {
                        
                        setSp(true)
                        const endpointType = data.type === ESTANTERIAS ? 'estanterias' : 'canastas';
                        const res = await Call('POST', `/api/bodegas/transferir/${endpointType}/${data.bodega}`, true, transferData, false)
                        let list=[]
                        for (let item in res?.data?.data ){
                            if(data.type === ESTANTERIAS){
                                let d={
                                    col: res.data.data[item],
                                    status:false
                                }
                                list.push(d)
                            }else{
                                let arrayRef=[]
                                for(let referencia in res?.data?.data[item]?.referencias){
                                    let ref= {
                                        referencia: res.data.data[item].referencias[referencia].referencia,
                                        cantidad: res.data.data[item].referencias[referencia].cantidad,
                                        status:false
                                    }
                                    arrayRef.push(ref)
                               } 
                               
                               let d={
                                can: res.data.data[item].cod_ubicacion,
                                status:false,
                                    referencias:arrayRef
                                }
                                list.push(d)
                            }       
                        }
                        
                        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
                        
                        checkboxes.forEach(checkbox => {
                            checkbox.checked = false;
                        });
                        for(let canastas in listModal){
                            listModal[canastas].status=false
                        }
                        setListOrigin(list)
                        setListType(list)
                        setSp(false)
                        Swal.fire({
                            title: '',
                            icon: 'success',
                            html: res.data.message,
                            focusConfirm: false,
                            confirmButtonText:'Aceptar',
                        })
                        setShowModal(false)
                        setBuscar({ppal:'', modal:''})
                        setOrigin('')
                        setDestination('')
                        setShowTransfer(false)
                    } catch (error) {
                        setSp(false)
                    }
                }
            })        
    }
  return (
    <div className='px-4'>
        { sp ? <Spinner /> : ''}  {/*LOADER*/}
        {showModal &&
            <>
                <div className="modal" style={{display:'block', opacity:'1'}} data-backdrop-static="true" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable"  role="document">
                    <div className="modal-content" style={{height:'80vh'}}>
                        <div className="modal-header py-1">
                            <span className='fw-600'>{data.type === 1 ? 'Columnas':'Canastas'}</span>
                            <button type="button" onClick={closeModal} className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body" style={{height:'80vh', overflow:'auto'}}>
                            <h6>Transferir {typeof origin === 'string' ? origin : [...new Set(origin.can)].join(', ')} a:</h6>
                            { ((listModal.length>0)  || (showSearchModal)) &&
                                        <div className='my-3'>
                                            <input 
                                                type="text" 
                                                className='form-control form-control-sm input-buscar-listform' 
                                                name="buscarpredist" 
                                                placeholder="&#xF002;  Buscar" 
                                                onChange={(e)=>handlechangeSearch(e, 'modal')} 
                                                value={buscar && buscar.modal} 
                                            />
                                        </div>
                                    }
                            {   listModal&& listModal.length>0 ?
                                <>                                    
                                    {data.type === 1?
                                        (
                                            listModal&& listModal.map((estanteria, indexm)=>(
                                                <div className="form-check" key={indexm}>
                                                <input 
                                                    className="form-check-input" 
                                                    type="radio" 
                                                    name="exampleRadios" 
                                                    id={`${estanteria.col}`}  
                                                    value={estanteria.col} 
                                                    checked={estanteria.status}
                                                    onChange={handleChangeModal}
                                                />
                                                <label className="form-check-label text-dark m-regular" htmlFor={`${estanteria.col}`}>
                                                    Columna: {estanteria.col}
                                                </label>
                                            </div>
                                            ))
                                        )
                                        :
                                        (
                                            listModal&& listModal.map((canastas, indexcan)=>(
                                                <div key={indexcan}>
                                                    <div className="form-check" >
                                                        <input 
                                                            className="form-check-input" 
                                                            type="radio" 
                                                            name="radioDestino" 
                                                            id={`${canastas.can}`}  
                                                            value={canastas.can} 
                                                            checked={canastas.status}
                                                            onChange={handleChangeModal}
                                                        />
                                                        <label className="form-check-label" htmlFor={`${canastas.can}`}>
                                                            Canasta: {canastas.can}
                                                        </label>
                                                    </div>
                                                    {
                                                        canastas.status &&
                                                        <div>
                                                            <table className="table table-responsive">
                                                                <thead>
                                                                    <tr>
                                                                    <th>Referencia</th>
                                                                    <th>Cantidad</th>
                                                                    </tr>
                                                                </thead>
                                                                    {
                                                                        origin&& origin.referencia&& 
                                                                        origin.referencia.map((referencia, indexRef)=>(
                                                                                <tbody key={indexRef}>
                                                                                    <tr >
                                                                                        <td className='m-0 p-0'>{referencia.referencia}</td>
                                                                                        <td className='m-0 p-0 number'>
                                                                                            <input
                                                                                                type="number"
                                                                                                min={0}
                                                                                                className='form-control form-control-sm w-50 number'
                                                                                                onChange={(e)=>handleChangeCant(e, indexRef)}
                                                                                                value={referencia.cantidad}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            
                                                                        ))
                                                                    }
                                                            </table>
                                                        </div>
                                                    }
                                                </div>
                                            ))
                                        )
                                    }
                                </>
                                :
                                <div className="ticket-empty-modal pt-4">
                                    <div><i className="fas fa-box-open fs-20"></i></div>
                                    <div className="text-empty">En este momento no hay nada para mostrar</div>
                                </div>

                            }
                        </div>
                        <div className="modal-footer w-100 p-2 mb-0">
                            {
                                destination !== '' &&
                                <div className="btn btn-primary m-auto" onClick={transfer}>Transferir</div>
                            }
                            
                        </div>
                    </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show" style={{zIndex:'1000'}}></div>
            </>
        }
        {
             listBodegas&& listBodegas.length === 0 ?
                <div className="ticket-empty-modal pt-4">
                    <div><i className="fas fa-box-open fs-20"></i></div>
                    <div className="text-empty">En este momento no hay nada para mostrar</div>
                </div>
             :
             <div>
                {
                    listBodegas&& listBodegas.length > 1 ?
                        <Select
                            className='w-100 mb-0'
                            placeholder="Selecciona una bodega"
                            optionFilterProp="children"
                            onChange={(option, children) => handleChangeSelect(option, children)}
                        >
                            { listBodegas &&
                        
                                listBodegas.map((lb, indexlb) => (
                                    <Option name={1} key={indexlb + lb.codigo} value={lb.id}>
                                        {lb.nombre}
                                    </Option>
                                ))
                            }
                        </Select>
                        :
                        <div className='text-capitalize'><span className='fw-600'>Bodega:</span> {listBodegas&& listBodegas[0]&& listBodegas[0].nombre.toLowerCase()}</div>
                }
                    {
                        data&& data.bodega !== '' &&
                        <>
                            <div className='my-3 mt-4'>
                             <Select
                                    placeholder="Tipo"
                                    style={{ width: '100%' }}
                                    onChange={(options, children)=>{handleChangeSelect(options, children)}}
                                    value={data.type !== '' && (data.type===1 ? 'Columnas': 'Canastas')}
                                    options={optionsType}
                                />
                        </div> 
                    
                        {/* //////////////// BODY //////////// */}
                        <div className='mt-3'>
                            <input 
                                type="text" 
                                className='form-control form-control-sm input-buscar-listform' 
                                name="buscarpredist" 
                                placeholder="&#xF002;  Buscar" 
                                onChange={(e)=>handlechangeSearch(e, 'ppal')} 
                                value={buscar && buscar.ppal} 
                            />
                        </div>
                        </>
                    }
                    {/* //////////////// LIST DATA////////////////////////// */}
                    <div className='mt-4'>
                        { data.bodega !=='' && 
                            (data.type === 1 ?
                                (listTYpe && listTYpe.length>0 ?
                                    <>
                                        {
                                            listTYpe&& listTYpe.map((estanteria, index)=>(
                                                <div className="form-check my-2" key={index}>
                                                    <input 
                                                        className="form-check-input" 
                                                        type="radio" 
                                                        name={estanteria.col} 
                                                        id={estanteria.col}  
                                                        value={estanteria.col} 
                                                        onChange={(e, index)=>handleChange(e, index)}
                                                        checked={estanteria.status}
                                                    />
                                                    <label className="form-check-label fs-16 m-regular text-dark" htmlFor={estanteria.col} >
                                                        Columnas: {estanteria.col}
                                                    </label>
                                                </div>
                                                )) 
                                        }
                                    </>
                                    :  
                                        <div className="ticket-empty-modal pt-4">
                                            <div><i className="fas fa-box-open fs-20"></i></div>
                                            <div className="text-empty">En este momento no hay nada para mostrar</div>
                                        </div>
                                    )
                                
                                :
                                (
                                    listTYpe && listTYpe.length>0 ?
                                    <>
                                        <div className='d-flex'>
                                            <div className="accordion w-100" id="accordionExample">
                                            {  listTYpe&& listTYpe.length > 0 ? (
                                                listTYpe.map((canasta, indexc) => (
                                                    <div key={indexc}>
                                                        <div className='d-flex'>
                                                <div className="card mb-1 w-100" >
                                                <div 
                                                className="p-2 border-bottom cursor-pointer fw-600 w-100" 
                                                id={`heading${canasta.can}`}
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#collapse${canasta.can}`}
                                                    aria-expanded={indexc === 0 ? 'true' : 'false'} 
                                                    aria-controls={`collapse${canasta.can}`}
                                                >
                                                    Canasta: {canasta.can}   
                                                </div>
                                                <div id={`collapse${canasta.can}`} 
                                                className={`collapse ${indexc === 0 ? 'show' : ''} w-100`} 
                                                aria-labelledby={`heading${canasta.can}`} 
                                                data-bs-parent="#accordionExample">
                                                    <div className="card-body p-1">
                                                        <div className="header-canastas">
                                                        {
                                                            canasta.referencias&& canasta.referencias.length>0 ?
                                                            <table className="table" key={indexc+'n'}>
                                                            <thead>
                                                                <tr>
                                                                <th className='m-2'>Referencia</th>
                                                                <th className='m-2'>Cant</th>
                                                                <th className='m-2'>
                                                                    <input
                                                                        key={'n'}
                                                                        name={`${canasta.can}-all`}
                                                                        id="all"
                                                                        type="checkbox"
                                                                        className='form-check-input big-check'
                                                                        onChange={(e) => handleCheckboxAll(e, canasta)}
                                                                        checked={null}
                                                                    />
                                                                </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {canasta&& canasta.referencias.map((ref, idf) => (
                                                                <tr key={idf}>
                                                                    
                                                                    <td>{ref.referencia}</td>
                                                                    <td>{ref.cantidad}</td>
                                                                    <td className="">
                                                                        <input
                                                                            type="checkbox"
                                                                            className='form-check-input big-check'
                                                                            name={ref.referencia}
                                                                            onChange={(e) => handleCheckboxChange(e, canasta)}
                                                                            checked={ref.status}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                ))}
                                                            </tbody>
                                                            </table>
                                                            :
                                                            <div className="ticket-empty-modal pt-4">
                                                                <div><i className="fas fa-box-open fs-20"></i></div>
                                                                <div className="text-empty">En este momento no hay nada para mostrar</div>
                                                            </div>
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                        </div>
                                                    </div>
                                            
                                            ))   
                                                
                                            ) : (
                                                <div className="ticket-empty-modal pt-4">
                                                    <div><i className="fas fa-box-open fs-20"></i></div>
                                                    <div className="text-empty">En este momento no hay nada para mostrar</div>
                                                </div>
                                            )}
                                        </div>
                                        </div>                           
                                    </>
                                :  
                                    <div className="ticket-empty-modal pt-4">
                                        <div><i className="fas fa-box-open fs-20"></i></div>
                                        <div className="text-empty">En este momento no hay nada para mostrar</div>
                                    </div>
                                )
                                
                            )
                           
                        }
                        {
                            showTransfer &&
                            <section className="btn-float">
                                <div className="btn-solid-red" onClick={canastaInconplete} >Transferir</div>
                            </section> 
                        }
                         
                    </div>

             </div>
        }
    </div>
  )
}

export default Transfer
