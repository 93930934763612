import React, { useState, useContext,useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import {TitleLayout} from '../base/TitleLayout'
import axios from 'axios';
import Call from '../../config/Call'
import $ from 'jquery';
import Swal from 'sweetalert2'
import Spinner from '../base/spiner.js';
import AuthContext from '../../contexts/auth/AuthContext';
import NewTask from './newTask'
import ProfileCard from '../base/ProfileCard';
import { Select, Tooltip, Skeleton, Upload, message,Popover } from 'antd';
import { motion } from 'framer-motion'; //EFECTOS
import InfiniteScroll from 'react-infinite-scroll-component'; //SCROLL INFINITO
import moment from 'moment';
import {FormDinamic} from '../formularios/FormDinamic';
import { FormViewResponseDetail } from '../formularios/FormViewResponseDetail.js';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import useSound from 'use-sound';

import new_comment_sound from '../../assets/sonidos/new_comment.mp3';
import status_changed_sound from '../../assets/sonidos/status_changed.mp3';

function style (color) {
  return {
    height: '100%',
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color
  };
}

const Tareas2 =() => {

    // ///////////// INIT PANEL RESIZE ///////////////////////
  const [sizes, setSizes] = useState([152, '30%', 'auto']);
  const [sizes2, setSizes2] = useState([140,'10%','auto']);

    const layoutCSS = {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };
    const layoutCSS2 = {
        height: '100%',
        // display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };
    ////////////// FIN PANEL RESIZE /////////////////////

    const [bandeja, setBandeja] = useState('Recibidos') // TAREAS RECIBIDAS Y ENVIADAS
    let [estadoSelected, setEstadoSelected] = useState('pendiente') // ESTADO SELECCIONADO DE UNA TAREA
    const [lTareas, setLtareas] = useState([]) // LISTADO DE TAREAS
    const [detalle, setDetalle] = useState(null) // DETALLE A MOSTRAR DE UNA TAREA SELECCIONADA
    const [estadosTareas, setEstadosTareas] = useState([]) //LISTAS DE ESTADOS DE NA TAREA
    const [idSelected, setIdSelected] = useState(null) // ID TAREA SELECCIONADA
    const [buscar, setBuscar] = useState('') // INPUT BUSCAR
    const [sp, setSp]= useState(false) // MOSTRAR/OCULTAR LOADER
    const [orden, setOrden] = useState('updated_at') // ORDENAR TAREAS
    const [infoComments, setInfoComments] = useState('')
    const [showModalPrioridades, setShowModalPrioridades] = useState(false)
    const [showModalFlujos, setShowModalFlujos] = useState(false)
    const [modal, setModal] = useState(false) //MODAL DETALLE TAREA MOVIL
    const { user } = useContext(AuthContext) // INFORMACION DE USUARIO
    const [newT, setNew] = useState({}) // MOSTRAR OCULTAR MODAL NUEVA TAREA
    const [comentarios, setComentarios] =useState([])
    const [lFlujos, setLflujos]= useState([])
    const [searchText, setSearchText] = useState('')
    const [showAnswer, setShowAnswer] = useState(false)
    const [visto, setVisto] = useState('')
    let [asignacion, setAsignacion] = useState('todos')
    const [showResponsables, setShowResponsables] = useState(false)
    const [showDestinatario, setShowDestinatario] = useState(false)
    const [listResponsables, setListResponsables] = useState([])
    const [responsablesSelect, setResponsablesSelect] = useState([])
    const [userDeSelected, setUserDeSelected] = useState([])
    const [grupoDeSelected, setGrupoDSelected] = useState([])
    const [valuesResponsables, setValuesResponsables]= useState([]) //VALUE RESPONSABLES PARA SELECT
    const [valueGdestinatarios, setValuesGdestinatarios] = useState([]) //VALUE DESTINATARIOS GRUPO PARA SELECT
    const [lUsuarios, setLusuarios] = useState([]) // LISTA DE USUARIOS
    const [lGupoUsuarios, setLgupoUsuarios] = useState([]) // LISTA DE GRUPOS
    const [forzarUpdate, setForzarUpdate] = useState(null)
    const { Option, OptGroup, onSelect} = Select;
    const [infoPrioridades, setInfoPrioridades] = useState(
        {
          id: null, 
          estado: false,
      })
    const id=useParams()
    let idtarea= parseInt(id?.id) // ID QUE VIENE POR PARAMETRO

    const [cantidad, setCantidad]= useState({ //# DE TAREAS EN EL SISTEMA
      filtrados: '',
      total: '' //TAREAS TOTALES EN EL SISTEMA
    })
  //   var download = require('file-download')
    const [data, setData] = useState({previewVisible: false, previewImage: '', previewTitle: '', fileList:[]})
    const [url, setUrl]= useState(null)
    const [cantidadComments, setCantidadComments]= useState({ //# DE COMENTARIOS EN UNA TAREA
      filtrados: '',
      total: '' //TAREAS TOTALES EN EL SISTEMA
    })
    // const [dataUploader, setDataUploader] = useState({previewVisible: true, previewImage: '', previewTitle: '', fileList:[]})
    const [time, setTime] =useState('')
    const [errorComments, setErrorComments] = useState(false)
    const [ bFlujos, setbFlujos] = useState([]) // BUSQUEDA DE FLUJOS
    const [ load, setLoad ] = useState(false)
    const [ leido, setLeido] = useState(null)

   ///     websocket 
    const URL_SOCKET = process.env.REACT_APP_BROKERWS_URL + '/tareas/send/'+user.id
    const [socketUrl, setSocketUrl] = useState(URL_SOCKET);
    const [messageHistory, setMessageHistory] = useState([]);

    
    const [play_newtask] = useSound(
        status_changed_sound,
        { volume: 0.5 }
    );
    const [play_newcomment] = useSound(
        new_comment_sound,
        { volume: 0.5 }
    );
    const [play_statuschanged] = useSound(
        status_changed_sound,
        { volume: 0.5 }
    );

    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
    // FIN WEBSOCKET
    console.log("Estado del WebSocket:", readyState);

    useEffect(() => {
        if (lastMessage !== null) {
          let data = JSON.parse(lastMessage.data);
          if (data.type_message === 'new_task'){
            let tarea =  data.data
            if (estadoSelected===tarea.estado || estadoSelected==='Todos'){
              tarea.leido = false
              lTareas.unshift(tarea)
              setLtareas(lTareas)
              play_newtask()
            }
          }else if (data.type_message ==='new_comment'){
            let existe = false
            let comentario = data.data.comment
            let task = data.data.task
            if (comentario.tarea === detalle.id){
              let existe_comentario = false
              for(let key in comentarios){
                if(comentario.id === comentarios[key].id){
                  existe_comentario = true
                }
              }
              for(let key in lTareas){
                if (lTareas[key].id === task.id){
                  existe = true
                  lTareas.splice(key,1)
                  lTareas.unshift(task)
                  setLtareas(lTareas)
                  // play_statuschanged()
                }
              }
              
              if (existe_comentario===false){
                comentarios.unshift(comentario)
                setComentarios(comentarios)
                play_newcomment()
              }
            }else{
              for(let key in lTareas){
                if (lTareas[key].id === task.id){
                  existe = true
                  lTareas.splice(key,1)
                  task.leido = false
                  lTareas.unshift(task)
                  setLtareas(lTareas)
                  play_statuschanged()
                }
              }
              if (existe === false){
                task.leido = false
                lTareas.unshift(task)
                setLtareas(lTareas)
                play_newtask()
              }
            }
          }else if (data.type_message ==='status_changed'){
            let tarea =  data.data
            if (estadoSelected===tarea.estado || estadoSelected==='Todos'){
              let existe = false
              for(let key in lTareas){
                if (lTareas[key].id === tarea.id){
                  existe = true
                  tarea.leido = false
                  lTareas[key] = tarea
                  play_statuschanged()
                  setLtareas(lTareas)
                }
              }
              if (existe===false){
                tarea.leido = false
                lTareas.unshift(tarea)
                play_statuschanged()
                setLtareas(lTareas)
              }
              if (idSelected==null){
                verTarea(tarea.id, undefined, estadoSelected)
                setIdSelected(tarea.id)
              }
            }else{
              for(let key in lTareas){
                if (lTareas[key].id === tarea.id){
                  lTareas.splice(key,1)
                  play_statuschanged()
                  setLtareas(lTareas)
                  if (tarea.id===detalle.id){
                    getDetailTarea(lTareas[0]?.id,true)
                    setIdSelected(lTareas[0]?.id)
                  }
                }
              }
            }
          }else if (data.type_message === 'update_detail'){
            if (data.data===detalle.id){
              getDetailTarea(data.data, true)
            }
          }else if (data.type_message === 'change_responsable'){
            let tarea =  data.data.detalle
            let tarea_list =  data.data.minlista
            let leido = false
            let existe = false
            if (estadoSelected===tarea.estado || estadoSelected==='Todos'){
              if (tarea.id ===detalle.id){
                getDetailTarea(tarea.id,true)
                leido =  true
              }
              tarea.leido = leido
              tarea_list.leido = leido
              let ltarea = lTareas
              for(let key in ltarea){
                if (ltarea[key].id === tarea.id){
                  ltarea.splice(key,1)
                }
              }
              ltarea.unshift(tarea_list)

              play_statuschanged()
              setLtareas(ltarea)

            }
          }else if (data.type_message === 'visto_changed'){
            const tareaId = data.data.tarea_id;
            const nuevoEstadoLeido = data.data.estado;
            const usuarioLeido = data.data.usuario;

            // console.log('Mensaje visto_changed recibido:');
            // console.log('Tarea ID:', tareaId);
            // console.log('Nuevo estado leido:', nuevoEstadoLeido);
            // console.log('Usuario que leyó:', usuarioLeido);

            setLtareas((prevTareas) =>
                prevTareas.map((tarea) =>
                tarea.id === tareaId ? { ...tarea, leido: nuevoEstadoLeido, usuarioLeido } : tarea
                )
            );
          }
          setMessageHistory((prev) => prev.concat(lastMessage));
        }
    }, [lastMessage, setMessageHistory]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(()=>{
        if(idtarea){
            verTarea(idtarea, 'param')
        }else{
            datos()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        datos()
    }, [forzarUpdate]) // eslint-disable-line react-hooks/exhaustive-deps
          
    useEffect(()=>{
        getBinaryFiles()
        listarDestinatarios()

    }, [data.fileList, detalle, leido]) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(()=>{
    }, [estadoSelected]) // eslint-disable-line react-hooks/exhaustive-deps
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    })
    
    let datat = {
        titulo: "Tareas"
    }
        
    const [datosComentarios, setDatosComentarios]=useState({
        
    })
    
    const clearComments = ()=>{
        let texto= document.getElementById('txtarea')
        if(texto){
            texto.innerHTML=''
        }
    } 
    
    const getDetailTarea = async (id, changeTask=false) =>{
        if (changeTask){
            carga(true)
        }
            
        const res = await Call('GET', '/api/tareas/'+id, true, null, false)
        const res1 = await Call('GET', '/api/tareas/min/'+id, true, null, false)
        const res2 = await Call('GET', 'api/tareas/detalles/'+id+'?start=0&length=3', true, null)
        if (changeTask){
            setDetalle(res.data.data)
            setIdSelected(id)
            if(res2.data.code === 1){
            // CARGANDO COMENTARIOS
            setComentarios(res2.data.data.data.reverse())
            }else{
                setComentarios([])
            }
        }else{
            
        }
        if (changeTask){
            carga(false)  
        }
    }
       
    const listarFlujos = async() =>{
        let a =[]
        
        const res = await Call('GET', '/api/tareas/flujos', true, null, false)
        
        for (const key in res.data.data) {
            let flujos={
                categoria: key,
                flujos:res.data.data[key]
            }
            a.push(flujos)
        }
        setLflujos(a)
    }
    const listarDestinatarios = async() =>{
        carga(true)
        const res = await Call('GET', '/api/usuarios-destinatatios', true, null, false)
        setLusuarios(res.data.results)
        const res2 = await Call('GET', '/api/grupos', true, null, false)
        setLgupoUsuarios(res2.data.results)    
        carga(false)
    }
          
    const handlechange =(e) =>{
        setBuscar(e.target.value)
        clearTimeout(time) 
        setTime(setTimeout(() => 
        {
            nuevosDatos('buscar', e.target.value)
        }, 800))   
    }
    // Captura del comentario

    const handleChangeComments = (e) =>{
    
        setInfoComments(e.target.innerText)
    
    }
    const handleChangeRes =(value, options, tipo)=>{
                
        if(tipo === 'destinatarios'){
            let ud= []
            let gd=[]
            for(let i=0; i<options.length; i++ ){
                if(options[i].name === "user"){
                    ud.push(parseInt(options[i].value.slice(1)))
                }else{
                    gd.push(parseInt(options[i].value.slice(1)));
                }
            }
            setUserDeSelected(ud)
            setGrupoDSelected(gd)
        }else{
            let u= []
            let g=[]
            for(let i=0; i<options.length; i++ ){
                if(options[i].name === "user"){
                    u.push(parseInt(options[i].value.slice(1)))
                }else{
                    g.push(parseInt(options[i].value.slice(1)));
                }
            }
            setResponsablesSelect(u);
        }
        // setselectedGroups(g);         
    }
    //CARGA DE DATOS INICIALES
    const datos = async (param)=>{
        carga(true)
        listarFlujos()
        if(param){
            try {
            const res = await Call('GET', '/api/tareas/estados', true, null, false)
            setEstadosTareas(res.data.data)
            } catch (error) {
                carga(false)
                if(error.message === 'El token dado no es valido para ningún tipo de token'){

                }else{
                    alert(error)
                }
            }
        }else{
            try {
            const res = await Call('GET', `/api/tareas?estado=${estadoSelected}&categoria=${bandeja}&filtro=${buscar}&orden=${orden === null ? '' : orden}&leido=${leido}&start=0&length=15&ordendir=desc`, true, null, false)
            setLtareas(res.data.data)
            setCantidad({
                total: res.data.recordsTotal
            })
            if(visto !== 'leido' && visto !== 'sin leer'){
    
                if(res.data.data.length > 0){
                    const res2 = await Call('GET', '/api/tareas/'+res.data.data[0].id, true, null, false)
                    const res3 = await Call('GET', 'api/tareas/detalles/'+res.data.data[0].id+'?start=0&length=3', true, null)
                    if(res3.data.code === 1){
                    setComentarios(res3.data.data.data)
                    setCantidadComments({total:res3.data.data.recordsTotal})
                    }else{
                    setComentarios([])
                    }
                    setDetalle(res2.data.data)
                    setIdSelected(res.data.data[0].id)
                    marcar_visto(res.data.data[0], true)
                    }
                }
            } catch (error) {
                carga(true)
                
                alert(error)
            }
            try {
                const res = await Call('GET', '/api/tareas/estados', true, null, false)
                setEstadosTareas(res.data.data)
            } catch (error) {
                carga(false)
                alert(error)
            }
        }
            
        carga(false)
    }
              
    const nuevosDatos = async(tipo, dato, estadoLeido)=>{
        carga(true)
            
        if(tipo === 'bandeja'){
            if (bandeja !== dato){
                setShowDestinatario(false)
                setValuesGdestinatarios([])
                setValuesResponsables([])
                setBandeja(dato)
                setBuscar('')
                if(dato === 'Enviados'){
                    setEstadoSelected('Todos')
                }else{
                    setEstadoSelected('pendiente')
                }
            }
            const res = await Call('GET', `/api/tareas?estado=${dato === 'Enviados' ? 'Todos': 'pendiente'}&categoria=${dato}&filtro=&orden=${orden}&leido=${leido}&asignacion=${asignacion}&start=0&length=15&ordendir=desc`, true, null, false)
            setLtareas(null)
            setLtareas(res.data.data)
            setCantidad({
            total: res.data.recordsTotal
            })
            if(visto !== 'leido' && visto !== 'sin leer'){
                if(res.data.data.length > 0){
                    const res2 = await Call('GET', '/api/tareas/'+res.data.data[0].id, true, null, false)
                    const res3 = await Call('GET', 'api/tareas/detalles/'+res.data.data[0].id+'?start=0&length=3', true, null)
                    if(res3.data.code === 1){
                        // CARGANDO COMENTARIOS
                        setComentarios(res3.data.data.data)
                    }else{
                        setComentarios([])
                    }
                    setIdSelected(res.data.data[0].id)
                    setDetalle(res2.data.data)
                    marcar_visto(res2.data.data, true)
                    // setHasMore(true)
                }
            }else{
            setDetalle(null)
            setIdSelected(null)
            }
                  
        }
        if(tipo === 'estado' ){
            setEstadoSelected(dato)
            if(estadoSelected !== dato){
                let d= dato
                setEstadoSelected(dato)
                const res = await Call('GET', `/api/tareas?estado=${d}&categoria=${bandeja}&filtro=&orden=${orden === null ? '' : orden}&leido=${leido}&asignacion=${asignacion}&start=0&length=15&ordendir=desc`, true, null, false)
                setLtareas(res.data.data)
                setCantidad({
                    total: res.data.recordsTotal
                })
                if(visto !=='leido' && visto!=='sin leer'){
                    if(res.data.data.length > 0){
                        const res2 = await Call('GET', '/api/tareas/'+res.data.data[0].id, true, null, false)
                        const res3 = await Call('GET', 'api/tareas/detalles/'+res.data.data[0].id+'?start=0&length=3', true, null)
                        if(res3.data.code ===1){
                            setComentarios(res3.data.data.data)
                            setCantidadComments({total:res3.data.data.recordsTotal})
                        }else{
                            setComentarios([])
                        }
                        setDetalle(res2.data.data)
                        setIdSelected(res.data.data[0].id)
                        setEstadoSelected(dato)
                        marcar_visto(res2.data.data, true, undefined, dato)
                        // setHasMore(true)
                    }
                }else{
                    setDetalle('')
                    setIdSelected(null)
                }
                      
                      
            }
        }
        if((tipo === 'buscar') ){
            const res = await Call('GET', `/api/tareas?estado=${estadoSelected}&categoria=${bandeja}&filtro=${dato}&orden=${orden === null ? '' : orden}&leido=${leido}&asignacion=${asignacion}&start=0&length=15&ordendir=desc`, true, null, false)
            // carga(true)
            setLtareas(res.data.data)
            setCantidad({
                total: res.data.recordsTotal
            })
            if(visto !== 'leido' && visto !=='sin leer' ){
            if(res.data.data.length > 0){
                const res2 = await Call('GET', '/api/tareas/'+res.data.data[0].id, true, null, false)
                const res3 = await Call('GET', 'api/tareas/detalles/'+res.data.data[0].id+'?start=0&length=3', true, null)
                if(res3.data.code ===1){
                    setComentarios(res3.data.data.data)
                    setCantidadComments({total:res3.data.data.recordsTotal})
                }else{
                    setComentarios([])
                }
                setDetalle(res2.data.data)
                setIdSelected(res.data.data[0].id)
                marcar_visto(res2.data.data, true)
                // setHasMore(true)
                }
            }
            // carga(true)           
        }
        if((tipo === 'orden') ){
                  
            setOrden(dato)
            // setLeido('')
            const res = await Call('GET', `/api/tareas?estado=${estadoSelected}&categoria=${bandeja}&filtro=${buscar}&orden=${dato}&leido=${leido}&asignacion=${asignacion}&start=0&length=15&ordendir=desc`, true, null, false)
            setLtareas(res.data.data)
            setCantidad({
                total: res.data.recordsTotal
            })
                  
            if(res.data.data.length > 0){
            const res2 = await Call('GET', '/api/tareas/'+res.data.data[0].id, true, null, false)
            const res3 = await Call('GET', 'api/tareas/detalles/'+res.data.data[0].id+'?start=0&length=3', true, null)
            if(res3.data.code ===1){
                setComentarios(res3.data.data.data)
                setCantidadComments({total:res3.data.data.recordsTotal})
            }else{
                setComentarios([])
            }
            setDetalle(res2.data.data)
            setIdSelected(res.data.data[0].id)
            marcar_visto(res2.data.data, true, dato, )
            }
                  // setHasMore(true)
                  
        }
        if(tipo === 'visto'){
            // if(dato === 'leido'){
            setVisto(estadoLeido ? 'leido': 'sin leer')
            setLeido(estadoLeido)
            const res = await Call('GET', `/api/tareas?estado=${estadoSelected}&categoria=${bandeja}&filtro=${buscar}&orden=${orden}&start=0&length=15&leido=${estadoLeido}&asignacion=${asignacion}&ordendir=desc`, true, null, false)
            setLtareas(res.data.data)

            setCantidad({
                total: res.data.recordsTotal
            })
            setDetalle('')
            setIdSelected(null)
        }
        if(tipo === 'asignacion' ){
            setAsignacion(dato)
            if(asignacion !== dato){
                setAsignacion(dato)
                const res = await Call('GET', `/api/tareas?estado=${estadoSelected}&categoria=${bandeja}&filtro=&orden=${orden === null ? '' : orden}&leido=${leido}&asignacion=${dato}&start=0&length=15&ordendir=desc`, true, null, false)
                setLtareas(res.data.data)
                setCantidad({
                    total: res.data.recordsTotal 
                })
                if(visto !=='leido' && visto!=='sin leer'){
                    if(res.data.data.length > 0){
                    const res2 = await Call('GET', '/api/tareas/'+res.data.data[0].id, true, null, false)
                    const res3 = await Call('GET', 'api/tareas/detalles/'+res.data.data[0].id+'?start=0&length=3', true, null)
                    if(res3.data.code ===1){
                    setComentarios(res3.data.data.data)
                    setCantidadComments({total:res3.data.data.recordsTotal})
                    }else{
                    setComentarios([])
                    }
                    setDetalle(res2.data.data)
                    setIdSelected(res.data.data[0].id)
                    setAsignacion(dato)
                    marcar_visto(res2.data.data, true, undefined, dato)
                    // setHasMore(true)
                }
                }else{
                setDetalle('')
                setIdSelected(null)
                }          
            }
        }
        clearComments()
        carga(false)
    }
              
    const verTarea = async(id, lugar, estado)=>{
        
        setShowResponsables(false)
        setShowDestinatario(false)
        setValuesGdestinatarios([])
        setValuesResponsables([])
            
        if(!lugar){
            window.history.pushState(null, "", "/tareas");
        }
        
        carga(true)
        if(!id){
            if(idSelected){
            const res2 = await Call('GET', '/api/tareas/'+idSelected, true, null, false)
            const res3 = await Call('GET', 'api/tareas/detalles/'+idSelected+'?start=0&length=3', true, null)
            if(res3.data.code ===1){
                setComentarios(res3.data.data.data)
                setCantidadComments({total:res3.data.data.recordsTotal})
            }else{
                setComentarios([])
            }
            setData({previewVisible: false, previewImage: '', previewTitle: '', fileList:[]})
            setDetalle(res2.data.data)
            marcar_visto(res2.data.data, true, undefined, estado)
            }
        }
        if(idSelected !== id && id !== undefined){
            setIdSelected(id)
            const res2 = await Call('GET', '/api/tareas/'+id, true, null, false)
            const res3 = await Call('GET', 'api/tareas/detalles/'+id+'?start=0&length=3', true, null)
            
            if(res3.data.code === 1){
                setComentarios(res3.data.data.data)
                setCantidadComments({total:res3.data.data.recordsTotal})
                // carga(false)
            }else{
                setComentarios([])
            }
            setData({previewVisible: false, previewImage: '', previewTitle: '', fileList:[]})
            setDetalle(res2.data.data)
            marcar_visto(res2.data.data, true)
        }
            
        if(lugar){
            if(window.screen.width <= 767){
                setModal(true)
            }
            setIdSelected(id)
            const res = await Call('GET', `/api/tareas?estado=${estadoSelected}&categoria=${bandeja}&filtro=${buscar}&orden=${orden === null ? '' : orden}&leido=${leido}&start=0&length=15&ordendir=desc`, true, null, false)
            const found = res.data.data.find(element => element.id === id); //IDENTIFICANDO SI EL ID POR PARAMETRO ES DE ENVIADOS O RECIBIDOS
            
            if(found){
                const res2 = await Call('GET', `/api/tareas?estado=${estadoSelected}&categoria=${bandeja}&filtro=${buscar}&orden=${orden === null ? '' : orden}&leido=${leido}&asignacion=${asignacion}&start=0&length=15&ordendir=desc`, true, null, false)
                
                setBandeja(bandeja)
                setLtareas(res2.data.data)
                datos(true)
                marcar_visto(res2.data.data, true)
                setCantidad({
                    total: res2.data.recordsTotal 
                })
                return
            }else{
                datos(true)
                const res3 = await Call('GET', `/api/tareas?estado=Todos&categoria=Enviados&filtro=${buscar}&orden=${orden === null ? '' : orden}&leido=${leido}&asignacion=${asignacion}&start=0&length=15&ordendir=desc`, true, null, false)
                setBandeja('Enviados')
                setLtareas(res3.data.data)
                setEstadoSelected('Todos')
                marcar_visto(res3.data.data, true)
                setCantidad({
                    total: res3.data.recordsTotal 
                })
                
                // return
            }
            setData({previewVisible: false, previewImage: '', previewTitle: '', fileList:[]})
        }
            setErrorComments('')
            clearComments()
            carga(false)
    }
    const cambiarEstado = async (estado, dispo)=>{
        setShowResponsables(false)
        let data ={
            usuario: user.id,
            estado: estado
        }
        Swal.fire({
            html: "¿Está seguro de cambiar el estado de la tarea a <span class='fw-bold text-capitalize'>"+estado+"</span>?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#23b991',
            cancelButtonColor: '#ee4630',
            confirmButtonText: 'Cambiar',
            cancelButtonText:'Cancelar',
            customClass:{
            popup:'w-33 pop-up33',
            cancelButton:'btn-cancel-general',
            confirmButton:'btn-cancel-general',
            htmlContainer:'content-alert'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
            const res= await Call('PUT', '/api/tareas/'+idSelected, true, data, false)
            if(res.data.code === 1){
                Toast.fire({
                title:'El estado de la tarea ha cambiado',
                icon: 'success'
                })
                if(dispo){
                setModal(false)
                }
                setForzarUpdate(prev => !prev);
                // datos()
                
            }else{
                Toast.fire({
                title:`${res.data.mensaje}`,
                icon: 'warning'
                })
            }
            
            }
        })
    }
             
    const addComments = async (usuario) =>{
        if(infoComments !== '' || datosComentarios.archivos.length> 0 ){
            setErrorComments(false)
            setDatosComentarios({
            data: infoComments,
            usuario: user.id,
            archivos:[]
            })
            let data={
            data: infoComments,
            usuario: user.id,
            archivos:datosComentarios.archivos
            }
            //    getBinaryFiles()
            carga(true)
            
            const res= await Call('POST', 'api/tareas/detalles/'+idSelected, true, data, false)
            
            if(res.data.code === 1){
                setInfoComments('')
                clearComments()
                setData({previewVisible: false, previewImage: '', previewTitle: '', fileList:[]})
                // verTarea()
                comentarios.unshift(res.data.data)
                setComentarios(comentarios)
                carga(false)
            }else{
                carga(false)
                Toast.fire({
                    title:`Hubo un error`,
                    icon: 'error'
                })
            }
        }else{
            setErrorComments(true)
        }    
    }
           
    const getBinaryFiles = () => {
                
        let arrayArchivos = []
        data.fileList && data.fileList.map(el => {
            const reader = new FileReader();
            reader.readAsDataURL(el.originFileObj)

            reader.onload = e => {
                let x=reader.result;
                
                let base= x.split('64,');
                let a = {
                    nombre:el.name,
                    data:base[1],
                }
                
                arrayArchivos.push(a)
            };
            return null
        })
        
        setDatosComentarios({
            ...datosComentarios,
            archivos: arrayArchivos
        })
    }

    const carga =(activar)=> {
        if (activar) {
            setSp(true)
        } else {
            setSp(false)
        }
    }

    // MODAL DETALLE TAREA MOVIL
    const parentToChild = (id) => {
        const modalExample= document.getElementById("#exampleModal")

        if(modalExample){
            $('#exampleModal').modal('hide')

        }
        setShowModalFlujos(false)
        setNew({
            estado:true,
            idFlujo: id
        })
    }
    const childToParent = (childdata) => {
        setShowResponsables(false)
        setNew(false);
        datos()
        infoTareas2()
    }
    const marcar_visto = async (item, value, or, estado) => {
        if (item && typeof item === 'object' && item.tarea_visto) {
            
            if (item.tarea_visto.length === 0) {
                await Call('PUT', '/api/tareas/' + item.id, true, { visto: value, usuario: user.id }, false);
                
                for (let key in lTareas) {
                    if (lTareas[key].id === item.id) {
                        lTareas[key].leido = true;
                        setLtareas([...lTareas]);
                    }
                }
            } else {
                const tareaNoVista = item.tarea_visto.find(word => word.estado === false && word.usuario?.id === user.id);
                if (tareaNoVista) {
                    await Call('PUT', '/api/tareas/' + item.id, true, { visto: value, usuario: user.id }, false);
                    
                    for (let key in lTareas) {
                        if (lTareas[key].id === item.id) {
                            lTareas[key].leido = true;
                            setLtareas([...lTareas]);
                        }
                    }
                }
    
                const usuarioNoEncontrado = item.tarea_visto.find(word => word.usuario?.id === user.id) === undefined;
                if (usuarioNoEncontrado) {
                    await Call('PUT', '/api/tareas/' + item.id, true, { visto: value, usuario: user.id }, false);
                    
                    for (let key in lTareas) {
                        if (lTareas[key].id === item.id) {
                            lTareas[key].leido = true;
                            setLtareas([...lTareas]);
                        }
                    }
                }
            }
        }
    };
    
              
    const fetchMoreData = async(data) => {
        
        if(lTareas.length < cantidad.total){
        const res = await Call('GET', `/api/tareas?estado=${estadoSelected}&categoria=${bandeja}&filtro=${buscar}&orden=${orden === null ? '' : orden}&leido=${leido}&asignacion=${asignacion}&start=${lTareas.length}&length=15&ordendir=desc`, true, null, false)
        
        let nuevo= lTareas.concat(res.data.data)
        setTimeout(() => {
            
            setLtareas(nuevo)
        }, 1000);
        }
    }
    const fetchMoreDataComents = async(data) => {        
        if(comentarios.length < cantidadComments.total){
                
            const res= await Call('GET', 'api/tareas/detalles/'+idSelected+'?start='+comentarios.length+'+&length=3', true, null)
            let new_comments= comentarios.concat(res.data.data.data.reverse())
            setTimeout(() => {
            setComentarios(new_comments)
            }, 1000);
        }
    }
    const iconRender = (file) => {
        // const isJPG = file.type === 'image/jpeg';
        const isTXT = file.type === 'text/plain'
        const isPDF = file.type === 'application/pdf' 
        const isCSV = file.type === 'text/csv'
        const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        const isPWP = file.type === 'application/pdf'
        const isWORD = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        const isZIP = file.type === 'application/x-zip-compressed'
        const isMP4 = file.type === 'video/mp4'
        if(isTXT){
            return (<i className="fas fs-22 fa-file-alt fa-2xl justify-content-center"/>)
        }
        if(isPDF){
            return (<i className="fas fs-22 fa-file-pdf fa-2xl justify-content-center"/>)
        }
        if(isXLSX){
            return (<i className="fas fs-22 fa-file-excel fa-2xl justify-content-center"/>)
        }
        if(isPWP){
            return (<i className="fas fs-22 fa-file-powerpoint fa-2xl justify-content-center"/>)
        }
        if(isWORD){
            return (<i className="fas fs-22 fa-file-word fa-2xl justify-content-center"/>)
        }
        if(isCSV){
            return (<i className="fas fs-22 fa-file-csv fa-2xl justify-content-center"/>)
        }
        if(isZIP){
            return (<i className="fas fs-22 fa-file-archive fa-2xl justify-content-center"/>)
        }
        if(isMP4){
            return (<i className="fas fs-22 fa-file-video fa-2xl"/>)
        }
        if(!isTXT && !isPDF && !isXLSX && !isPWP && !isWORD && !isCSV && !isZIP && !isMP4){
            return (<i className="fas fs-22 fa-file-alt fa-2xl justify-content-center"/>)
        }
    }
    const cargarArchivos = ({fileList}, file)=>{
        if(fileList[fileList.length-1] && fileList[fileList.length-1].size && ((fileList[fileList.length-1].size/1024/1024)> 8)){
        message.error('El archivo debe ser menor a 8M');
        }else {
            setData({fileList})
            var objDiv = document.getElementById("modaldet-movilB")
            if (objDiv) {
                setTimeout(() => {
                    objDiv.scrollTop = objDiv.scrollHeight
                }, 500)
            }
        }
    }
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
        
    }
    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
    }
             
    const beforeUpload = (file, fileList) => {
        return false;
    }
            
    const ver = async(url) =>{
        await setUrl(url)
        $('#modalMoreInfo').modal('show')
    }
            
    const downloadFile = async (url, nombre)=>{
        // let token= decode(localStorage.getItem('authInfo')).token
        axios({
            url:`${url}`,
            method: 'GET',
            responseType: 'blob'
        })
            .then((response) => {
                    const url = window.URL
                        .createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', nombre);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
            })
    }
    // VER PREVIOSUALIZACION DE UNA IMAGEN
    const SeeImage = () =>{
        return(      
          <div className="modal fade" id="modalMoreInfo" tabIndex="-1" role="dialog" aria-labelledby="modalMoreInfoTitle" aria-hidden="true" style={{background:'transparent', display:'block'}}>
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
              <div className="modal-content position-relative" style={{maxHeight: '95vh'}}>
                <button type="button" className="close position-relative p-1 pr-2 ms-auto" data-bs-dismiss="modal" aria-label="Close" style={{background:'transparent', border:'none'}} >
                  <span aria-hidden="true" className="fs-30" style={{color: 'white'}} onClick={()=>{setUrl(null)}} >&times;</span>
                </button>
                <div className="modal-body py-1 mb-2 px-4 admin-text-g">
                  <div className="--header">
                    <div className="img">
                      <img src={url} alt="img modal" className="img-fluid shadow rounded" style={{maxHeight:"80vh"}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
    const infoTareas2 =()=>{
        $('html,body').css('overflow', 'inherit')
       setShowModalFlujos(false)
    }
      
    const set =(e) =>{
        setSearchText(e.target.value)
        // if(lFlujos){
        if(e.target.value === ''){
            setLoad(false)
        }
        let a=[]
        for(let i=0; i< lFlujos.length; i++){
        for(let j=0; j< lFlujos[i].flujos.length;j++){
            a.push(lFlujos[i].flujos[j])
        }
        }
          
        clearTimeout(time) 
        setTime(setTimeout(() => 
        {
            let b= a.filter(f => (f.nombre.toLowerCase().includes(e.target.value.toLowerCase())))
            setbFlujos(b)
            setLoad(true)
        }, 800))   
          
    }
    const showmodalanswer=(open)=>{
        if (open){
            $('html,body').css('overflow', 'hidden');
        }else{
            $('html,body').css('overflow', 'inherit')
        }
    }
    const modalDetail =()=>{
        const staticBackdrop=document.getElementById("staticBackdrop");
        if(staticBackdrop){
            $('#staticBackdrop').modal('hide')
        }
        setShowAnswer(false)
    }
  
    const editarResponsable = async(tipo) =>{
        setSp(true)

        if(tipo === 'destinatario'){

            let grupoDestinatario =[]
            let usuariosDestinatario=[]
            let gDestinatarioSave=[]
            let uDestinatarioSave=[]
            
            if(detalle.grupo.length > 0){
                for(let i=0; i<detalle.grupo.length; i++){
                    let d={
                        value: 'g'+detalle.grupo[i].id,
                        label: detalle.grupo[i].apellidos_nombres
                    }
                    grupoDestinatario.push('g'+detalle.grupo[i].id)
                    gDestinatarioSave.push(detalle.grupo[i].id)
                }
            }
            
            if(detalle.user.length > 0){
                for(let k=0; k<detalle.user.length; k++){
                    usuariosDestinatario.push('u'+detalle.user[k].id)
                    uDestinatarioSave.push(detalle.user[k].id)
                }
            }
            let totalDestinatarios= grupoDestinatario.concat(usuariosDestinatario)
            setUserDeSelected(uDestinatarioSave)
            setGrupoDSelected(gDestinatarioSave)
            setValuesGdestinatarios(totalDestinatarios)
            setShowDestinatario(true)
            setSp(false)
        }else{
            let valResponsables=[]
            if(detalle.responsable.length > 0){
                for(let j=0; j<detalle.responsable.length; j++){
                let d={
                        value: 'u'+detalle.responsable[j].id,
                        label: detalle.responsable[j].apellidos_nombres
                    }
                    valResponsables.push('u'+detalle.responsable[j].id)
                }
                setValuesResponsables(valResponsables)
            }
          
            let grupos= []
            let user=[]
            let todos=[]
            for(let i=0; i< detalle.grupo.length; i++){
                grupos.push(parseInt(detalle.grupo[i].id))
            }
            for(let j=0; j< detalle.user.length; j++){
                user.push(detalle.user[j])
            }
              
            if(grupos.length > 0){
                const res = await Call('GET', `/api/usuarios-destinatatios?groups[]=${grupos}`, true, false)
                    todos = res.data.results.concat(user)
                    let hash = {};
                    let userUnicos  = todos.filter(o => hash[o.id] ? false : hash[o.id] = true);
                                        
                    setListResponsables(userUnicos)

            }else{
                setListResponsables(user)
            }
            setShowResponsables(true)
            setSp(false)
        }
    }

    const actualizarResponsables = async(tipo)=>{
        setSp(true)

        if(tipo === 'destinatarios'){
			let datos ={
				destinatarios_usuarios: userDeSelected,
				destinatarios_grupos: grupoDeSelected,
			}
			
			const res= await Call('PUT', 'api/tareas/edit/'+idSelected, true, datos, false )
			
			if(res.data.code === 1){
                setDetalle(res.data.data)

                const response = await Call('GET', `/api/tareas?estado=${estadoSelected}&categoria=${bandeja}&filtro=${buscar}&orden=${orden === null ? '' : orden}&leido=${leido}&start=0&length=15&ordendir=desc`, true, null, false)


                if(response.data.data){
                    setLtareas(response.data.data)
                    setCantidad({
                        total: response.data.recordsTotal
                    })
                    if(response.data.data.length>0){
                        const res2 = await Call('GET', '/api/tareas/'+response.data.data[0]?.id, true, null, false)
                        const res3 = await Call('GET', 'api/tareas/detalles/'+response.data.data[0]?.id+'?start=0&length=3', true, null)

                        if(res3.data.code === 1){
                            setComentarios(res3.data.data.data)
                            setCantidadComments({total:res3.data.data.recordsTotal})
                        }else{
                            setComentarios([])
                        }
                        setDetalle(res2.data.data)
                        setIdSelected(response.data.data[0]?.id)
                        marcar_visto(response.data.data[0], true)
                    }else{
                        setDetalle(null)
                        setIdSelected(null)
                    }


                }
				setSp(false)
                setShowDestinatario(false)

			}else{
				Swal.fire({
					title: '',
					icon: 'info',
					html: res.data.message,
					showCloseButton: true,
					showCancelButton: false,
					focusConfirm: false,
					confirmButtonText:'Aceptar',
				  })
				  setSp(false)
			}
		}else{
			let data={
				responsables: responsablesSelect,
				
			}
			
			const res = await Call('PUT', 'api/tareas/edit/'+idSelected, true, data, false )
			
			if(res.data.code === 1){
                setDetalle(res.data.data)
				setSp(false)
				setShowResponsables(false)
			}
		}
    }

    const  handlePaste = async (e) => {
      if (e.clipboardData.files.length) {
            e.preventDefault();
            let uid = crypto.randomUUID()
            let fileObject = e.clipboardData.files[0];
            fileObject.uid =uid
            let url = await getBase64(e.clipboardData.files[0])
            let obj = {
                lastModified:fileObject.lastModified,
                lastModifiedDate:fileObject.lastModifiedDate,
                name:fileObject.name,
                percent:0,
                size:fileObject.size,
                thumbUrl:url,
                type:fileObject.type,
                uid:uid,
                originFileObj:fileObject
            } 
            data.fileList.push(obj)
            setData(data)
            cargarArchivos(data)
            getBinaryFiles()

        }
        return false
    }
    const infoTareas =()=>{
        const modalPrioridades=document.getElementById("modalinfo-prioridades");
        if(modalPrioridades){
            const modal = window.bootstrap.Modal.getOrCreateInstance(modalPrioridades)
            modal.hide();
        }
        setShowModalPrioridades(false)
        setShowModalFlujos(true)
        $('html,body').css('overflow', 'hidden');
    }
  return (
    <>
        {
            showModalPrioridades &&
            <>
                <div className='modal fade show' id="modalinfo-prioridades" style={{display: 'block'}}>
                        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document" style={{ width:'fit-content'}}>
                            <div className="modal-content fondo-movil">
                                <div className="modal-header border-bottom-0 pb-0">
                                </div>
                                <div className="modal-body detalle-tarea pt-0" style={{height: 'auto'}}>
                                    <img src="/assets/images/img-prioridad.png" alt="" className='img-fluid' style={{maxHeight:'75vh' }}/>
                                </div>
                            <div className="btn-infot" >

                                <div className='cursor-pointer btn-aceptar-info' onClick={infoTareas} >Aceptar</div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
            </>
        }
        { sp ? <Spinner /> : ''}  {/*LOADER*/}
        { newT.estado && <NewTask parentToChild={newT} childToParent={childToParent} />} {/*LOADER*/}
        { modal && 
            <>
              <div className='modal fade show' style={{display: 'block', zIndex:'892'}} id="modaldet-movil">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
                  <div className="modal-content fondo-movil" style={{height: '90vh'}}>
                    <div className="modal-header border-bottom-0 pb-0 px-3">
                    <div  className="ms-auto" onClick={()=>{setModal(false); setInfoComments(''); showmodalanswer(false); setData({previewVisible: false, previewImage: '', previewTitle: '', fileList:[]})}}>
                      <button type="button" onClick={()=>{setModal(false); setInfoComments(''); showmodalanswer(false); setData({previewVisible: false, previewImage: '', previewTitle: '', fileList:[]})}} 
                      className="btn-close" data-bs-dismiss="modal" aria-label="Close" >
                      </button>
                    </div>
                    </div>
                    <div className="modal-body detalle-tarea p-0 pb-0 mb-0" 
                    id="modaldet-movilB"
                    style={{height: 'auto', overflowY:'auto'}}
                    >
                      <div className='fondo-texto px-3 pt-3'>
                      <div className='encabezadod fw-bold'>#{detalle && detalle.id+' - '+detalle.asunto}</div>
                      <div className='cambiar-estados text-end' >
                            { 
                              // (bandeja !== 'Enviados') &&
                              (detalle && (detalle.estado === 'completada' || detalle.estado === 'rechazada') ?
                              <Tooltip color={'#aba7a7c2'} title="Volver a pendiente"><span className='p-2 pausar' onClick={()=>cambiarEstado('pendiente', 'movil')}><i title="" className="fas fa-history"></i></span></Tooltip>
                              : (<>
                                  <Tooltip color={'#aba7a7c2'} title="Rechazar">
                                    <span className='p-2 descartar' onClick={()=>cambiarEstado('rechazada', 'movil')}><i title="" className="fas fa-times-circle"></i></span>
                                  </Tooltip>
                                    
                                  {detalle && (detalle.estado === 'pausada') ? <Tooltip color={'#aba7a7c2'} title="Volver a pendiente"><span className='p-2 pausar' onClick={()=>cambiarEstado('pendiente', 'movil')}><i title="" className="fas fa-history"></i></span></Tooltip> : <Tooltip color={'#aba7a7c2'} title="Pausada"><span className='p-2 pausar' onClick={()=>cambiarEstado('pausada', 'movil')}><i title="" className="fas fa-pause-circle"></i></span></Tooltip>}
                                  
                                  {(detalle && ((detalle.show_btn_actions===true) || (detalle.usuario.id === user.id))) ?<Tooltip color={'#aba7a7c2'} title="Completada">
                                    <span className='p-2 finalizar' onClick={()=>cambiarEstado('completada', 'movil')}><i title="" className="fas fa-check-circle"></i></span>
                                  </Tooltip>:''}
                                  { (detalle && detalle.show_btn_actions===false)? <Tooltip color={'#aba7a7c2'} title="Esta tarea esta programada para cerrarse automaticamente">
                                    <span className='p-2' ><i title="" className="fas fa-exclamation-circle"></i></span>
                                  </Tooltip>:''}                                  
                                </>
                              ))
                            }
                        </div>
                        <div className='d-flex'>
                            <div className='me-2 img-encabezado-user'>
                                <img src={`${detalle && detalle.usuario.foto && detalle.usuario.foto}`} alt="" height="25"  />
                            </div>
                            <div className='detalle-datos'>
                                <div className='text-capitalize remitente'>{detalle && detalle.usuario.apellidos_nombres.toLowerCase()}</div>
                                <div className='fecha-creacion fs-12'>Fecha de creación: <span>{detalle && detalle.created_at}</span></div>
                                <div className='destinatario fs-12 d-flex flex-wrap'>
                                    Para: 
                                    { !showDestinatario ?
                                        <> 
                                            { detalle&& 
                                            detalle.grupo && detalle.grupo.map((dg, indexdg)=>(
                                                <div key={indexdg} className="my-1">
                                                <span className='mx-1 text-capitalize'>{dg.name.toLowerCase()}</span>
                                                </div>
                                            ))
                                            }
                                            {
                                            detalle && detalle.user.map((du, indexu)=>(
                                                <div key={indexu}>
                                                <span className='mx-1 text-capitalize'>{du.apellidos_nombres.toLowerCase()}</span>
                                                </div>
                                            ))
                                            }
                                            <span onClick={()=>editarResponsable('destinatario')}> <i className="fas fa-edit cursor-pointer fs-16"></i></span>
                                        </>
                                        :
                                            <>
                                                    <div>
                                                        <Select
                                                            mode="multiple"
                                                            allowClear
                                                            onChange={(value, options)=>handleChangeRes(value, options, 'destinatarios')}
                                                            style={{ maxWidth: '60%' }}
                                                            className="select-responsable select-destinatario"
                                                            showSearch
                                                            defaultValue={valueGdestinatarios}
                                                            placeholder="Selecciona un destinatario"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>{
                                                                if(option.children){
                                                                return option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                                                }else if(option.label){
                                                                return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                                                }
                                                                }}
                                                            filterSort={(optionA, optionB) =>
                                                            optionA.children?.toLowerCase().localeCompare(optionB.children?.toLowerCase())
                                                            }
                                                        >
                                                            <OptGroup label="Usuarios">
                                                                {lUsuarios &&  lUsuarios.map((u, indexu)=>(
                                                                    <Option key={'u'+indexu} value={'u'+u.id} name="user">{u.apellidos_nombres}</Option>
                                                                )) }
                                                                
                                                            </OptGroup>
                                                            <OptGroup label="Grupos">
                                                                {
                                                                    lGupoUsuarios && lGupoUsuarios.map((u, indexgu)=>(
                                                                        <Option key={'g'+indexgu} value={'g'+u.id} name="group">{u.name}</Option>
                                                                    ))
                                                                }
                                                            </OptGroup>
                                                    </Select>
                                                </div>
                                                <span onClick={()=>actualizarResponsables('destinatarios')} className="m-2"><i className="fas fa-check text-success fs-16 cursor-pointer"></i></span>
                                            <span onClick={()=>{ setShowDestinatario(false) }}><i className="fas fa-times text-danger fs-16 cursor-pointer"></i></span>
                                            </>
                                        }
                                </div>
                                <div  className='destinatario fs-12 d-flex flex-wrap'>
                                    Responsable:
                                    {
                                    (detalle && detalle.responsable.length !== 0 && !showResponsables) ?
                                       detalle &&  detalle.responsable.map((r, indexr)=>(
                                        <div key={indexr} className="my-1">
                                            <span className='mx-1 text-capitalize'>{r.apellidos_nombres.toLowerCase()}</span>
                                        </div>
                                        ))
                                        :
                                        !showResponsables &&
                                        ' -'
                                    }
                                    {
                                    !showResponsables ?
                                        (user.id !== (detalle && detalle.usuario.id) ?
                                        <span className='ms-2 cursor-pointer' onClick={editarResponsable}> <i className="fas fa-edit cursor-pointer fs-16"></i></span>
                                        :'')
                                        :
                                        <>
                                            <div style={{ width: '100%' }}>
                                                <Select
                                                mode="multiple"
                                                allowClear
                                                onChange={(value, options)=>handleChangeRes(value, options)}
                                                style={{ maxWidth: '60%' }}
                                                defaultValue={valuesResponsables}
                                                onSelect={onSelect}
                                                className="select-responsable select-destinatario"
                                                showSearch
                                                placeholder="Selecciona un destinatario"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>{
                                                    if(option.children){
                                                    return option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                                    }else if(option.label){
                                                    return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                                    }
                                                    }}
                                                filterSort={(optionA, optionB) =>
                                                optionA.children?.toLowerCase().localeCompare(optionB.children?.toLowerCase())
                                                }
                                                >
                                                    <OptGroup>
                                                        {listResponsables &&  listResponsables.map((u, indexu)=>(
                                                            <Option key={'u'+indexu} id={u.id} value={'u'+u.id} name="user">{u.apellidos_nombres}</Option>
                                                        )) }
                                                        
                                                    </OptGroup>
                                                </Select>
                                        </div>
                                        <div className='d-flex'>
                                            <span onClick={actualizarResponsables} className="mx-2 bg-transparent"><i className="fas fa-check text-success fs-16 cursor-pointer"></i></span>
                                            <span onClick={()=>{ setShowResponsables(false) }} className="bg-transparent"><i className="fas fa-times text-danger fs-16 cursor-pointer"></i></span>
                                        </div>	
                                        </>
                                    }
                                </div>
                                <div className='fecha-vencimiento fs-12'>
                                    Fecha de vencimiento: <span>{detalle && detalle.vencimiento === null ? '-' : moment(detalle && detalle.vencimiento).format('DD/MM/YYYY')}</span>
                                </div>
                                <div className='estado fs-12 fw-bold'>
                                    Estado: <span className={`${detalle && detalle.estado === 'pendiente' ? 'pendiente' : (detalle && detalle.estado === 'pausada' ? 'pausada' : (detalle && detalle.estado === 'completada' ? 'completada' : 'rechazada')) } text-capitalize`}>{detalle && detalle.estado}</span>
                                </div>
                            </div>
                        </div>
                        <div className='detalle-mensaje' dangerouslySetInnerHTML={{ __html: detalle && detalle.descripcion }} />
                        <hr/>
                        <div className='file-task'>
                        { 
                          detalle && detalle.files&& detalle.files.length > 0 &&
                          detalle && detalle.files&& detalle.files.map((f, indexf)=>(
                              <div key={indexf} className="mx-1 d-flex justify-content-between file">
                                {(f.filename.toLowerCase().split(".")[1] === "jpeg" || f.filename.toLowerCase().split(".")[1] === "png" || f.filename.toLowerCase().split(".")[1] === "jpg") &&
                                  <img src={f.url} alt="" className='img-fluid p-2'/>
                                }
                                {f.type === "application/pdf" && 
                                  <i className="fas fa-file-pdf fa-2xl justify-content-center p-2 file-pdf"/>
                                }
                                {
                                  f.type === "video/mp4" &&
                                    <i className="fas inpuload fa-file-video file-pdf p-2 fa-2xl"/>
                                }
                                {   f.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
                                    <i className="fas inpuload fa-file-excel fa-2xl file-pdf p-2 justify-content-center"/>
                                }
                                {
                                    f.type === 'text/plain' &&
                                    <i className="fas inpuload fa-file-alt fa-2xl file-pdf p-2 justify-content-center"/>
                                }
                                {
                                   ( f.type === 'application/vnd.rar' || f.type === 'application/zip') &&
                                    <i className="fas inpuload fa-file-archive fa-2xl file-pdf p-2 justify-content-center"/>
                                }
                                {
                                   ((f.filename.toLowerCase().split(".")[1] !== "jpg" )&&(f.filename.toLowerCase().split(".")[1] !== "png")&&(f.filename.toLowerCase().split(".")[1] !== "jpeg") && (f.type !== "application/pdf")&&(f.type !== "video/mp4")&&(f.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")&&(f.type !== 'text/plain')&&( f.type !== 'application/vnd.rar') && (f.type !== 'application/zip')) &&
                                    <i className="fas inpuload fa-file-archive fa-2xl file-pdf p-2 justify-content-center"/>
                                }
                                <div className='my-auto'>
                                 
                                  <div className='text-file'><Tooltip color={'#aba7a7c2'} title={f.filename}>{f.filename}</Tooltip></div>
                                  <div className='d-flex'>
                                    {(f.filename.toLowerCase().split(".")[1] === "jpeg" || f.filename.toLowerCase().split(".")[1] === "png" || f.filename.toLowerCase().split(".")[1] === "jpg") &&
                                      <div className='mx-1 my-auto cursor-pointer' onClick={()=>{ver(f.url)}}><i className="fas fa-eye"></i></div>
                                    }
                                    <div className='mx-1 my-auto icons-file' >
                                        {/* <a href={f.url} target="_blank"> */}
                                        <i className="fas fa-download cursor-pointer" onClick={() => downloadFile(f.url, f.filename)}></i>
                                        {/* </a> */}
                                       
                                        </div>
                                  </div>
                                </div>
                              </div>
                          ))
                        }
                        </div>
                        {detalle && detalle.files && detalle.files.length > 0 && <hr />}
                        {
                            detalle && detalle.respuestas_formulario && detalle.respuestas_formulario.length> 0 ?
                            <>
                                {/* <div className='texto d-flex'>
                                    <span className='fs-14 fw-600 me-2'>Formularios</span>
                                    <div className='cursor-pointer text-danger' data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={()=>{setModal(false) }}>
                                        <i className="fas fa-clipboard-check fs-18"></i> <span className='fs-14' onClick={showmodalanswer}>Respuestas</span>
                                    </div>
                                </div> */}
                                <FormViewResponseDetail respuestas_formulario={detalle && detalle.respuestas_formulario[0]} modalDetail={modalDetail} is_tarea_view={true}/>
                                <hr className='mt-2'/>
                                </>
                            :''
                        }
                     </div>
                        <div className='encabezadod border border-bottom-0 px-3 pb-0 pt-2 fw-bold'>Comentarios</div>
                        <hr className='w-90' />
                        <div className='comentarios'> 
                            <InfiniteScroll
                            height={data.fileList.length === 0 ? '45vh': '45vh' }
                            dataLength={comentarios.length} //This is important field to render the next data
                            className="box-list-tareas"
                            next={fetchMoreDataComents}
                            style={{ display: "flex", flexDirection: "column-reverse", overflowX:'hidden' }} //To put endMessage and loader to the top.
                            scrollableTarget="scrollableDiv"
                            inverse={true} //
                            hasMore={(comentarios.length < 3 || comentarios.length === cantidadComments.total ) ? false : true}
                            loader={<div className='d-flex justify-content-between mt-2'>
                                <Skeleton.Button active size={'large'} shape={'circle'} className="px-2"  />
                                <Skeleton.Button active size={'large'} style={{height: '80px'}} shape={'buttonShape'} className="pr-2" height="300" rows={3} width={'10px'} block={'block'} />
                                </div>
                                }
                            >
                                {
                                    comentarios && 
                                    comentarios.length > 0 ?
                                    comentarios.map((d, indexde)=>(
                                    <div key={indexde} style={{width:'97%'}}>
                                        <div className={`d-flex my-2 ${(d.usuario.id === user.id && d.usuario.apellidos_nombres.toLowerCase() !== 'john' ) ? 'ms-auto mensaje-r' : ((d.usuario.id !== user.id && d.usuario.apellidos_nombres.toLowerCase() !== 'john' ) ? 'mensaje-l' : 'mensaje-bot')}`} >
                                            {/* <div className='img-user'>
                                                <img src={`${d.usuario.foto}`} alt="" width="30" height="30" />
                                            </div> */}
                                            <div className="contenido">
                                                <div className='destinatario text-capitalize'>{d.usuario.apellidos_nombres.toLowerCase()}</div>
                                                <div className='contenido fecha'>{d.fecha && d.fecha}</div>
                                                <div className='contenido' dangerouslySetInnerHTML={{ __html: d.contenido }} />
                                                <div className='file-task'>
                                                    { d.files &&
                                                        d.files.length >0 &&
                                                        d.files.map((df, indexdf)=>(
                                                            <div key={indexdf} className="d-flex file-commnets" >
                                                                <div>
                                                                    {(df.filename.toLowerCase().split(".")[1] === "jpeg" || df.filename.toLowerCase().split(".")[1] === "png" || df.filename.toLowerCase().split(".")[1] === "jpg") &&
                                                                    <img src={df.url} alt="" className='img-fluid p-2'/>
                                                                    }
                                                                    {df.type === "application/pdf" && 
                                                                    <i className="fas inpuload fa-file-pdf fa-2xl justify-content-center p-2 file-pdf"/>
                                                                    }
                                                                    {
                                                                    df.type === "video/mp4" &&
                                                                        <i className="fas inpuload fa-file-video file-pdf p-2 fa-2xl"/>
                                                                    }
                                                                    {   df.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
                                                                        <i className="fas inpuload fa-file-excel fa-2xl file-pdf p-2 justify-content-center"/>
                                                                    }
                                                                    {
                                                                        df.type === 'text/plain' &&
                                                                        <i className="fas inpuload fa-file-alt fa-2xl file-pdf p-2 justify-content-center"/>
                                                                    }
                                                                    {
                                                                    ( df.type === 'application/vnd.rar' || df.type === 'application/zip') &&
                                                                        <i className="fas inpuload fa-file-archive fa-2xl file-pdf p-2 justify-content-center"/>
                                                                    }
                                                                    {
                                                                    ((df.filename.toLowerCase().split(".")[1] !== "jpeg") && (df.filename.toLowerCase().split(".")[1] !== "png") && (df.filename.toLowerCase().split(".")[1] !== "jpg")
                                                                    && (df.type !== "application/pdf") && (df.type !== "video/mp4") && ( df.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") && (df.type !== 'text/plain') && (df.type !== 'application/vnd.rar') && (df.type !== 'application/zip') ) &&
                                                                            <i className="fas inpuload fa-file-alt fa-2xl file-pdf p-2 justify-content-center"/> 
                                                                    }
                                                                </div>
                                                                <div className='my-auto'>
                                                                    <div className='text-file'><Tooltip color={'#aba7a7c2'} title={df.filename}>{df.filename}</Tooltip></div>
                                                                    <div className='d-flex'>
                                                                        {(df.filename.toLowerCase().split(".")[1] === "jpeg" || df.filename.toLowerCase().split(".")[1] === "png" || df.filename.toLowerCase().split(".")[1] === "jpg" ) &&
                                                                        <div className='mx-1 my-auto cursor-pointer' onClick={()=>{ver(df.url)}}><i className="fas fa-eye"></i></div>
                                                                        }
                                                                        <div className='mx-1 my-auto icons-file cursor-pointer'>
                                                                            <i className="fas fa-download cursor-pointer" onClick={() => downloadFile(df.url, df.filename)}></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                {/* <div style={{textAlign: 'end',color: '#6f6f6f99'}}>
                                                    <i className="fas fa-check"></i>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    ))
                                :
                                <div className='mb-auto fs-15'>Aún no hay comentarios en esta tarea</div>
                            }
                            </InfiniteScroll>
                        </div>
                        {/* <div className=""> */}
                            <div className='d-flex box-comments'>
                            <div className='upload-img-comments' style={{overflowX:'auto', overflowY:'hidden'}} >
                            <div className='icon-upload cursor-pointer'>
                            { detalle && detalle.estado !== 'completada' ?
                                    <Upload
                                    fileList={data.fileList}
                                    className='content upload-task'
                                    listType="picture"
                                    onChange={cargarArchivos}
                                    iconRender={iconRender}  
                                    onPreview={handlePreview}
                                    beforeUpload={(file, fileList)=>beforeUpload(file, fileList)}  
                                    uploading={true}                                            
                                    >
                                    {
                                    data.fileList.length < 5 &&
                            
                                        <span className='icono1' id="icono1">
                                            <svg height="24" width="24" viewBox="0 0 24 24"
                                            style={{ display: "block"}}
                                            >
                                            <path d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z"
                                                fill="currentColor"
                                            />
                                            </svg>
                                        </span>
                                        }
                                    </Upload>
                                    :
                                        <div className='content-disabled'>
                                            <span className='icono1'>
                                                <svg height="24" width="24" viewBox="0 0 24 24"
                                                style={{ display: "block", pointerEvents: "none" }}
                                                >
                                                <path d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z"
                                                    fill="currentColor"
                                                />
                                                </svg>
                                            </span>
                                        </div>
                                }
                                </div>
                            </div>
                            <div className='d-flex typing-comments'>
                            <div className='m-3 box-letter' style={{background: 'aliceblue'}}>
                                { detalle && detalle.estado !== 'completada' ?
                                    <div onPaste={handlePaste} 
                                        placeholder="Escribe un comentario"
                                        type="textarea"
                                        id="txtarea"
                                        className="_13NKt copyable-text selectable-text max-h fake-textarea bg-white p-1 cursor-text"
                                        // onChange={handleChangeComments} 
                                        onInput={(e)=>handleChangeComments(e)} 
                                        contentEditable="true"
                                        // cols="30" 
                                        rows="2"
                                        title="Escribe un mensaje aquí"
                                    ></div>
                                    :
                                    <textarea
                                        placeholder="Escribe un comentario"
                                        type="text"
                                        style={{resize: 'none'}}
                                        className="_13NKt copyable-text selectable-text cursor-nallowed"
                                        // contentEditable="true"
                                        dir="ltr"
                                        disabled
                                        // cols="30" rows="1"
                                        id="txtarea"
                                        spellCheck
                                        title="Escribe un mensaje aquí"
                                    ></textarea>
                            }
                            </div>
                            {  detalle && detalle.estado !== 'completada' ?
                                    <div className="icon-send" >
                                    <button
                                        className="_30ggS"
                                        aria-label="Mensaje de voz">
                                        <span onClick={()=>addComments (detalle.usuario&& detalle.usuario.id)} className="_30ggsss">
                                        <i className="fas fa-paper-plane fs-18"></i>
                                        </span>
                                    </button>
                                    </div>
                                :
                                <div className="icon-send">
                                    <button
                                    className="_30ggS"
                                    aria-label="Mensaje de voz">
                                    <span className="_30ggsss cursor-nallowed disabled">
                                        <i className="fas fa-paper-plane fs-18"></i>
                                    </span>
                                    </button>
                                </div>

                            }
                            
                            </div>
                            </div>
                        {/* </div> */}
                   </div>
                 </div>
               </div>
            </div>
            <div className="modal-backdrop fade show" style={{zIndex:'890'}}></div>
          </>
          }
        <TitleLayout data={datat}  icon={true} seccion={'tareas'} clase={"text-center"} />
        {/* ///////// MOVIL ////////// */}
         {/* MODAL INICIO FLUJOS*/}
        { showModalFlujos &&
            <>
                <div className="modal fade show" id="exampleModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModal" aria-hidden="true" style={{display: 'block'}}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header title-modal">
                                <div className="title m-auto w-100" id="exampleModal">Seleccione una tarea</div>
                                <button type="button" className="btn-close" onClick={infoTareas2}></button>
                            </div>
                            <div className="modal-body" style={{height: '80vh', overflowY: 'auto'}}>
                                <div className="input-group input-group-sm mb-3 mt-1">
                                <span className="input-group-text buscar-tareas rounded-0" id="basic-addon1"><i
                                    className="fas fa-search"></i></span>
                                    <input type="text" className='form-control form-control-sm border border-start-0'
                                           placeholder="&#xF002;  Buscar" onChange={set} value={searchText}/>
                                </div>
                                <div className='body-list-task'>
                                    {(load && searchText !== '') ?
                                        (
                                            bFlujos && bFlujos.length > 0 ?
                                                <div
                                                    className="card-body d-flex flex-wrap justify-content-center list-flujo m-regular">
                                                    {bFlujos && bFlujos.map((flu, indexflu) => (
                                                        <div className='ltarea py-4 my2' key={indexflu} onClick={() => {
                                                            parentToChild(flu.id);
                                                            setInfoPrioridades({...infoPrioridades, estado: false})
                                                        }}>
                                                            <div className='card-flujo'>
                                                                <i className={flu.icon}></i>
                                                            </div>
                                                            <div className='mt-2 w-50'>{flu.nombre}</div>
                                                        </div>
                                                    ))
                                                    }
                                                </div>
                                                :
                                                <div className="ticket-empty-modal">
                                                    <div><i className="fas fa-box-open fs-20"></i></div>
                                                    <div className="text-empty">En este momento no hay nada para mostrar
                                                    </div>
                                                </div>
                                        )
                                        :
                                        <>
                                            <div className="accordion" id="accordionExample">
                                                {lFlujos && lFlujos.map((l, indexl) => (
                                                    <div id={indexl}>
                                                        <div className="">
                                                            <div className="accordion-header" id="headingOne"
                                                                 type="button"
                                                                 data-bs-toggle="collapse"
                                                                 data-bs-target={"#" + l.categoria.replace(/ /g, "") + 'l'}
                                                                 aria-expanded="false" aria-controls="collapseOne">
                                                                <h2 className="mb-0">
                                            <span className="btn btn-block text-start text-capitalize fw-600 m-regular">
                                                {l.categoria}
                                                {/* <span className='float-right'>
                                                    <i className="fas fa-chevron-down"></i>
                                                </span> */}
                                            </span>

                                                                </h2>
                                                            </div>
                                                            <div id={l.categoria.replace(/ /g, "") + 'l'}
                                                                 className="collapse"
                                                                 aria-labelledby="headingOne"
                                                                 data-bs-parent="#accordionExample">
                                                                <div className="card-body d-flex flex-wrap list-flujos">
                                                                    {l.flujos && l.flujos.map((f, indexfl) => (
                                                                        <div className='ltarea py-4  my-2 mx-2'
                                                                             key={indexfl}
                                                                             onClick={() => {
                                                                                 parentToChild(f.id);
                                                                                 setInfoPrioridades({
                                                                                     ...infoPrioridades,
                                                                                     estado: false
                                                                                 })
                                                                             }}>
                                                                            <div className='card-flujo'>
                                                                                <i className={f.icon}></i>
                                                                            </div>
                                                                            <div
                                                                                className='mt-2 w-50 over-wrap'>{f.nombre}</div>
                                                                        </div>
                                                                    ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}</div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
            </>
        }

        {/* MODAL FIN FLUJOS*/}

        {/* ////////// FIN MOVIL ///////// */}
        <div className='btn-add-tareas' onClick={() => {
            setShowModalPrioridades(true)
        }}><i className="fa-solid fa-plus"></i> <span className='font-weight-600' onClick={() => {
            setShowResponsables(false);
            setShowModalPrioridades(true)
        }}>Nueva tarea</span></div>
        <div className='tareas-app pb-1' style={{height: '130vh'}}>
            <SplitPane
                split='vertical'
                sizes={sizes}
                onChange={setSizes}
                style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}
            >
                <Pane maxSize='50%'>
                    <div style={style('white')}>
                        <div className='p-2'
                             style={{boxShadow: '0px 1px 0px 0px rgba(60, 64, , 0.3)', alignSelf: 'flex-start'}}>
                            <div id="lista" className='d-flex justify-content-between'>
                                <div>
                                    <span
                                        className={`encabezado-tareas text-dark ${bandeja === 'Recibidos' ? 'active' : ''}`}
                                        onClick={() => {
                                            nuevosDatos('bandeja', 'Recibidos')
                                        }}>Recibidos</span>
                                    <span
                                        className={`encabezado-tareas text-dark ${bandeja === 'Enviados' ? 'active' : ''}`}
                                        onClick={() => {
                                            nuevosDatos('bandeja', 'Enviados')
                                        }}>Enviados</span>
                                </div>
                                <div>
                            <span className="mainmenu">
                                    <div className="dropdown">
                                        <Link to='/#'
                                              className="nav-link dropdown-toggle fs-15 text-info cursor-pointer fw-bold"
                                                data-bs-toggle="dropdown">Filtro</Link>
                                        <div className="dropdown-menu dropdown-menu-end">
                                            {
                                                estadosTareas && estadosTareas.map((e, indexe) => (
                                                    <li className={`dropdown-item estadosTareas ${estadoSelected === (e) ? 'active' : ''}`}
                                                        key={indexe} onClick={() => {
                                                        nuevosDatos('estado', e)
                                                    }}>{e}</li>
                                                ))
                                            }
                                            <li className={`dropdown-item estadosTareas ${estadoSelected === 'Todos' ? 'active' : ''}`}
                                                onClick={() => {
                                                    nuevosDatos('estado', 'Todos')
                                                }}>Todos</li>
                                            <span className='dropdown-item disabled btn-order'>Orden</span>
                                            <>
                                                <li className={`dropdown-item btn-order-opciones cursor-pointer ${orden === 'Fecha' ? 'active' : ''}`}
                                                    onClick={() => {
                                                        nuevosDatos('orden', 'Fecha')
                                                    }}>
                                                    Fecha creación
                                                </li>
                                                <li className={`dropdown-item btn-order-opciones cursor-pointer ${orden === 'updated_at' ? 'active' : ''}`}
                                                    onClick={() => {
                                                        nuevosDatos('orden', 'updated_at')
                                                    }}>
                                                    Fecha actualización
                                                </li>
                                                <li className={`dropdown-item btn-order-opciones cursor-pointer ${orden === 'Prioridad' ? 'active' : ''}`}
                                                    onClick={() => {
                                                        nuevosDatos('orden', 'Prioridad')
                                                    }}>
                                                    Prioridad
                                                </li>
                                                <hr/>
                                                <li className={`dropdown-item btn-order-opciones cursor-pointer ${visto === 'leido' ? 'active' : ''}`}
                                                    onClick={() => {
                                                        nuevosDatos('visto', 'leido', true)
                                                    }}>
                                                    Leidos
                                                </li>
                                                <li className={`dropdown-item btn-order-opciones cursor-pointer ${visto === 'sin leer' ? 'active' : ''}`}
                                                    onClick={() => {
                                                        nuevosDatos('visto', 'leido', false)
                                                    }}>
                                                    Sin leer
                                                </li>
                                            </>
                                            <span className='dropdown-item disabled btn-order'>Asignación</span>
                                            <>
                                                <li className={`dropdown-item btn-order-opciones cursor-pointer ${asignacion === 'todos' ? 'active' : ''}`}
                                                    onClick={() => {
                                                        nuevosDatos('asignacion', 'todos')
                                                    }}>
                                                    Todos
                                                </li>
                                                <li className={`dropdown-item btn-order-opciones cursor-pointer ${asignacion === 'asignado' ? 'active' : ''}`}
                                                    onClick={() => {
                                                        nuevosDatos('asignacion', 'asignado')
                                                    }}>
                                                    Mis Asignaciónes
                                                </li>
                                            </>
                                        </div>
                                    </div>
                            </span>
                                </div>
                            </div>
                            <div className='lista'>
                                <div className="input-group input-group-sm mb-3 mt-1">
                                    <span className="input-group-text buscar-tareas rounded-0" id="basic-addon1"><i
                                        className="fas fa-search"></i></span>
                                    <input type="text" className="form-control form-control-sm border border-start-0"
                                           placeholder="Buscar" aria-label="Buscar" aria-describedby="basic-addon1"
                                           onChange={handlechange} value={buscar}/>
                                </div>
                                {/* -------------------- CONTENIDO ---------------- */}
                                {/* ------------------- LISTA DE TAREAS------------- */}
                                {/* <div className=''> */}

                                <InfiniteScroll
                                    height={'113vh'}
                                    dataLength={lTareas && lTareas.length} //This is important field to render the next data
                                    className="box-list-tareas"
                                    next={fetchMoreData}
                                    hasMore={((lTareas && lTareas.length < 10) || (lTareas && lTareas.length === cantidad.total)) ? false : true}
                                    loader={<div className='d-flex justify-content-between mt-2'>
                                        <Skeleton.Button style={{marginTop: '32%'}} active size={'large'}
                                                         shape={'circle'} className="px-2"/>
                                        <Skeleton.Button style={{height: '65px'}} active size={'large'} shape={'buttonShape'} className="pr-2" height="300" rows={3} width={'10px'} block={'block'} />
                      </div>
                      }
                      >
                    {
                      lTareas &&
                      lTareas.length > 0 ?
                      <>
                        {
                          lTareas.map((l, lindex)=>(
                            <motion.div key={lindex} className={`${idSelected === l.id ? 'selected': ''} ${!l.leido ? 'sin-leer':''}`} whileInView={{ opacity: 1 }} transition={{ duration: 0.5 }} initial={{opacity: 0 }}>
                              {
                                <div className={`tarea-tarea`} onClick={()=>{verTarea(l.id, undefined, estadoSelected)}}>
                                <div className='d-flex cursor-pointer' >
                                <div className={`${!l.leido ? 'marca':''}`}></div>
                                  <div className='img'>
                                  <Popover content={()=><ProfileCard props={l.usuario}/>}  trigger="hover">
                                    <img src={`${l.usuario.foto}`} alt="" height="35"  />
                                    </Popover>
                                  </div>
                                  <div className='encabezadoT w-70 '>
                                    #{l.id}
                                    <br />
                                    <span className=''>{l.asunto}</span>
                                    <br />
                                    <span className='text-capitalize'>{bandeja === 'Enviados' ? (l.grupo.length > 0  ? (l.user.length > 0 ? (l.grupo.length>0 && l.grupo[0]?.name.toLowerCase())+', ...': (l.grupo.length>0 && l.grupo[0]?.name.toLowerCase() )) : (l.user.length > 0 ? (l.grupo.length >0 ? l.user[0]?.apellidos_nombres.toLowerCase()+', ...' : (l.grupo.length>0&& l.user[0]?.apellidos_nombres.toLowerCase())): (l.grupo.length>0&& l.grupo[0]?.name.toLowerCase()))) : l.usuario.apellidos_nombres.toLowerCase()}</span>
                                  </div>
                                  <div className='w-30 fecha'>
                                    <div className='text-end'>{l.prioridad === 3 ? <Tooltip color={'#aba7a7c2'} title="Alta"><i className="fas fa-fire-alt prioridad alta"></i></Tooltip> : (l.prioridad === 2 ? <Tooltip color={'#aba7a7c2'} title="Media"><i className="fas fa-exclamation-triangle prioridad pmedia"></i></Tooltip> : <Tooltip color={'#aba7a7c2'} title="Baja"><i className="fas fa-minus"></i></Tooltip> )}</div>
                                    <span>{l.updated_at}</span>
                                    <br />
                                    <span className={`${l.estado === 'pendiente' ? 'pendiente' : (l.estado === 'pausada' ? 'pausada' : (l.estado === 'completada' ? 'completada' : 'rechazada')) } text-capitalize`}>{l.estado}</span>
                                    <div style={{textAlign: 'end',color: '#6f6f6f99'}}>
                                </div>
                                  </div>
                                </div>
                                </div>
                              }
                            </motion.div>
                          ))
                        }
                      </>
                      :
                        <>
                        </>
                    }
                    </InfiniteScroll>
                  {/* </div> */}
                </div>
              </div>
            </div>
            </Pane>
            <div style={style('white')}>
                <SplitPane
                    split='horizontal'
                    sizes={sizes2}
                    onChange={(sizes2) => setSizes2(sizes2)}
                    
                >
                    <Pane maxSize='80%' style={{ ...layoutCSS2, background: 'white' }}>
                        <div className='detalle-detalle'>
                        { (detalle && lTareas ) ?
                            <>
                            <div className='detalle-tarea card fondo-movil'>
                                <div className='encabezadod fw-bold'>
                                    <div>
                                        #{detalle && detalle.id} - {detalle && detalle.asunto} 
                                    </div>
                                <div className='cambiar-estados'>
                                    { 
                                    // (bandeja !== 'Enviados') &&
                                    (detalle && (detalle.estado === 'completada' || detalle.estado === 'rechazada') ?
                                    <Tooltip color={'#aba7a7c2'} title="Volver a pendiente"><span className='p-2 pausar' onClick={()=>cambiarEstado('pendiente', detalle.usuario.id)}><i title="" className="fas fa-history"></i></span></Tooltip>
                                    : (<>
                                        
                                        <Tooltip color={'#aba7a7c2'} title="Rechazar">
                                            <span className='p-2 descartar' onClick={()=>cambiarEstado('rechazada', detalle.usuario.id)}><i title="" className="fas fa-times-circle"></i></span>
                                        </Tooltip>
                                            
                                        {detalle && detalle.estado === 'pausada' ? <Tooltip color={'#aba7a7c2'} title="Volver a pendiente"><span className='p-2 pausar' onClick={()=>cambiarEstado('pendiente', detalle.usuario.id)}><i title="" className="fas fa-history"></i></span></Tooltip> : <Tooltip color={'#aba7a7c2'} title="Pausada"><span className='p-2 pausar' onClick={()=>cambiarEstado('pausada', detalle.usuario.id)}><i title="" className="fas fa-pause-circle"></i></span></Tooltip>}
                                        {(detalle && ((detalle.show_btn_actions=== true) || (detalle && detalle.usuario && (detalle.usuario.id === user.id)))) ?<Tooltip color={'#aba7a7c2'} title="Completada">
                                            <span className='p-2 finalizar' onClick={()=>cambiarEstado('completada', detalle.usuario.id)}><i title="" className="fas fa-check-circle"></i></span>
                                        </Tooltip>:''}
                                        { detalle && detalle.show_btn_actions===false? <Tooltip color={'#aba7a7c2'} title="Esta tarea esta programada para cerrarse automaticamente">
                                            <span className='p-2' ><i title="" className="fas fa-exclamation-circle"></i></span>
                                        </Tooltip>:''}
                                        </>
                                    ))
                                    }
                                </div>
                            </div>
                            <div className='d-flex  mt-1'>
                            {/* <ProfileCard props={detalle.usuario}/> */}
                            <Popover content={()=><ProfileCard props={detalle && detalle.usuario}/>}  trigger="hover">
                            <div className='me-3 img-encabezado-user'><img height="40" src={`${detalle && detalle.usuario.foto}`} alt="" className='' /></div>
                            </Popover>
                                <div className='detalle-datos w-100'>
                                    <Popover content={()=><ProfileCard props={detalle && detalle.usuario}/>}  trigger="hover">
                                        <div className='text-capitalize remitente'>
                                            {detalle && detalle.usuario.apellidos_nombres.toLowerCase()}
                                        </div>
                                    </Popover>
                                <div className='fecha-creacion fs-12'>Fecha de creación: <span>{detalle && detalle.created_at}</span></div>
                                <div className='destinatario fs-12 d-flex flex-wrap'>Para:
                                {
                                    !showDestinatario ?
                                        <> 
                                            { ((detalle&& detalle.grupo && detalle.grupo.length !== 0)  ) &&
                                            detalle.grupo && detalle.grupo.map((dg, indexg)=>(
                                                <div key={indexg} className="my-1">
                                                <span className='mx-1 text-capitalize'>{dg.name.toLowerCase()}</span>
                                                </div> 
                                            ))
                                            }
                                            {((detalle && detalle.user && detalle.user.length !== 0)) &&
                                            detalle && detalle.user && detalle.user.map((du, indexuu)=>(
                                                <div key={indexuu}>
                                                <span className='mx-1 text-capitalize'>{du.apellidos_nombres.toLowerCase()}</span>
                                                </div>
                                            ))
                                            }
                                            <span onClick={()=>editarResponsable('destinatario')}> <i className="fas fa-edit cursor-pointer fs-16"></i></span>
                                            </>
                                        :
                                            <>
                                                <span>
                                                    <Select
                                                        mode="multiple"
                                                        allowClear
                                                        onChange={(value, options)=>handleChangeRes(value, options, 'destinatarios')}
                                                        style={{ width: '100%' }}
                                                        className="select-responsable select-destinatario"
                                                        showSearch
                                                        defaultValue={valueGdestinatarios}
                                                        placeholder="Selecciona un destinatario"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>{
                                                            if(option.children){
                                                            return option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                                            }else if(option.label){
                                                            return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                                            }
                                                            }}
                                                        filterSort={(optionA, optionB) =>
                                                        optionA.children?.toLowerCase().localeCompare(optionB.children?.toLowerCase())
                                                        }
                                                    >
                                                        <OptGroup label="Usuarios">
                                                            {lUsuarios &&  lUsuarios.map((u, indexu)=>(
                                                                <Option key={'u'+indexu} value={'u'+u.id} name="user">{u.apellidos_nombres}</Option>
                                                            )) }
                                                            
                                                        </OptGroup>
                                                        <OptGroup label="Grupos">
                                                            {
                                                                lGupoUsuarios && lGupoUsuarios.map((u, indexgu)=>(
                                                                    <Option key={'g'+indexgu} value={'g'+u.id} name="group">{u.name}</Option>
                                                                ))
                                                            }
                                                        </OptGroup>
                                                </Select>
                                            </span>
                                            <span onClick={()=>actualizarResponsables('destinatarios')} className="m-2"><i className="fas fa-check text-success fs-16 cursor-pointer"></i></span>
                                        <span onClick={()=>{ setShowDestinatario(false) }}><i className="fas fa-times text-danger fs-16 cursor-pointer"></i></span>
                                        </>
                                    }
                                </div>
                                <div  className='destinatario fs-12 d-flex flex-wrap'>
                                    Responsable:
                                    {
                                    ((detalle && detalle.responsable && detalle.responsable.length !== 0) && !showResponsables ) ?
                                        detalle && detalle.responsable.map((r, indexr)=>(
                                        <div key={indexr} className="my-1">
                                            <span className='mx-1 text-capitalize'>{r.apellidos_nombres.toLowerCase()}</span>
                                        </div>
                                        ))
                                        :
                                        
                                            !showResponsables &&
                                            ' -'                                    
                                    }
                                    {
                                        !showResponsables ?
                                        (user.id !== (detalle && detalle.usuario.id) ?
                                            <span className='ms-2 cursor-pointer' onClick={editarResponsable}> <i className="fas fa-edit cursor-pointer fs-16"></i></span>
                                            :'')
                                            :
                                            <>
                                            <span className='w-80'>
                                                <Select
                                                mode="multiple"
                                                allowClear
                                                onChange={(value, options)=>handleChangeRes(value, options)}
                                                style={{ width: '100%' }}
                                                defaultValue={valuesResponsables}
                                                onSelect={onSelect}
                                                className="select-responsable select-destinatario"
                                                showSearch
                                                placeholder="Selecciona un destinatario"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>{
                                                    if(option.children){
                                                    return option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                                    }else if(option.label){
                                                    return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                                    }
                                                    }}
                                                filterSort={(optionA, optionB) =>
                                                optionA.children?.toLowerCase().localeCompare(optionB.children?.toLowerCase())
                                                }
                                                >
                                                    <OptGroup>
                                                        {listResponsables &&  listResponsables.map((u, indexu)=>(
                                                            <Option key={'u'+indexu} id={u.id} value={'u'+u.id} name="user">{u.apellidos_nombres}</Option>
                                                        )) }
                                                        
                                                    </OptGroup>
                                                </Select>
                                        </span>
                                        <span onClick={actualizarResponsables} className="m-2"><i className="fas fa-check text-success fs-16 cursor-pointer"></i></span>
                                        <span onClick={()=>{ setShowResponsables(false) }}><i className="fas fa-times text-danger fs-16 cursor-pointer"></i></span>
                                        </>
                                    }
                                    
                                </div>
                                <div className='fecha-vencimiento fs-12'>
                                    Fecha de vencimiento: <span>{detalle && detalle.vencimiento === null ? '-' : moment(detalle && detalle.vencimiento).format('DD/MM/YYYY')}</span>
                                </div>
                                <div className='estado fs-12 fw-bold'>
                                    Estado: <span className={`text-capitalize ${detalle && detalle.estado} `}>{detalle && detalle.estado}</span>
                                </div>
                                </div>
                            </div>
                            <hr />
                            <div className='file-task'>
                            { 
                            (detalle&& detalle.files)&& detalle.files.length > 0 &&
                            detalle && detalle.files&& detalle.files.map((f, indexlf)=>(
                                <div key={indexlf} className="mx-1 d-flex justify-content-between file">
                                    {(f.filename.toLowerCase().split(".")[1] === "jpeg" || f.filename.toLowerCase().split(".")[1] === "png" || f.filename.toLowerCase().split(".")[1] === "jpg") &&
                                    <img src={f.url} alt="" className='img-fluid p-2'/>
                                    }
                                    {f.type === "application/pdf" && 
                                    <i className="fas fs-22 fa-file-pdf fa-2xl justify-content-center p-2 file-pdf"/>
                                    }
                                    {
                                    f.type === "video/mp4" &&
                                        <i className="fas fs-22 fa-file-video file-pdf p-2 fa-2xl"/>
                                    }
                                    {   f.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
                                        <i className="fas fs-22 fa-file-excel fa-2xl file-pdf p-2 justify-content-center"/>
                                    }
                                    {
                                        (f.type === 'text/plain' || f.type === 'text/csv') &&
                                        <i className="fas fs-22 fa-file-alt fa-2xl file-pdf p-2 justify-content-center"/>
                                    }
                                    {
                                    ( (f.type === 'application/vnd.rar') || (f.type === 'application/zip')) &&
                                        <i className="fas fs-22 fa-file-archive fa-2xl file-pdf p-2 justify-content-center"/>
                                    }
                                    {
                                        ((f.type !== "application/pdf") && (f.type !== "video/mp4") &&
                                        (f.type !== 'text/plain') && (f.type !== 'text/csv') && (f.type !== 'application/vnd.rar') && (f.type !== 'application/zip')) &&
                                        <i className="fas fs-22 fa-file-alt fa-2xl file-pdf p-2 justify-content-center"/>
                                    }
                                    
                                    <div className='my-auto'>
                                    <div className='text-file'><Tooltip color={'#aba7a7c2'} title={f.filename}>--{f.filename}</Tooltip></div>
                                    <div className='d-flex'>
                                        {(f.filename.toLowerCase().split(".")[1] === "jpeg" || f.filename.toLowerCase().split(".")[1] === "png" ||f.filename.toLowerCase().split(".")[1] === "jpg" ) &&
                                        <div className='mx-1 my-auto cursor-pointer' onClick={()=>{ver(f.url)}}><i className="fas fa-eye"></i></div>
                                        }
                                        <div className='mx-1 my-auto icons-file' >
                                            {/* <a href={f.url} target="_blank"> */}
                                            <i className="fas fa-download cursor-pointer" onClick={() => downloadFile(f.url, f.filename)}></i>
                                            {/* </a> */}
                                        
                                            </div>
                                    </div>
                                    </div>
                                </div>
                            ))
                            }
                            </div>
                            {detalle && detalle.files && detalle.files.length > 0 && <hr />}
                            {
                                detalle && detalle.respuestas_formulario && detalle.respuestas_formulario.length> 0 ?
                                    <div className='detalle-mensaje respuesta-formulario' > 
                                        <div className='mt-5'>
                                            <FormViewResponseDetail respuestas_formulario={detalle && detalle.respuestas_formulario[0]} modalDetail={modalDetail} is_tarea_view={true}/>
                                        </div>
                                </div> 
                                :''
                            }
                            <div className='detalle-mensaje' dangerouslySetInnerHTML={{ __html: detalle&& detalle.descripcion }} /> 
                            {/* formulario dinamico */}
                            <div className='detalle-mensaje formulario-dinamico' > 
                            {(detalle && detalle.formulario && detalle.has_formulario)? ( <div className='mt-5'>
                                        <FormDinamic tarea_id={detalle.id} formData={detalle.formulario} showButtonEnviar={true} showButtonLimpiar={true} url={'tareas'} />
                                    </div>):(detalle && detalle.formulario === null &&detalle.respuestas_formulario.length===0 && detalle.has_formulario)?<><div className='mt-5'><div className="col-sm-12 col-md-11 col-lg-11 p-0 m-auto"><div className="card cabecera" >El formulario no se encuentra disponible, todas las respuestas requeridas se han completado</div></div></div></>:""
                            }
                            </div>
                            {/* respuestas formulario */}
                            </div>

                            </>
                            :
                            <div className="ticket-empty">
                              <div><i className="fas fa-box-open fs-20"></i></div>
                              <div className="text-empty">En este momento no hay nada para mostrar</div>
                            </div>
                        }
                        </div>
                    </Pane>
                    <div style={{ ...layoutCSS2, background: 'white' }}>
                    <div>
                        <div className='comentarios card fondo-movil'>
                            <div id="scrollableDiv"  > 
                            <InfiniteScroll
                                height= {sizes2[1]=== '10%' ? (data.fileList.length > 0? '25vh' : '34vh') :(parseInt(sizes2[1]-(data.fileList.length > 0? 130: 78))+'px')}
                                dataLength={comentarios.length} //This is important field to render the next data
                                className="box-list-tareas-coments"
                                next={fetchMoreDataComents}
                                style={{ 
                                    display: "flex",
                                    flexFlow: "wrap-reverse",
                                    overflowX: "hidden",
                                    overflowY: "auto",
                                    // alignContent: "flex-start", 
                                    scrollMarginTop: "0",
                                    paddingBottom: "1rem"
                                }} 
                                inverse={true} 
                                // hasMore={true}
                                hasMore={(comentarios.length < 3 || comentarios.length === cantidadComments.total ) ? false : true}
                                // scrollableTarget="scrollableDiv"
                                loader={<div className='d-flex justify-content-between mt-2'>
                                    <Skeleton.Button active size={'large'} shape={'circle'} className="px-2"  />
                                    <Skeleton.Button active style={{height: '75px', width: '79%'}} size={'large'} shape={'buttonShape'} className="pr-2" height="300" rows={3} width={'10px'} block={'block'} />
                                    </div>
                                    }
                                >
                                
                                {
                                comentarios&& 
                                comentarios.length >0 ?
                                        comentarios.map((d, indexdde)=>(
                                            <div style={{width:'98%', height:'fit-content'}}  key={indexdde}>
                                            <div className={`d-flex my-2 ${((d.usuario.id === user.id) && (d.usuario.apellidos_nombres.toLowerCase() !== 'john') ) ? 'ms-auto mensaje-r' : ((d.usuario.id !== user.id && d.usuario.apellidos_nombres.toLowerCase() !== 'john' ) ? 'mensaje-l' : 'mensaje-bot')}`} >
                                                {/* <div className='img-user'>
                                                <Popover content={()=><ProfileCard props={d.usuario}/>}  trigger="hover">
                                                <img src={`${d.usuario.foto}`} alt="" width="30" />
                                                </Popover>
                                                </div> */}
                                                <div className="contenido w-100 mb-0">
                                                    <div className='destinatario text-capitalize'>{d.usuario.apellidos_nombres.toLowerCase()}</div>
                                                    <div className='fs-9 m-regular'>{d.fecha && d.fecha}</div>
                                                    {/* <div className='contenido fecha'></div> */}
                                                    <div className='contenido' dangerouslySetInnerHTML={{ __html: d.contenido }}/>
                                                    {/* {
                                                        d.files && d.files.length === 0 &&
                                                        <div style={{textAlign: 'end',color: '#6f6f6f99',marginLeft:'auto',float:'right'}}>
                                                            <i className="fas fa-check"></i>
                                                        </div>
                                                    } */}
                                                    { d.files &&  d.files.length >0 &&
                                                        <div className='d-flex'>
                                                            <div className='file-task'>
                                                            { d.files &&
                                                                d.files.length >0 &&
                                                                d.files.map((df, indexdf)=>(
                                                                    <div key={indexdf} className="d-flex file-commnets" >
                                                                        <div >
                                                                        {((df.filename.toLowerCase().split(".")[1] === "jpeg") || (df.filename.toLowerCase().split(".")[1]  === "png" || df.filename.toLowerCase().split(".")[1]=== "jpg")) &&
                                                                            <img src={df.url} alt="" className='img-fluid p-2'/>
                                                                            }
                                                                            {df.type === "application/pdf" && 
                                                                            <i className="fas fs-22 fa-file-pdf fa-2xl justify-content-center p-2 file-pdf"/>
                                                                            }
                                                                            {
                                                                            df.type === "video/mp4" &&
                                                                                <i className="fas fs-22 fa-file-video file-pdf p-2 fa-2xl"/>
                                                                            }
                                                                            {   df.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
                                                                                <i className="fas fs-22 fa-file-excel fa-2xl file-pdf p-2 justify-content-center"/>
                                                                            }
                                                                            {
                                                                                (df.type === 'text/plain' || df.type === 'text/csv') &&
                                                                                <i className="fas fs-22 fa-file-alt fa-2xl file-pdf p-2 justify-content-center"/>
                                                                            }
                                                                            {
                                                                            ( df.type === 'application/vnd.rar' || df.type === 'application/zip' ) &&
                                                                                <i className="fas fs-22 fa-file-archive fa-2xl file-pdf p-2 justify-content-center"/>
                                                                            }
                                                                             {
                                                                                ((df.filename.toLowerCase().split(".")[1] !== "jpeg") && (df.filename.toLowerCase().split(".")[1]  !== "png") && (df.filename.toLowerCase().split(".")[1]!== "jpg") && (df.type !== "application/pdf") && (df.type !== "video/mp4") &&
                                                                                (df.type !== 'text/plain') && (df.type !== 'text/csv') && (df.type !== 'application/vnd.rar') && (df.type !== 'application/zip')) &&
                                                                                <i className="fas fs-22 fa-file-alt fa-2xl file-pdf p-2 justify-content-center"/>
                                                                            }
                                                                        </div>
                                                                        <div className='my-auto'>
                                                                            <div className='text-file'><Tooltip color={'#aba7a7c2'} title={df.filename}>{df.filename}</Tooltip></div>
                                                                            <div className='d-flex'>
                                                                                {(df.filename.toLowerCase().split(".")[1]=== "jpeg" || df.filename.toLowerCase().split(".")[1] === "png" || df.filename.toLowerCase().split(".")[1]=== "jpg"  ) &&
                                                                                <div className='mx-1 my-auto cursor-pointer' onClick={()=>{ver(df.url)}}><i className="fas fa-eye"></i></div>
                                                                                }
                                                                                <div className='mx-1 my-auto icons-file cursor-pointer'>
                                                                                    <i className="fas fa-download cursor-pointer" onClick={() => downloadFile(df.url, df.filename)}></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                ))
                                                            }
                                                            </div>
                                                        
                                                            {/* <div style={{textAlign: 'end',color: '#6f6f6f99',marginLeft:'auto', marginTop:'auto'}}>
                                                                <i className="fas fa-check"></i>
                                                            </div> */}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            </div>
                                        ))
                                    :
                                        <div className='mb-auto fs-15'>
                                        Aún no hay comentarios en esta tarea
                                        </div>
                                }
                                {/* </div> */}
                                </InfiniteScroll>
                            </div>
                        </div>

                        {/* ///////////////////////////// */}
                      <div className='d-flex box-comments'>
                        <div className='upload-img-comments' style={{overflowX:'auto', overflowY:'hidden'}} >
                          <div className='icon-upload cursor-pointer'>
                        { detalle && detalle.estado !== 'completada' ?
                                <Upload
                                fileList={data.fileList}
                                className='content upload-task'
                                listType="picture"
                                onChange={cargarArchivos}
                                iconRender={iconRender}  
                                onPreview={handlePreview}
                                beforeUpload={(file, fileList)=>beforeUpload(file, fileList)}  
                                uploading={true}                                            
                                >
                                {
                                data.fileList.length < 5 &&
                        
                                    <span className='icono1'>
                                        <svg height="24" width="24" viewBox="0 0 24 24"
                                        style={{ display: "block"}}
                                        >
                                        <path d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z"
                                            fill="currentColor"
                                        />
                                        </svg>
                                    </span>
                                    }
                                </Upload>
                                :
                                    <div className='content-disabled'>
                                        <span className='icono1'>
                                            <svg height="24" width="24" viewBox="0 0 24 24"
                                            style={{ display: "block", pointerEvents: "none" }}
                                            >
                                            <path d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z"
                                                fill="currentColor"
                                            />
                                            </svg>
                                        </span>
                                    </div>
                            }
                            </div>
                        </div>
                        <div className='d-flex typing-comments'>
                          <div className='ms-3 box-letter' style={{background: 'aliceblue'}}>
                            { detalle && detalle.estado !== 'completada' ?
                                <div onPaste={handlePaste} 
                                    placeholder="Escribe un comentario"
                                    type="textarea"
                                    id="txtarea"
                                    className="_13NKt copyable-text selectable-text max-h fake-textarea bg-white p-1 cursor-text"
                                    // onChange={handleChangeComments} 
                                    onInput={(e)=>handleChangeComments(e)} 
                                    contentEditable="true"
                                    // cols="30" 
                                    rows="2"
                                    title="Escribe un mensaje aquí"
                                ></div>
                                :
                                <textarea
                                    placeholder="Escribe un comentario"
                                    type="text"
                                    style={{resize: 'none'}}
                                    className="_13NKt copyable-text selectable-text cursor-nallowed"
                                    // contentEditable="true"
                                    dir="ltr"
                                    disabled
                                    // cols="30" rows="1"
                                    id="txtarea"
                                    spellCheck
                                    title="Escribe un mensaje aquí"
                                ></textarea>
                          }
                          </div>
                          {  detalle && detalle.estado !== 'completada' ?
                                <div className="icon-send" >
                                  <button
                                    className="_30ggS"
                                    aria-label="Mensaje de voz">
                                    <span onClick={()=>addComments (detalle.usuario&& detalle.usuario.id)} className="_30ggsss">
                                      <i className="fas fa-paper-plane fs-18"></i>
                                    </span>
                                  </button>
                                </div>
                            :
                              <div className="icon-send">
                                <button
                                className="_30ggS"
                                aria-label="Mensaje de voz">
                                <span className="_30ggsss cursor-nallowed disabled">
                                    <i className="fas fa-paper-plane fs-18"></i>
                                </span>
                                </button>
                            </div>

                          }
                          
                          </div>
                      </div>
                      </div>
                    </div>
                </SplitPane>
            </div>
        </SplitPane>
        </div>
        {/* MOVIL */}
        <div className='tareas-movil'>
            <div className='p-2 card' style={{boxShadow: '0 1px 5px 0 rgba(60, 64, 67, 0.3)'}}>
                <div id="lista" className='d-flex justify-content-between'> 
                  <div>
                    <span className={`encabezado-tareas text-dark ${bandeja === 'Recibidos' ? 'active': ''}`} onClick={()=>{nuevosDatos('bandeja', 'Recibidos')}} >Recibidos</span>
                    <span className={`encabezado-tareas text-dark ${bandeja === 'Enviados' ? 'active': ''}`} onClick={()=>{nuevosDatos('bandeja', 'Enviados')}}>Enviados</span>
                  </div>
                  <div>
                    <span className="mainmenu">
                        <span >
                            <div className="dropdown">
                            <span
                                  className="fs-15 dropdown-toggle text-info cursor-pointer fw-bold" data-bs-toggle="dropdown">Filtro</span>
                            <div className="dropdown-menu dropdown-menu-end">
                                {
                                estadosTareas && estadosTareas.map((e, indexxe)=>(
                                    <li key={indexxe} className={`dropdown-item estadosTareas ${estadoSelected === (e) ? 'active' : ''}`}  onClick={()=>{nuevosDatos('estado', e)}}>{e}</li>
                                ))
                                }
                                <li className={`dropdown-item estadosTareas ${estadoSelected === 'Todos' ? 'active' : ''}`} onClick={()=>{nuevosDatos('estado', 'Todos')}}>Todos</li>
                                <span className='dropdown-item disabled btn-order'>Orden</span>
                                    <li className={`dropdown-item btn-order-opciones cursor-pointer ${orden === 'Fecha' ? 'active' : ''}`} onClick={()=>{nuevosDatos('orden', 'Fecha')}}>
                                        Fecha creación
                                    </li>
                                    <li className={`dropdown-item btn-order-opciones cursor-pointer ${orden === 'updated_at' ? 'active' : ''}`} onClick={()=>{nuevosDatos('orden', 'updated_at')}}>
                                        Fecha actualización
                                    </li>
                                    <li className={`dropdown-item btn-order-opciones cursor-pointer ${orden === 'Prioridad' ? 'active' : ''}`} onClick={()=>{nuevosDatos('orden', 'Prioridad')}}>
                                        Prioridad
                                    </li>
                                    <hr/>
                                    <li className={`dropdown-item btn-order-opciones cursor-pointer ${visto === 'leido' ? 'active' : ''}`} onClick={()=>{nuevosDatos('visto', 'leido', true)}}>
                                        Leidos
                                    </li>
                                    <li className={`dropdown-item btn-order-opciones cursor-pointer ${visto === 'sin leer' ? 'active' : ''}`} onClick={()=>{nuevosDatos('visto', 'leido', false)}}>
                                        Sin leer
                                    </li>
                                    <span className='dropdown-item disabled btn-order'>Asignación</span>
                                    <>
                                    <li className={`dropdown-item btn-order-opciones cursor-pointer ${asignacion === 'todos' ? 'active' : ''}`} onClick={()=>{nuevosDatos('asignacion', 'todos')}}>
                                        Todos
                                    </li>
                                    <li className={`dropdown-item btn-order-opciones cursor-pointer ${asignacion === 'asignado' ? 'active' : ''}`} onClick={()=>{nuevosDatos('asignacion', 'asignado')}}>
                                        Mis Asignaciónes
                                    </li>
                                    </>
                                </div>
                            </div>
                        </span>
                    </span>
                  </div>
                </div>
                <div className='lista'>
                    <div className="input-group input-group-sm mb-3 p-1 mt-1">
                        <span className="input-group-text buscar-tareas rounded-0" id="basic-addon1"><i className="fas fa-search"></i></span>
                        <input type="text" id="buscar" className="form-control form-control-sm border border-start-0" placeholder="Buscar" aria-label="Buscar" aria-describedby="basic-addon1" onChange={handlechange} value={buscar} />
                    </div>
                    {/* -------------------- CONTENIDO ---------------- */}
                    <InfiniteScroll
                        height={'75vh'}
                        dataLength={lTareas && lTareas.length} //This is important field to render the next data
                        className="box-list-tareas"
                        next={fetchMoreData}
                        hasMore={((lTareas && lTareas.length < 10) || (lTareas && lTareas.length === cantidad.total) ) ? false : true}
                        loader={<div className='d-flex justify-content-between mt-2'>
                            <Skeleton.Button active size={'large'} shape={'circle'} className="px-2"  />
                            <Skeleton.Button active size={'large'} shape={'buttonShape'} className="pr-2" height="300" rows={3} width={'10px'} block={'block'} />
                        </div>
                        }
                    >
                        {/* <div className='box-list-tareas'> */}
                        {
                        lTareas &&
                        lTareas.length > 0 ?
                        <>
                            {
                            lTareas.map((l, lindex)=>(
                                <motion.div key={lindex} className={`${idSelected === l.id ? 'selected': ''} ${!l.leido ? 'sin-leer':''}`} whileInView={{ opacity: 1 }} transition={{ duration: 0.5 }} initial={{opacity: 0 }}>
                                {
                                    <div className='d-flex tarea-tarea cursor-pointer'  data-bs-toggle="modalDetalle" data-bs-target="#modalDetalle" onClick={()=>{verTarea(l.id,undefined, estadoSelected); setModal(true); showmodalanswer(true)}} >
                                    <div className='img'>
                                        <img src={`${l.usuario.foto}`} alt="" height="35"  />
                                    </div>
                                    <div className='encabezadoT w-70'>
                                        #{l.id}
                                        <br />
                                        <span className=''>{l.asunto}</span>
                                        <br />
                                        <span className='text-capitalize'>{bandeja === 'Enviados' ? (l.grupo.length > 0  ? (l.user.length > 0 ?  (l.grupo.length>0&& l.grupo[0].name.toLowerCase())+', ...': (l.grupo.length>0&& l.grupo[0].name.toLowerCase()) ) : (l.user.length > 0 ? (l.grupo.length >0 ? l.user[0].apellidos_nombres.toLowerCase()+', ...' : l.user[0].apellidos_nombres.toLowerCase()): (l.grupo.length>0&& l.grupo[0].name.toLowerCase()))) : l.usuario.apellidos_nombres.toLowerCase()}</span>
                                    </div>
                                    <div className='w-30 fecha'>
                                        <div className='text-end'>{l.prioridad === 3 ? <Tooltip color={'#aba7a7c2'} title="Alta"><i className="fas fa-fire-alt prioridad alta"></i></Tooltip> : (l.prioridad === 2 ? <Tooltip color={'#aba7a7c2'} title="Media"><i className="fas fa-exclamation-triangle prioridad pmedia"></i></Tooltip> : <Tooltip color={'#aba7a7c2'} title="Baja"><i className="fas fa-minus"></i></Tooltip> )}</div>
                                        <span>{l.updated_at}</span>
                                        <br />
                                        <span className={`${l.estado === 'pendiente' ? 'pendiente' : (l.estado === 'pausada' ? 'pausada' : (l.estado === 'completada' ? 'completada' : 'rechazada')) } text-capitalize`}>{l.estado}</span>
                                    </div>
                                    
                                    </div>
                                }
                                </motion.div>
                            ))
                            }
                        </>
                        :
                            <>
                            </> 
                        }   
                        {/* </div> */}
                    </InfiniteScroll>
                </div>
              </div>
          </div>
    </>
  );
}
export default Tareas2;