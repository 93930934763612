import React, {useState,useEffect, useContext} from 'react';
import { Link } from "react-router-dom";
import {TitleLayout} from '../base/TitleLayout'
import Spinner from '../base/spiner';
import { Select, Progress, Spin  } from 'antd';
import Swal from 'sweetalert2'
import Call from '../../config/Call'
import AuthContext from '../../contexts/auth/AuthContext';
import { Line  } from '@ant-design/charts';
import { Gauge } from '@ant-design/plots';
import moment from 'moment';
import 'moment/locale/es'
import DataTableComp from '../produccion/autoriaLotes/dataTableComp';
import CardIndicadores from './components/indicadoresCarrusel';
import TiendasSelect from './components/indicadoresGerentes';
import { saveAs } from 'file-saver';
moment.locale("es");

const { Option } = Select;

const Dashboard =()=>{
    const { user } = useContext(AuthContext) // USER INFORMATION
    const [sp, setSp] = useState(false) //SPINER
    const [listNews, setListNews] = useState([]) // LIST NEWS
    const [listAccess, setlistAccess] = useState([])
    const [userType, setUserType] = useState(null)
    const [viewReady, setViewReady ] = useState(false)
    let [data, setData] = useState([]); // GRAPH DATA
    const [ dataDashboard, setDataDashboard ] = useState(null) // DASHBOARD OVERVIEW
    let [filtro, setFiltro]= useState('mes') // GRAPH FILTER
    const [showErrorEmpty, setShowErrorEmpty] = useState(false)
    const [ typeCompliance, setTypeCompliance ] = useState('day')
    const [ typeComplianceStore, setTypeComplianceStore ] = useState('day')
    const [spinnerIndicadores, setSpinner] = useState(true); // Estado para el spinner
    const [listUnidadPromedioTrasaccion, setUnidaPromedioTrasaccion] = useState([]); // Estado para los datos de los indicadores
    const [selectedAccess] = useState('');
    const [tiendas, setTiendas] = useState([]);
    
    let datat = {
        titulo: `¡Hola ${user&& user?.apellidos_nombres?.toLowerCase()}!` // USER NAME IN MESSAGE WELCOME
    }
    const tiendas_user = user.tiendas
    const COD_CENTRO_COSTOS_SUB_GERENCIA = '13';
    const COD_CENTRO_COSTOS_GERENTE = '34';
    const COD_CENTRO_COSTOS_MERCADEO = '23';
    const COD_CENTRO_COSTOS_PRODUCTO = '56';
    const PERMISOS_TODAS_TIENDAS = [COD_CENTRO_COSTOS_SUB_GERENCIA, COD_CENTRO_COSTOS_GERENTE, COD_CENTRO_COSTOS_MERCADEO, COD_CENTRO_COSTOS_PRODUCTO];

    useEffect(() => {
        //INIT DATA
      initialData();
      fetchTiendas()
    }, []);

    const handleDownload = async () => {
        try {
            const response = await Call('GET', '/api/promedios_comerssia/all_tiendas/tiendas/', true, null, false, null, true);

            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            saveAs(blob, 'promedios_tiendas.xlsx');
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        }
    };

const fetchTiendas = async () => {
    if (PERMISOS_TODAS_TIENDAS.includes(user.cod_centro_costos)) {
        try {
            const result = await Call('GET', 'api/tiendas/', true, null, false);
            setTiendas(result.data.results);
        } catch (error) {
        }
    }
};

const IndicadoresComerciales = async() => {
    setSpinner(true);  // Muestra el spinner mientras cargan los datos
    try {
        const result = await Call('GET', '/api/promedios_comerssia/', true, null, false);


        if (result.data) {
            setUnidaPromedioTrasaccion(result.data);
        }
    } catch (error) {
        console.error("Error fetching data", error);
    } finally {
        setSpinner(false);
    }
}

    const handleChangeAccesoRapido = (value) => {
        if (value) {
            window.open(value, '_blank');
        }
    };

const handleChange=(e)=>{
    // CHANGE GRAPH DATA
    if(e === 'anio'){
        setFiltro(e)
        let actual={}
        let last={}
        let dataT=[]
        //REBUILDING GRAPH DATA

        for(let i=0; i<dataDashboard?.growth.length; i++){
            
            if((dataDashboard?.growth[i].current_year) &&  (i <= moment().month())){
                actual={
                    type: dataDashboard?.growth[i].current_year.toString(),
                    month: dataDashboard?.growth[i].month,
                    value: dataDashboard?.growth[i].growth_current_year
                }
            }
            if((dataDashboard?.growth[i].last_year) &&  (i <= moment().month())){
                last={
                    type: dataDashboard?.growth[i].last_year.toString(),
                    month: dataDashboard?.growth[i].month,
                    value: dataDashboard?.growth[i].growth_last_year
                }
            }
            dataT.push(actual, last)
        }
        setData(dataT)
    }
    if(e==='mes'){
        setFiltro(e)
        //REBUILDING GRAPH DATA
        let dataN=[]
            
            let a={}
            let b={}
            for(let i=0; i< dataDashboard.month_sales.length; i++){
                if(dataDashboard?.month_sales[i].sales_current_year){
                     a ={
                        day:dataDashboard?.month_sales[i].day,
                        value:dataDashboard?.month_sales[i].sales_current_year,
                        type:dataDashboard?.month_sales[i].current_year.toString()
                    }
                }
                if(dataDashboard?.month_sales[i].sales_last_year){
                     b ={
                        day:dataDashboard?.month_sales[i].day,
                        value:dataDashboard?.month_sales[i].sales_last_year,
                        type: dataDashboard?.month_sales[i].last_year.toString()
                    }
                }
                dataN.push(a,b)
            }
            
            setData(dataN)
    }
    if(e=== 'trimester'){
        setFiltro('trimester')
        const fechaActual = moment().month() // ACTUAL DATE
        const mesInicial=moment().month()-2 // LAST THREE MONTHS
        
        //REBUILDING GRAPH DATA
        let a={}
        let b={}
        let monthTrimester=[]
        for (let i = mesInicial; i <= fechaActual; i++) {
            if (dataDashboard.growth[i] && dataDashboard.growth[i].current_year) {
                a = {
                    type: dataDashboard.growth[i].current_year.toString(),
                    month: dataDashboard.growth[i].month,
                    value: dataDashboard.growth[i].growth_current_year
                };
            }
            if (dataDashboard.growth[i] && dataDashboard.growth[i].last_year) {
                b = {
                    type: dataDashboard.growth[i].last_year.toString(),
                    month: dataDashboard.growth[i].month,
                    value: dataDashboard.growth[i].growth_last_year
                };
            }
            if (a && b) {
                monthTrimester.push(a, b);
            }
        }
        
        setData(monthTrimester)
    }
}

    const initialData = async()=> {
        //CHARGING INITIAL DATA
        try {
            setSp(true)
            const res2 = await Call('GET', '/api/homedashboard', true, null, false)
            
            if(res2.data.code === 1){
                setShowErrorEmpty(false)
                setDataDashboard(res2.data && res2.data.data)
                if(res2.data.data.type_user === "administrativo"){
                    setListNews(res2.data.data.news.splice(0,3))
                }else{
                    if(typeof(res2.data.data.month_sales) !== 'string'){

                    
                    let dataN=[]
                    let a={}
                    let b={}
                    for(let i=0; i<res2.data.data.month_sales.length; i++){
                        if(res2.data.data.month_sales[i].sales_current_year){
                            a ={
                                day:res2.data.data.month_sales[i].day,
                                value:res2.data.data.month_sales[i].sales_current_year,
                                type:res2.data.data.month_sales[i].current_year.toString()
                            }
                        }
                        if(res2.data.data.month_sales[i].sales_last_year){
                            b ={
                                day:res2.data.data.month_sales[i].day,
                                value:res2.data.data.month_sales[i].sales_last_year,
                                type: res2.data.data.month_sales[i].last_year.toString()
                            }
                        }
                    dataN.push(a,b)
                    }
                    setData(dataN)
                }
                setUserType(res2.data.data.type_user)
                setUserType('tienda')
                }
                if (res2.data.data.type_user === 'tienda') {
                    const accesosDirectoData = await Call('GET', '/api/acceso_directo', true, null, false)
                    setlistAccess(accesosDirectoData?.data?.results)
                    IndicadoresComerciales()
                }
                setViewReady(true)
                setSp(false)
            }else{
                setSp(false)
                setShowErrorEmpty(true)
                Swal.fire({
                    title: '',
                    icon: 'error',
                    html: res2.data.message,
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                })
            }
            
        } catch (error) {
            setSp(false)
            Swal.fire({
                title: '',
                icon: 'error',
                html: error,
                focusConfirm: false,
                confirmButtonText:'Aceptar',
            })
        }
    }
    //DRAWING GRAPHS MULTILINE
    const config = {
        data,
        xField: (filtro !== 'mes' ? 'month': 'day'),
        yField: 'value',
        yAxis: {
          label: {
            formatter: (v) =>`${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
          },
        },
        tooltip: {
        formatter: (datum) => {
            return { name: datum.type, value: datum.value + '%' };
        },
        showTitle: false,
        },
        seriesField: 'type',
        color: ['#1979C9', '#D62A0D', '#FAA219'],
    };   

    // Graph gauge DRAWING GRAPHS MULTILINE STORE COMPLIANCE
    
    const config2 = {
        percent: (dataDashboard && (dataDashboard.compliance/100)),
        height:150,
        width:150,
        range: {
          color: 'l(0) 0:#23b99170 1:#23b991',
        },
        
        startAngle: Math.PI,
        endAngle: 2 * Math.PI,
        indicator: null,
        axis: {
            label: {
              formatter(v) {
                return (Number(v) * 100);
              },
            },
            subTickLine: {
              count: 3,
            },
          },
          
        statistic: {
          title: {
            offsetY: -36,
            style: {
              fontSize: '25px',
              color: '#4B535E',
            },
            formatter: () => (dataDashboard&& ((dataDashboard.compliance.substring(0,5)))+'%'),
          },
          content: {
            style: {
              fontSize: '24px',
              lineHeight: '44px',
              color: '#4B535E',
              maxWidth:'100%!important'
            },
            formatter: () => '',
          },
        },
      };
      const config3 = {
        percent: (dataDashboard && (dataDashboard.month_compliance/100)),
        height:150,
        width:150,
        range: {
          color: 'l(0) 0:#23b99170 1:#23b991',
        },
        
        startAngle: Math.PI,
        endAngle: 2 * Math.PI,
        indicator: null,
        axis: {
            label: {
              formatter(v) {
                return (Number(v) * 100);
              },
            },
            subTickLine: {
              count: 3,
            },
          },
          
        statistic: {
          title: {
            offsetY: -36,
            style: {
              fontSize: '25px',
              color: '#4B535E',
            },
            formatter: () => (dataDashboard&& ((dataDashboard.month_compliance.substring(0,5)))+'%'),
          },
          content: {
            style: {
              fontSize: '24px',
              lineHeight: '44px',
              color: '#4B535E',
              maxWidth:'100%!important'
            },
            formatter: () => '',
          },
        },
      };
      // DATA DATATABLE
      const columns =[
        {
            name: 'Nombre',
            cell: row => (
                <div className='text-capitalize text-start'>
                    {row.seller_name.toLowerCase()}
                </div>
            ),
            // width: '170px',
            filterable: true,
            left:true,
        },
        {
            name: '% cumplimiento',
            cell: row => (
                <div style={{width:80}}>
                    <Progress className="dash-saler" percent={parseFloat((typeCompliance ==='day' ?row.seller_compliance : row.month_seller_compliance)).toFixed(2)} status="active" style={{width:'180px'}} />
                </div>
            ),
            // width: '140px',
        }
      ]
      
      const columnMovil=[
        {
            name: 'Nombre',
            cell: row => (
                <div className='text-capitalize text-start'>
                    {row.seller_name.toLowerCase()}
                </div>
            ),
            filterable: true,
            width: '100px'
        },
        {
            name: '% cumplimiento',
            cell: row => (
                <div style={{width:'80%'}}>
                    <Progress className="dash-saler" percent={typeCompliance === 'day'? row.seller_compliance.substring(0,4) : row.month_seller_compliance.substring(0,4)} status="active" style={{width:'180px'}} />
                </div>
            ),
            width: '80px',
        }
      ]
      const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };   
    return(
        <div className='pb-4'>
            { sp ? <Spinner /> : ''}  {/*LOADER*/}
             <TitleLayout data={datat} clase={"text-start text-capitalize"} icon={false} seccion={'dashboard'} />
            <div className='bg-white p-4 col-lg-12 col-md-12 col-sm-12 mx-auto' style={{height:'auto'}}>
                { showErrorEmpty ? 
                <>
                    <div className='my-5 text-center'>
                        <i className="fas fa-box-open fs-40"></i>
                        <div>En estos momentos no hay datos disponibles</div>
                    </div>
                </>
                
                :
                    <>
                        {
                            viewReady && userType === 'tienda' ?
                            <>
                            {/* //////////////////////////// desktop /////////////// */}
                            
                            {/* init RANKING how are we doing */}
                            <div className='card-general dash1 mb-4'>
                                <h5>Accesos rápidos</h5>
                                <div className='text-start quick-access'>
                                    <Link className="link-formater fs-18 my-3 m-regular" to='/tareas'><i className="fas fa-tasks fs-18"></i> Tareas</Link>
                                    <Link className="link-formater fs-18 my-3 m-regular" to='/formularios'><i className="fas fa-check-square fs-18"></i> Formularios</Link>
                                    <a className="link-formater fs-18 my-3 m-regular" href={`${process.env.REACT_APP_BACKEND_URL}/tiendas/comovamos`}><i className="fas fa-chart-bar fs-18"></i> Cómo vamos</a>
                                    <a href="/consultar-referencias" target="_blank" className="link-formater fs-18 my-3 m-regular">
                                        <i className="fas fa-search fs-18"></i> Consultar referencias
                                    </a>
                                </div>
                                <div className='flex-wrap' style={{ paddingTop: '20px' }}>
                                    <Select 
                                        onChange={handleChangeAccesoRapido}
                                        placeholder="Acceso directos"
                                        optionFilterProp="children"
                                        showSearch
                                        style={{ width: 210 }}
                                        value={selectedAccess === "" ? undefined : selectedAccess}
                                    >
                                        {listAccess.map((access, index) => (
                                            <Option key={index} value={access.enlace}>
                                                <i className={`fas ${access.icono}`} style={{ marginRight: '8px' }}></i>
                                                {access.nombre}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>

                                
                                {/* init RANKING how are we doing */}
                                {/*  //////////////// statistics how are we doing ///////////////////////*/}
                                <div className='d-flex flex-wrap dash1 justify-content-between wrapper'>
                                    <div className='card-general'>
                                        {
                                            dataDashboard.compliance !== 'No hay cumplimiento de presupuesto del dia disponible' &&
                                            <>
                                             { typeComplianceStore === 'day'  ? 
                                                    <div
                                                        className='btn-info2 fit-content ms-auto px-2 mb-2'
                                                        onClick={()=>{setTypeComplianceStore('month')}}
                                                    >
                                                        Ver por mes
                                                    </div> 
                                                    :
                                                    <div
                                                        className='btn-info2 fit-content ms-auto px-2 mb-2'
                                                        onClick={()=>{setTypeComplianceStore('day')}}
                                                    >
                                                        Ver por día
                                                    </div> 
                                                }
                                            </>
                                        }
                                        <div>
                                            <h5>Presupuesto <span className='text-capitalize'>{dataDashboard && dataDashboard.store.toLowerCase()}</span></h5>
                                            {
                                                dataDashboard.compliance !== 'No hay cumplimiento de presupuesto del dia disponible' ?
                                                <>
                                                    <span className='text-capitalize'>{ typeComplianceStore === 'day' ? moment().format("DD/MM/YYYY") : moment().format("MMMM") }</span>
                                                    {
                                                        typeComplianceStore === 'day' ?
                                                        <Gauge
                                                            {...config2}
                                                        />
                                                        :
                                                        <Gauge
                                                            {...config3}
                                                        />
                                                    }

                                                </>
                                                :
                                                <div className='mx-auto d-block mt-5'>
                                                    <div><i className="fas fa-box-open fs-40 c-sec"></i></div>
                                                    <div className='c-sec mt-2'>En estos momentos no hay datos disponibles</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="card-general">
                                        {spinnerIndicadores || !listUnidadPromedioTrasaccion ? (
                                            <div className='d-flex justify-content-center align-items-center' style={{ minHeight: '200px', width: '100%' }}>
                                                <Spin tip="Cargando indicadores comerciales..." size="large" >
                                                    Cargando indicadores comerciales...
                                                </Spin>
                                            </div>
                                        ) : (
                                            <CardIndicadores
                                                indicadores={listUnidadPromedioTrasaccion}
                                            />
                                        )}
                                    </div>
                                    <div className="card-general">
                                        {/* <h5 className="text-center">
                                            <i className="fas fa-chart-line fs-19"></i> Ranking indicadores comerciales
                                        </h5>
                                        <p className="text-center">
                                            <i className="fas fa-info-circle fs-18 "></i> Los indicadores se calculan a partir de el inicio del mes hasta el dia de hoy.
                                        </p>
                                        <button 
                                            className="btn btn-custom w-100"
                                            onClick={handleDownload}
                                            style={{ 
                                                backgroundColor: '#3abaf4', 
                                                color: '#fff', 
                                                padding: '15px', 
                                                border: 'none', 
                                                borderRadius: '5px', 
                                                fontSize: '1rem',
                                                marginTop: '10px' 
                                            }}
                                        >
                                            Descarga de información
                                        </button> */}

                                        <h5 className='text-center'><i className="fas fa-chart-line fs-19"></i>  Ranking Cómo Vamos </h5>
                                        {
                                            typeof(dataDashboard['como_vamos']) !== 'string' ?
                                            <>
                                                <div className='mb-3'>
                                                    <h6 className='text-danger fw-700 fs-18'>Categoria {dataDashboard && dataDashboard['como_vamos'].store_category}</h6>
                                                </div>
                                                <div >
                                                    <h6 className='mb-0'>Tu posición en el ranking es</h6>
                                                    <div className="ranking-pos mt-0">
                                                        <div>
                                                            <img src="/assets/images/iwinner.png" alt="" height={20}/>
                                                        </div>
                                                        <div>
                                                            <h6 className='mb-0'>{dataDashboard&& dataDashboard['como_vamos'].store_position}</h6>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className='text-center m-auto'>
                                                <div className='text-capitalize flex-wrap ranking-general steps'>
                                                    <div className='text-capitalize'>
                                                        <div className='row' style={{maxWidth:'95%'}}>
                                                            <div className='col-lg-8 col-md-8 col-sm-12'>
                                                                <div><span className='fw-600 '>1. </span>{dataDashboard && dataDashboard.como_vamos && dataDashboard.como_vamos.ranking&& dataDashboard.como_vamos.ranking[0].rank_1.toLowerCase() }</div><span className='fs-13'></span>
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 col-sm-12'>
                                                                <Progress className="dash-saler store" percent={dataDashboard && dataDashboard.como_vamos && dataDashboard.como_vamos.ranking&& dataDashboard.como_vamos.ranking[0].percentage } status="active" style={{width:'95px'}} /> 
                                                            </div>
                                                        </div>
                                                        <div className='row my-3'  style={{maxWidth:'95%'}}>
                                                            <div className='col-lg-8 col-md-8 col-sm-12'>
                                                                <div>
                                                                    <span className='fw-600'>2. </span>
                                                                    {dataDashboard && dataDashboard.como_vamos && dataDashboard.como_vamos.ranking&& dataDashboard.como_vamos.ranking[1].rank_2.toLowerCase() }
                                                                </div><span className='fs-13'></span>
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 col-sm-12'>
                                                                <Progress className="dash-saler store" percent={dataDashboard && dataDashboard.como_vamos && dataDashboard.como_vamos.ranking&& dataDashboard.como_vamos.ranking[1].percentage } status="active" style={{width:'100px'}} />
                                                            </div>
                                                        </div>
                                                        <div className='row my-3'  style={{maxWidth:'95%'}}>
                                                            <div className='col-lg-8 col-md-8 col-sm-12'>
                                                                <div>
                                                                    <span className='fw-600'>3. </span>
                                                                    {dataDashboard && dataDashboard.como_vamos && dataDashboard.como_vamos.ranking&& dataDashboard.como_vamos.ranking[2].rank_3.toLowerCase() }
                                                                </div><span className='fs-13'></span>
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 col-sm-12'>
                                                                <Progress className="dash-saler store" percent={dataDashboard && dataDashboard.como_vamos && dataDashboard.como_vamos.ranking&& dataDashboard.como_vamos.ranking[2].percentage } status="active" style={{width:'100px'}} />
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <div className='mx-auto d-block mt-5'>
                                                <div><i className="fas fa-box-open fs-40 c-sec"></i></div>
                                                <div className='c-sec mt-2'>En estos momentos el presupuesto no está disponible</div>
                                            </div>
                                        }
                                        
                                        
                                    </div>
                                </div>
                                {/*  //////////////// end statistics how are we doing ///////////////////////*/}
                                
                                {/* ///////////// init compliance statistics */}
                                <div className='d-flex flex-wrap dash1 justify-content-between wrapper mt-4'>
                                    <div className='dash1 card-general'>
                                        <div className='my-3'>
                                            <h5>Cumplimiento <span className='text-capitalize'>{dataDashboard && dataDashboard.store.toLowerCase()}</span></h5>
                                        </div>
                                        {
                                           data.length !== 0 ?
                                            <>
                                                <div className='my-4'>
                                                    <Select
                                                        defaultValue="mes"
                                                        className='align'
                                                        style={{ width: 200 }}
                                                        onChange={handleChange}
                                                        options={[
                                                            { value: 'mes', label: 'Mes actual' },
                                                            { value: 'trimester', label: 'Trimestre' },
                                                            { value: 'anio', label: 'Año' },
                                                        ]}
                                                    />
                                                </div>
                                                { dataDashboard &&
                                                    <Line {...config} />
                                                }
                                            </>
                                            : 
                                            <div className='mx-auto d-block mt-5'>
                                                <div><i className="fas fa-box-open fs-40 c-sec mt-4 pt-3"></i></div>
                                                <div className='c-sec mt-2'>En estos momentos el presupuesto no está disponible</div>
                                            </div>
                                        }
                                                
                                        
                                    </div>
                                    <div className='dash1 card-general'>
                                        { typeof(dataDashboard.sellers_compliance) !== 'string' &&
                                            <>
                                            {
                                                typeCompliance === 'day' ? 
                                                    <div
                                                        className='btn-info2 fit-content ms-auto px-2'
                                                        onClick={()=>{setTypeCompliance('month')}}
                                                    >
                                                        Ver por mes
                                                    </div>
                                                :
                                                    <div
                                                        className='btn-info2 fit-content ms-auto px-2'
                                                        onClick={()=>{setTypeCompliance('day')}}
                                                    >
                                                        Ver por día
                                                    </div>
                                            }
                                            </>

                                        }
                                                
                                                    
                                        <div className='mt-2'>
                                            <h5>{ typeCompliance === 'day' ? 'Cumplimiento diario' : 'Cumplimiento mensual' } </h5>
                                            <span className='text-capitalize'>{ typeCompliance === 'day' ? moment().format("DD/MM/YYYY") : moment().format("MMMM") } </span>
                                            <div className='ocultar-movil'>
                                                {   (dataDashboard && dataDashboard.sellers_compliance && typeof(dataDashboard.sellers_compliance) !== 'string')
                                                    ?
                                                        <DataTableComp
                                                        columns={columns} 
                                                        data={dataDashboard && dataDashboard.sellers_compliance}
                                                        paginationComponentOptions={paginationComponentOptions}
                                                        paginationPerPage={5}
                                                        paginationRowsPerPageOptions={[5,10, 15]}
                                                        noDataComponent={<span className="fs-16 text-center">En este momento no hay nada para mostrar</span>}

                                                    />
                                                    :
                                                        <div className='mx-auto d-block mt-5'>
                                                                <div><i className="fas fa-coins fs-50 c-sec pt-2"></i></div>
                                                                <div className='c-sec mt-2'>En estos momentos el presupuesto no está disponible</div>
                                                        </div>
                                                }
                                            </div>
                                            <div className='mostrar-movil' style={{maxWidth:'200px'}}>
                                                {
                                                    (dataDashboard && dataDashboard.sellers_compliance&& typeof(dataDashboard.sellers_compliance) !== 'string')
                                                    ?
                                                        <DataTableComp
                                                            columns={columnMovil}
                                                            data={dataDashboard && dataDashboard.sellers_compliance}
                                                            paginationComponentOptions={paginationComponentOptions}
                                                            paginationPerPage={5}
                                                            paginationRowsPerPageOptions={[5,10, 15]}
                                                            noDataComponent={<span className="fs-16 text-center">En este momento no hay nada para mostrar</span>}
                                                        />
                                                    :
                                                        <div className='mx-auto d-block mt-5'>
                                                            <div><i className="fas fa-coins fs-50 c-sec pt-3"></i></div>
                                                            <div className='c-sec mt-2 mb-4'>En estos momentos el presupuesto no está disponible</div>
                                                        </div>
                                                }
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                                
                                {/* ///////////// end compliance statistics */}
                        
                            {/*  //////////ENF DESKTOP ////////*/}

                            {/* //////////// END MOVIL ////// */}
                            </>
                            : 
                            ( viewReady && userType !== 'tienda' &&
                                <>
                                    <div className='ocultar-movil'>
                                        <div className='card-general'>
                                            <h5>Accesos rápidos</h5>
                                            <hr/>
                                            <div className='text-start quick-access m-regular'>
                                                <Link className="link-formater text-decoration-n fs-18 my-3 text-danger m-regular" to='/tareas'><i className="fas fa-tasks fs-18"></i> Tareas</Link>
                                                <Link className="link-formater text-decoration-n fs-18 my-3 text-danger m-regular" to='/formularios'><i className="fas fa-check-square fs-18"></i> Formularios</Link>
                                                <a className="link-formater text-decoration-n fs-18 my-3 text-danger m-regular" href={`${process.env.REACT_APP_BACKEND_URL}/tiendas/comovamos`}><i className="fas fa-chart-bar fs-18"></i> Cómo vamos</a>
                                            </div>
                                        </div>
                                        <br></br><br></br>
                                        <TiendasSelect 
                                            user_cargo={user?.cod_cargo}
                                            all_tiendas={tiendas}
                                            tiendas_user={tiendas_user} 
                                        />
                                        <div className='card-general mt-5'>
                                            <h5 className='text-start'>Últimas noticias</h5>
                                            <hr/>
                                            <div className='d-flex mt-5  justify-content-around'>
                                                {listNews && listNews.map((n, index)=>(

                                                    <div className="card" key={index} style={{width: '18rem', height:'18rem' }}>
                                                        <a href={`${process.env.REACT_APP_BACKEND_URL+n.adjunto}`} className='link-news'>
                                                            <img src={`${process.env.REACT_APP_BACKEND_URL+n.cover}`} className="card-img-top object-fit-cover" alt="" height={150} />
                                                            <div className="mt-3 p-2">
                                                                <h6 className="card-title text-danger text-capitalize fonts-20">{n.titulo.toLowerCase()}</h6>
                                                                <p className="card-text text-secondary descripcion-news fs-16">{n.descripcion}</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    ))
                                                }
                                            </div>
                                            <div className='mb-5'>
                                                <span className='btn-general-accept'>
                                                    <a href={`${process.env.REACT_APP_BACKEND_URL}/noticias`}>Ver más</a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mostrar-movil'>
                                        <div className='card-general w-100'>
                                            <h5>Accesos rápidos</h5>
                                            <hr/>
                                            <div className='text-start quick-access'>
                                                <Link className="link-formater fs-18 my-3 m-regular" to='/tareas'><i className="fas fa-tasks fs-18"></i> Tareas</Link>
                                                <Link className="link-formater fs-18 my-3 m-regular" to='/formularios'><i className="fas fa-check-square fs-18"></i> Formularios</Link>
                                                <a className="link-formater fs-18 my-3 m-regular" href={`${process.env.REACT_APP_BACKEND_URL}/tiendas/comovamos`}><i className="fas fa-chart-bar fs-18"></i> Cómo vamos</a>
                                            </div>
                                        </div>
                                        <TiendasSelect
                                            user_cargo={user?.cod_cargo}
                                            all_tiendas={tiendas}
                                            tiendas_user={tiendas_user} 
                                        />
                                        <br></br><br></br>
                                        <div className='mt-5'>
                                            {/* //////////////// ACCORDEON //////// */}
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    <div className="p-3" id="headingOne">
                                                    <h5 className="mb-0">
                                                        <span className="text-start w-100 py-2" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Últimas noticias
                                                        </span>
                                                    </h5>
                                                    </div>

                                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        { listNews && 
                                                            listNews.map((n, indexn)=>(
                                                                <div className="" key={indexn}>
                                                                    <div className="p-3">
                                                                        <a href={`${process.env.REACT_APP_BACKEND_URL+n.adjunto}`} className='link-news'>
                                                                            <img src={`${process.env.REACT_APP_BACKEND_URL+n.cover}`} className="card-img-top img-fluid" alt="" />
                                                                            <div className="mt-3">
                                                                                <h5 className="card-title text-danger text-capitalize">{n.titulo.toLowerCase()}</h5>
                                                                                <p className="card-text text-dark descripcion-news m-regular mt-1">{n.descripcion}</p>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                    <hr className='w-80'/>
                                                                </div>
                                                            ))
                                                            
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            {/* END ACCORDEON */}
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </>
                }
            </div>
          
        </div>
    )
}
export default Dashboard