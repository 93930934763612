import React, { useEffect } from 'react';
import { Input, Switch } from 'antd';

const ReferenciaInput = ({ inputValue, onInputChange, toggleValue, onToggleChange, transitToggle, onTransitToggleChange, cleanDataAdvance, tallas, cleanSearch }) => {

    useEffect(() => {
        const timer = setTimeout(() => {
            onInputChange({ target: { value: '' } });
        }, 240000);

        return () => clearTimeout(timer);
    }, [inputValue, onInputChange]);

    return (
        <>
            <style>
                {`
                    .ant-input-clear-icon {
                        font-size: 20px !important;
                    }
                    .ant-switch-checked {
                        background-color: red !important;
                    }
                `}
            </style>
            <div style={{
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px'
            }}>
                <Input
                    allowClear
                    placeholder="Escribe o escanea el código de referencia"
                    style={{
                        flex: '1 1 400px',
                        minWidth: '250px',
                        padding: '10px 16px',
                        borderRadius: '5px',
                    }}
                    value={inputValue}
                    onChange={onInputChange}
                />
                <Switch
                    checked={toggleValue === 0}
                    onChange={onToggleChange}
                    checkedChildren="Zona"
                    unCheckedChildren="Zona"
                />
                <Switch
                    checked={transitToggle}
                    onChange={onTransitToggleChange}
                    checkedChildren="En tránsito"
                    unCheckedChildren="En tránsito"
                />
                <div className="text-end fw-500 me-0 btn-red" onClick={cleanDataAdvance}>
                    Filtros
                </div>
                {
                    tallas.length > 0 &&
                    <div onClick={cleanSearch} className='px-3'>
                        <i className="fas fa-trash fs-20" style={{color: 'red'}}></i>
                    </div>
                }

            </div>

        </>
    );
};

export default ReferenciaInput;
