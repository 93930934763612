import React, { useEffect, useState } from 'react';
import DataTableComp from '../../../produccion/autoriaLotes/dataTableComp';
import Spiner from "../../../base/spiner";
import Call from "../../../../config/Call";

const StepsThree = (datos) => {
    const [sp, setSp] = useState(false);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const id = datos?.datosId?.idFile;
    const infoReferencia = datos?.infoReferenciaData?.data

    useEffect(() => {

        getError(currentPage, rowsPerPage);

    }, [currentPage, rowsPerPage]);

    const getError = async (page, limit) => {
        try {
            setSp(true);
            const res = await Call('GET', `/api/producto_vtex/errores/${id}/?page=${page}&page_size=${limit}`, true, null, false);
            if (res && res.data) {
                setData(res.data.results);
                setTotalRows(res.data.count);
            }
        } catch (errors) {
            setSp(false);
        } finally {
            setSp(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const columns = [
        {
            name: 'Referencia',
            cell: row => (
                <div>
                    {row.referencia}
                </div>
            ),
        },
        {
            name: 'Fila',
            cell: row => (
                <div>
                    {row.fila}
                </div>
            ),
        },
        {
            name: 'Detalle',
            cell: row => (
                <div>
                    {row.detalle}
                </div>
            ),
        },
    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const customStyles = {
        headCells: {
            style: {
                paddingLeft: '2px',
                paddingRight: '2px',
                fontSize: '15px',
            },
        },
        cells: {
            style: {
                paddingLeft: '2px',
                paddingRight: '2px',
            },
        },
    };

    const NoDataComponent= (
        <div className="ticket-empty-modal pt-4">
            <div><i className="fas fa-box-open fs-20"></i></div>
            <div className="text-empty">En este momento no hay nada para mostrar</div>
        </div>
    )

    return (
        <div>
            {sp && <Spiner />}
            {
                infoReferencia === '' &&
                <section className="mx-2 my-3 text-end">
                    <span className="btn-info2 fitcontenedor"
                          onClick={() => getError(currentPage, rowsPerPage)}>Actualizar</span>
                </section>
            }

            <style>{`header.sc-dIouRR { display: none;}`}</style>
            <DataTableComp
                columns={columns}
                data={data}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                paginationPerPage={rowsPerPage}
                paginationComponentOptions={paginationComponentOptions}
                noDataComponent={NoDataComponent}
                customStyles={customStyles}
            />
        </div>
    );
};

export default StepsThree;
