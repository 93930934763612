import React, { useState, useEffect } from 'react';
import Call from '../../../../config/Call';
import Spinner from '../../../base/spiner';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';

const Pending = () => {
    const [sp, setSp] = useState(false);
    const [pendingData, setPendingData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [search, setSearch] = useState('');


    const desktopColumns  = [
        {
            name: 'Fecha de ingreso',
            selector: row => new Date(row.fecha).toISOString().split('T')[0],
            sortable: true,
        },
        {
            name: 'Referencia',
            selector: row => row.referencia,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
            cell: row => (
                <div className="text-capitalize" >
                    {row.nombre && row.nombre.toLowerCase()}
                </div>
            ),
            sortable: true,
        },
    ];

    const mobileColumns = [
        {
            name: 'Fecha',
            selector: row => new Date(row.fecha).toISOString().split('T')[0],
            sortable: true,
        },
        {
            name: 'Referencia',
            selector: row => row.referencia,
            sortable: true,
        },
    ];

    const customStyles = {
       
        headCells: {
            style: {
                paddingLeft: '1px',
                paddingRight: '1px',
                fontSize: '15px',
            },
        },
        cells: {
            style: {
                paddingLeft: '2px',
                paddingRight: '2px'
            },
        },
    };

    const PendingList = async () => {
        try {
            setSp(true);
            const res = await Call('GET', `api/producto_vtex/pendiente`, true, null, false);
            if (res && res.data) {
                setPendingData(res.data.data);
            }
        } catch (error) {
            Swal.fire('Error', 'Hubo un error interno', 'error');
        } finally {
            setSp(false);
        }
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleSearchSubmit = async () => {
        try {
            setSp(true);
            const res = await Call('GET', `api/producto_vtex/pendiente?search=${search}`, true, null, false);
            if (res && res.data) {
                setPendingData(res.data.data);
            }
        } catch (error) {
            Swal.fire('Error', error.response.data.message, 'error');
        } finally {
            setSp(false);
        }
    };

    const handleDownloadExcel = async () => {
        try {
            setSp(true);
            const response = await Call('GET', `api/producto_vtex/exportar/`, true, null, false, null, true);
            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            saveAs(blob, 'productos_pendientes.xlsx');
        } catch (error) {
            Swal.fire('Error', 'Hubo un error interno', 'error');
        } finally {
            setSp(false);
        }
    };

    useEffect(() => {
        PendingList();
    }, []);

    useEffect(() => {
        const updateColumns = () => {
            if (window.innerWidth < 999) {
                setColumns(mobileColumns);
            } else {
                setColumns(desktopColumns);
            }
        };
        
        window.addEventListener('resize', updateColumns);
        updateColumns();

        return () => window.removeEventListener('resize', updateColumns);
    }, []);

    const ExpandedComponent = ({ data }) => (
        <div className="fs-14 m-regular" style={{ padding: '10px 20px' }}>
            <p className="m-regular"><strong>Plu:</strong> {data.plu}</p>
            <p className="text-capitalize m-regular"><strong>Nombre:</strong> {data.nombre&& data.nombre.toLowerCase()}</p>
        </div>
    );

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    }

    const NoDataComponent= (
        <div className="ticket-empty-modal pt-4">
            <div><i className="fas fa-box-open fs-20"></i></div>
            <div className="text-empty">En este momento no hay nada para mostrar</div>
        </div>
    )

    return (
        <div className="px-4">
            {sp ? <Spinner /> : ''}
    
            <div className="mb-4">
                <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between">
                    <div className="d-flex align-items-center mb-3 mb-md-0">
                        <input
                            type="text"
                            className="form-control me-2"
                            placeholder="Buscar por referencia o nombre"
                            value={search}
                            onChange={handleSearchChange}
                            style={{ minWidth: '300px', flexGrow: 1 }}
                        />
                        <button className="btn btn-primary me-2" onClick={handleSearchSubmit}>
                            Buscar
                        </button>
                    </div>
    
                    <button className="btn btn-success" onClick={handleDownloadExcel}>
                        <i className="fas fa-download me-2"></i>
                        Descargar Excel
                    </button>
                </div>
            </div>
    
            <DataTable
                data={pendingData}
                columns={columns}
                pagination
                paginationPerPage={15}
                paginationComponentOptions={paginationComponentOptions}
                customStyles={customStyles}
                responsive={true}
                expandableRows={window.innerWidth < 999}
                expandableRowsComponent={ExpandedComponent}
                noDataComponent={NoDataComponent}
            />
        </div>
    );
};

export default Pending;