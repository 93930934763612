import React, {useState,useContext,useEffect} from 'react';
import { Link, useNavigate  } from "react-router-dom";
import {TitleLayout} from '../../base/TitleLayout'
import Spinner from '../../base/spiner';
import Call from '../../../config/Call'
import Swal from 'sweetalert2'
import { Select } from 'antd';
import AuthContext from '../../../contexts/auth/AuthContext';


const Sondeos =(props)=>{
    let datat = {
        titulo: "Sondeos"
    }

    const navigate = useNavigate();
    const [sp, setSp]= useState(false) // MOSTRAR/OCULTAR LOADER
    const [showInfo, setShowInfo] = useState(false)
    const [lUsuarios, setLUsuarios]= useState([]) // USUARIOS TIENDA
    const { user } = useContext(AuthContext) // INFORMACION DE USUARIO
    const [lSondeos, setLsondeos] = useState(
        {
            generados:[],
            pendientes:[]
        })

    const { Option } = Select;
    useEffect(()=>{
        if(user && (user.nombre_cargo === 'GERENTE DE ZONA' || user.nombre_cargo === 'COMERCIAL CLIENTES CORPORATIVOS' || user.nombre_cargo === 'OPERARIO DE MONITOREO' )){
            navigate(`/tiendas/sondeos/historial`)
        }else{
            listSondeos()
            listUsuario()
        }
       
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const listSondeos = async()=>{
        setSp(true)
        const res = await Call('GET', '/api/sondeos', true, null, false)
        if(res.data.code === 1){
            let  pen=[]
            let confirm=[]
            for(let i=0; i < res.data.data.length; i++ ){
                if(!res.data.data[i].completado){
                    pen.push(res.data.data[i])
                }else{
                    confirm.push(res.data.data[i])
                }
            }
            setLsondeos({pendientes: pen, generados:confirm })
            setShowInfo(true)
            setSp(false)
        }else{
            setSp(false)
            Swal.fire({
                icon: 'error',
                title: '',
                text: `${res.data.message}`,
                confirmButtonText:'Aceptar',
            })
        }
       
    }
    const listUsuario =async()=>{
        setSp(true)
        const res= await Call('GET', '/api/sondeos/guardar', true, null, false)
        setLUsuarios(res.data.data)
        // setSp(false)
    }
    const handleChange =(e, pos)=>{
        
        let sondeosP = [...lSondeos.pendientes]
        
        if(e.target){
            if(e.target.name === 'cantidad'){
                sondeosP[pos].cantidad = e.target.value
            }
            setLsondeos({
                ...lSondeos,
            })
        }else{
            sondeosP[pos].usuario = e
            setLsondeos({
                ...lSondeos,
            })
        }
    }
    const generateSondeos=async(id, cantidad, usuario, sub)=>{
        if((cantidad !== '') && (usuario !=='')){
            let datos={
                guardar:{
                    id: id,
                    cantidad: cantidad,
                    usuario: usuario
                }
            }
            setSp(true)
            const res = await Call('POST', '/api/sondeos/guardar', true, datos, false)
            if(res.data.code === 1){
                setLsondeos({
                    generados:[],
                    pendientes:[]
                })
                listSondeos()
            }else{
                setSp(false)
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: `${res.data.message}`,
                    confirmButtonText:'Aceptar',
                })
            }
           
        }else{
            Swal.fire({
                icon: 'info',
                title: '',
                text: `Faltan campos por llenar para confirmar ${sub}`,
                confirmButtonText:'Aceptar',
            })
        }
        
    }
    const deleteSondeos =async(id)=>{
        setSp(true)
        const res = await Call('GET', `/api/sondeos/eliminar/${id}`, true, null, false)
        if(res.data.code===1){
            listSondeos()
        }else{
            setSp(false)
            Swal.fire({
                icon: 'info',
                title: '',
                text: `${res.data.message}`,
                confirmButtonText:'Aceptar',
            })
        }
        
    }
    
    return(
        <div className='pb-4'>
            { sp ? <Spinner /> : ''}  {/*LOADER*/}
            <TitleLayout data={datat} clase={"text-start"} icon={true} seccion={'sondeos'} />
            <div className='bg-white p-4 col-lg-10 col-md-10 col-sm-12 mx-auto'>
                {   lSondeos&&
                        <div>
                            <div className='mb-4 text-end'>
                                <Link to="/tiendas/sondeos/historial" className='btn-buscar cursor-pointer fs-6'>Ver historial</Link>
                            </div>
                            <div>
                                <span className='fw-bold fs-5 text-dark'>Pendientes</span>
                                {
                                    (showInfo && (lSondeos.pendientes.length === 0)) ?
                                        <div className="ticket-empty-modal py-5 my-5">
                                            <div><i className="fas fa-box-open fs-25"></i></div>
                                            <div className="text-empty">En este momento no hay sondeos pendientes</div>
                                        </div>
                                    :
                                    <>
                                        <div className='ocultar-movil'>
                                            <div className='table-responsive'>
                                                <table className="table table-md">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="col-1">Código</th>
                                                            <th scope="col" className="col-3">Sublinea</th>
                                                            <th scope="col" className="col-3">Cantidad</th>
                                                            <th scope="col">Auditado por</th>
                                                            <th scope="col" className="col-1"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { lSondeos &&  lSondeos.pendientes&&
                                                            lSondeos.pendientes.map((s, indexs)=>(
                                                                <tr key={indexs}>
                                                                    <td>{s.id}</td>
                                                                    <td>{s.prenda}</td>
                                                                    <td>
                                                                        <input type="number" 
                                                                            name="cantidad" 
                                                                            onChange={(e)=>{handleChange(e, indexs)}}
                                                                            className='form-control form-control-sm'
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                    <Select
                                                                        showSearch
                                                                        placeholder="Selecciona un usuario"
                                                                        optionFilterProp="children"
                                                                        allowClear
                                                                        style={{ width: 200 }}
                                                                        onChange={(value, options)=>handleChange(value, indexs)}
                                                                        filterOption={(input, option) =>{
                                                                            if(option.children){
                                                                            return option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                                                            }else if(option.label){
                                                                            return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                                                            }
                                                                            }}
                                                                    >
                                                                        {
                                                                            lUsuarios &&
                                                                            lUsuarios.map((u, indexu)=>(
                                                                                <Option style={{ fontSize: '0.80rem' }} key={'u'+indexu} value={u}>{u}</Option>
                                                                            ))
                                                                        }
                                                                        
                                                                        </Select>
                                                                    </td>
                                                                    <td><i onClick={()=>{generateSondeos(s.id, s.cantidad, s.usuario, s.prenda)}} className="fas fa-check fs-5 text-success cursor-pointer"></i></td>
                                                                </tr>
                                                            ))

                                                        }
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                        <div className='mostrar-movil'>
                                            <div className='table-responsive'>
                                                <table className="table table-md">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th >Código</th>
                                                            <th scope="col" >Sublinea</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lSondeos &&
                                                            lSondeos.pendientes &&
                                                            lSondeos.pendientes.map((s, indexs) => (
                                                                <>
                                                                    <tr key={indexs}>
                                                                        <td style={{ width: '5px' }}>
                                                                            <a
                                                                                data-bs-toggle="collapse"
                                                                                className="btn-nav-accordion"
                                                                                href={`#collapseExample${indexs}`}
                                                                                role="button"
                                                                                aria-expanded="false"
                                                                                aria-controls={`collapseExample${indexs}`}
                                                                                style={{ textDecoration: 'none', color: '#000000de' }}
                                                                            >
                                                                                <i className="fas fa-chevron-right"></i>
                                                                            </a>
                                                                        </td>
                                                                        <td style={{ width: '10px' }}>{s.id}</td>
                                                                        <td className="d-block">{s.prenda}</td>
                                                                    </tr>
                                                                    <tr className="collapse" id={`collapseExample${indexs}`}>
                                                                        <td colSpan={4}>
                                                                            <div className="m-2">
                                                                                <span className="fw-600">Cantidad:</span>
                                                                                <input
                                                                                    type="number"
                                                                                    name="cantidad"
                                                                                    onChange={(e) => handleChange(e, indexs)}
                                                                                    className="form-control form-control-sm"
                                                                                />
                                                                            </div>
                                                                            <div className="m-2">
                                                                                <span className="fw-bold">Auditado por: </span>
                                                                                <Select
                                                                                    showSearch
                                                                                    placeholder="Selecciona un usuario"
                                                                                    optionFilterProp="children"
                                                                                    allowClear
                                                                                    style={{ width: 250 }}
                                                                                    onChange={(value, options) => handleChange(value, indexs)}
                                                                                >
                                                                                    {lUsuarios &&
                                                                                        lUsuarios.map((u, indexu) => (
                                                                                            <Option key={`u${indexu}`} value={u}>
                                                                                                {u}
                                                                                            </Option>
                                                                                        ))}
                                                                                </Select>
                                                                            </div>
                                                                            <div className="m-2">
                                                                                <i
                                                                                    onClick={() =>
                                                                                        generateSondeos(s.id, s.cantidad, s.usuario, s.prenda)
                                                                                    }
                                                                                    className="fas fa-check fs-18 text-success cursor-pointer"
                                                                                ></i>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ))}
                                                    </tbody>


                                                </table>
                                                </div>

                                        </div>
                                        </>
                                }
                                    </div>
                            {/* ***************GENERADOS ************************ */}
                            <div>
                                <hr className='pb-3'/>
                                <span className='fw-bold fs-5 pt-4'>Generados</span>
                                <div className='nomenclarura'>
                                    <div className='fs-6 d-flex align-items-center'>
                                        <div className='point danger me-2' width="40" height="40"></div> Unidades sobrantes o faltantes
                                    </div>
                                </div>
                                {
                                    lSondeos && lSondeos.generados.length > 0 ?
                                    <>
                                        <div className='ocultar-movil'>
                                            <div className='table-responsive'>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Código</th>
                                                            <th scope="col">Sublinea</th>
                                                            <th scope="col">Existencias</th>
                                                            <th scope="col">Fisicas</th>
                                                            <th scope="col">Diferencia</th>
                                                            <th scope="col">Auditado por</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { lSondeos && lSondeos.generados &&
                                                            lSondeos.generados.map((s, indexs)=>(
                                                                <tr key={indexs} className={`${s.diferencia < 0? 'dangerdif':(s.diferencia > 0?'dangerdif':'' )}`}>
                                                                    <td width="10">{s.id}</td>
                                                                    <td>{s.prenda}</td>
                                                                    <td width="10">{s.existencia}</td>
                                                                    <td width="10">{s.conteo}</td>
                                                                    <td width="10">{s.diferencia}</td>
                                                                    <td className='text-capitalize'>{s.usuario.toLowerCase()}</td>
                                                                    <td width="10"><i onClick={()=>{deleteSondeos(s.id)}} className="fas fa-times fs-6 text-danger cursor-pointer"></i></td>
                                                                </tr>
                                                            ))

                                                        }
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                        <div className='mostrar-movil table-sondeos'>
                                        <div className='table-responsive'>
                                            <table className="table table-md">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th >Código</th>
                                                        <th scope="col" >Sublinea</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { lSondeos &&  lSondeos.pendientes&&
                                                        lSondeos.generados.map((s, indexs)=>(
                                                            <>
                                                            <tr key={indexs} className={`${s.diferencia < 0? 'dangerdif':(s.diferencia > 0?'warningdif':'' )}`}>
                                                                <td style={{width:'5px'}}>
                                                                    <a 
                                                                        data-bs-toggle="collapse" 
                                                                        className='btn-nav-accordion2'
                                                                        href={`#collapseExampleC${indexs}`} 
                                                                        role="button" 
                                                                        aria-expanded="false" 
                                                                        aria-controls="collapseExampleC"
                                                                        style={{textDecoration:'none', color:'#000000de'}}
                                                                        >
                                                                            
                                                                            <i className="fas fa-chevron-right"></i>
                                                                    </a>
                                                                </td>
                                                                <td style={{width:'10px'}} >{s.id}</td>
                                                                <td className='d-block'>        
                                                                    {s.prenda} 
                                                                </td> 
                                                            </tr>
                                                            <tr rowSpan="3" className="collapse" id={`collapseExampleC${indexs}`}>
                                                                <td colSpan={4} >
                                                                    <div className='m-2'>
                                                                        <span className='fw-bold fs-6'>Existencias: {s.existencia}</span>
                                                                    </div>
                                                                    <div className='m-2'>
                                                                        <span className='fw-bold fs-6'>Físicas: {s.conteo}</span>
                                                                    </div>
                                                                    <div className='m-2'>
                                                                        <span className='fw-bold fs-6'>Diferencia: {s.diferencia}</span>
                                                                    </div>
                                                                    <div className='m-2'>
                                                                        <span  className='fw-bold fs-6 me-2'>Auditado por: </span>
                                                                        <div>{s.usuario.toLowerCase()}</div>
                                                                    </div>
                                                                    <div className='m-2'>
                                                                        <i onClick={()=>{deleteSondeos(s.id)}} className="fas fa-times fs-18 text-danger cursor-pointer"></i>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </>
                                                        ))
                                                    }
                                                </tbody>

                                            </table>
                                        </div>
                                        </div>
                                    </>
                                    :
                                    <div className="ticket-empty-modal py-5 my-5">
                                        <div><i className="fas fa-box-open fs-25"></i></div>
                                        <div className="text-empty">En este momento no hay sondeos generados</div>
                                    </div>
                                }
                                
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}
export default Sondeos;