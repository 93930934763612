import '../../assets/base/css/profilecard.css';

import { Image, Avatar, Badge   } from 'antd';

const ProfileCard = (props) => {
    const {apellidos_nombres, centro_costos, foto, nombre_cargo, is_online } = props.props

    const capitalize= (str) =>{
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
    <div className='d-flex ms-0 container'>
        <div className=''><Badge status={is_online?"success":"default"} dot><Avatar src={<Image src={`${foto}`} style={{ width: 75 }}  className="img-fluid" />} /> </Badge></div>
       
        <div className='body-cardprofile'>
            <div style={{fontWeight: 'bold'}} className='text-capitalize remitente'>
                {apellidos_nombres && apellidos_nombres?apellidos_nombres.toLowerCase():'NO DEFINIDO'}
            </div>
            <div className='estado fs-12 ' style={{fontWeight: 'bold'}} >
                Equipo: <span style={{fontWeight: 'normal'}} >
                {centro_costos?capitalize(centro_costos.toLowerCase()):'NO DEFINIDO'}</span>
            </div>
            <div className='estado fs-12 my-1 ' style={{fontWeight: 'bold'}} >
                Cargo: <span  className=" text-capitalize" style={{fontWeight: 'normal'}}> {nombre_cargo?nombre_cargo.toLowerCase():'NO DEFINIDO'}</span>
            </div>
        </div>
    </div>)
}

export default ProfileCard;

