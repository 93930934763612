import React, {useEffect, useState} from 'react';
import Spinner from '../../../base/spiner';
import { Steps } from "antd";
import ButtonUpload from "../components/ButtonUpload";
import StepsTwo from "../components/StepsTwo";
import StepsThree from "../components/StepsThree";
import TablaCarga from "./TableCarga";
import Call from "../../../../config/Call";
import swal from 'sweetalert2';

const PASO_2=1

const UploadImg = () => {

    const [sp, setSp ] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [current, setCurrent] = useState(0)
    const [datos, setdatos] = useState({
        idFile:null,
        totalRef: 0
    })
    const [idFile, setIdFile] = useState(null)
    const [totalRef, setTotalRef ] = useState(0)
    const [datosId, setDatosId] = useState({})
    const [infoReferencia, setInfoReferencia] = useState('')
    const [infoReferenciaData, setInfoReferenciaData] = useState({})
    const buttonUpload=<ButtonUpload datos={datos} setdatos={setdatos} />
    const stepsTwo = <StepsTwo datosId={datosId} infoReferenciaData={infoReferenciaData} />
    const stepsThree=<StepsThree datosId={datosId} infoReferenciaData={infoReferenciaData} />
    const { Step } = Steps;
    const [stepSelected, setStepSelected] = useState([])



    useEffect(() => {
        if(datos.idFile !== null){

            datosId.idFile= datos.idFile
            datosId.totalRef= datos.totalRef
            setCurrent(PASO_2)
            setTotalRef(datos.totalRef)
        }
    }, [datos?.idFile]);

    useEffect(() => {

    }, [datosId])

    
    useEffect(() => {
        if(infoReferencia !== ''){
            setStepSelected(stepsTable)
            setShowModal(true)
            setCurrent(0)
            datosId.idFile = infoReferencia.id
            datosId.totalRef = 0
            infoReferenciaData.data =infoReferencia
        }

    }, [infoReferencia])


    const stepsModal = [
        {
            title: 'Paso 1',
            content: buttonUpload,
            description: 'Cargar archivo'
        },
        {
            title: 'Paso 2',
            content: stepsTwo,
            description: 'Ver cantidad de registros',
        },
        {
            title: 'Paso 3',
            content:stepsThree,
            description:'Errores',
        },
    ];
    const stepsTable = [
        {
            title: 'Paso 1',
            content: stepsTwo,
            description: 'Ver cantidad de registros',
        },
        {
            title: 'Paso 2',
            content:stepsThree,
            description:'Errores',
        },
    ];
    const optionsModal=()=>{
        setStepSelected(stepsModal)
        setShowModal(true)
    }
    const onChange = (value) => {
        setCurrent(value);
        setIdFile(datos.idFile)
        setTotalRef(datos.totalRef)

    };

    const clearModal=()=>{
        setShowModal(false)
        setStepSelected([])
        setdatos({
            idFile: null,
            totalRef: 0,
        })
        setCurrent(0)
        setInfoReferencia('')
        setInfoReferenciaData({})

    }

    const enviarReferencia = async () => {
        try {
            setSp(true);
            const res = await Call('POST', `/api/producto_vtex/enviar-informacion/${datosId?.idFile}/`, true, null, false);
            if (res && res.data) {
                setSp(false);
                clearModal();
                swal.fire('¡Listo!', 'Se esta enviado la información a VTEX', 'success');
            }
        } catch (error) {
            setSp(false);
        }
    };

    return (
        <div className="px-4">
            {sp && <Spinner />}
            { showModal &&
                <>
                    <div className="modal bd-example-modal-xl" style={{display: 'block', opacity: '1'}} id="exampleModalCenter" tabIndex="-1"
                         role="dialog"
                         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalCenterTitle">{infoReferencia !== '' ? 'Visualización de cargue' : 'Cargue de productos nuevos' }</h5>
                                    <div className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearModal}>

                                    </div>
                                </div>
                                <div className="modal-body" style={{height:'450px', overflowY: 'auto'}}>
                                    <Steps current={current} onChange={onChange}  direction="horizontal">
                                        {
                                            stepSelected?.map(step=>(
                                                <Step
                                                    title={step.title}
                                                    description={step.description}
                                                    className="step-vtex"
                                                    disabled={datos.idFile !== null ? false:(infoReferencia!==''? false: true)}
                                                />
                                            ))
                                        }
                                    </Steps>
                                    <div className="steps-content">{stepSelected[current]?.content}</div>
                                </div>
                                {
                                    (current === 0 && infoReferencia !== '') || (current === PASO_2 && datos.idFile !== null) ? (
                                        <div className="modal-footer">
                                            <div className="btn-info2 fitcontenedor" onClick={enviarReferencia}>Enviar</div>
                                        </div>
                                    ) : null
                                }

                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show" style={{zIndex:'1000'}}></div>
                </>
            }
            <section>
                <div className="btn-general-green" onClick={optionsModal}>Cargar referencias </div>
            </section>
            <section>
                <TablaCarga showModal={showModal} infoReferencia={infoReferencia} setInfoReferencia={setInfoReferencia} />
            </section>
        </div>
    )
}

export default UploadImg
