import React, { useState } from 'react';
import { TitleLayout } from '../../base/TitleLayout';
import Pending from './components/pendientes';
import UploadImg from './components/uploadImg';
import TablaCompletados from './components/TableCompletados';

const Index = () => {
    const [activeTab, setActiveTab] = useState('cargar');

    const titulo = { titulo: "Generador Vtex" };
    const tabContent = {
        cargar: <UploadImg />,
        pendientes: <Pending />,
        completados: <div><TablaCompletados /></div>,
    };

    return (
        <div className='pb-4'>
            <div className='ocultar-movil'>
                <TitleLayout data={titulo} clase="text-left" />
            </div>

            <div className='modal-mercar' data-backdrop-static="true">
                <div className='tabs mb-4 mt-1 d-flex justify-content-start px-2 fs-16 text-dark'>
                    {Object.keys(tabContent).map((tab) => (
                        <div
                            key={tab}
                            className={`tab ${activeTab === tab ? 'active' : ''}`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab.charAt(0).toUpperCase() + tab.slice(1)}
                        </div>
                    ))}
                </div>
                
                {tabContent[activeTab]}
            </div>
        </div>
    );
};

export default Index;