import React, {useContext, useEffect, useState} from 'react';
import '../../../assets/base/css/navbar/navbar.css';
import { UserDropdown } from './UserDropdown.js';
import { ToggleData } from './ToggleData.js';
import { Link } from "react-router-dom";

import logo from '../../../assets/base/images/cubo_rojo.ico'; 

import Call from '../../../config/Call';


import AuthContext from '../../../contexts/auth/AuthContext';

export const NavBar = () => {
    
    const { logout, user } = useContext(AuthContext)
    // useEffect
    const userDetail = {
        userName: user?user.apellidos_nombres:"JOHN",
        userImg: user?user.foto:logo,
        logoutIcon: "fas fa-sign-out-alt",
        logoutTitle: "Salir",
        logoutFunction:logout,
        datas: [{
            link: `${process.env.REACT_APP_MENU_URL}usuarios/perfil`,
            icode: "far fa-user",
            title: "Perfil",
            redirect:true
          },{
            link: `${process.env.REACT_APP_MENU_URL}password/change`,
            icode: "fas fa-bolt",
            title: "Cambiar Contraseña",
            redirect:true
            }
        ],
      };
      

    const NotifyData = {
        toggleName: "Notificaciones",
        iconName: "far fa-bell",
        headerLeft: "Notificaciones",
        headerRight: "Marcar como leidos",
        listClass: "dropdown-list-icons",
        bottomMsg: "Ver todas",
        bottomMsgCls: "fas fa-chevron-right",
      
        content: [
          {
            iClassname: "fa-bell",
            bgClass: "bg-danger",
            description: "cargando...",
            textCls: "text-primary",
            time: "cargando...",
            unreadClass: true,
          },{
                  iClassname: "fas fa-bell",
                  bgClass: "bg-info",
                  description: " Welcome to Stisla template!",
                  time: "Yesterday",
                  unreadClass: false,
                },
        ],
       
      };

    const [notificaciones, setNotificacion] = useState(NotifyData);

    useEffect(() => {  
        notificaciones.content = []
        setNotificacion({...notificaciones})
        getDataForm()
    }, []);

    const getDataForm = async (id) =>{
        try{
            const res = await Call('GET', '/api/notificaciones', true, false, false)            
            if(res.data.data !== null){
                   let noty = {...notificaciones}
                   res.data.data.map((notify, index) => {
                    noty.content.push(
                        {
                            iClassname: notify.icon,
                            bgClass: "bg-danger",
                            description: notify.descripcion,
                            textCls: "text-primary",
                            time:  notify.date,
                            unreadClass: true,
                        }
                    )
                    setNotificacion({notificaciones,...noty})
                })
                
            } else {
                if (res.data.message === "Lo sentimos, no te encuentras activo en el sistema") {
                    // Toast.fire({
                    //   icon: 'error',
                    //   title: 'Lo sentimos, no te encuentras activo en el sistema, contacte con el administrador'
                    // })
                }
            } 
        } catch (error) {
            console.log(error)
        }
    }
    
    return (
        <nav className="navbar navbar-expand-lg justify-content-between py-0">
            <form className="form-inline me-auto">
                <ul className="navbar-nav me-3">
                <li>
                    <Link
                    to="#"
                    data-toggle="sidebar"
                    className="nav-link nav-link-lg"
                    >
                    <i className="fas fa-bars" />
                    </Link>
                </li>
                </ul>
            </form>
            <ul className="navbar-nav justify-content-end">
                {notificaciones && notificaciones.content.length>0?<ToggleData data={notificaciones} />:''}
                <UserDropdown userDetail={userDetail} />
            </ul>
        </nav>
    );
};