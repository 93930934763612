import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, Switch } from 'antd';
import Call from "../../../../config/Call";
import Spinner from '../../../base/spiner';

const { TextArea } = Input;

const EditModal = ({ isVisible, data, onClose, onSave }) => {
    const [sp, setSp] = useState(false);
    const [formData, setFormData] = useState({
        id: '',
        created: '',
        name: '',
        description: '',
        description_short: '',
        modelo: '',
        is_send_comerssia: false,
        title: '',
        meta_tag_description: '',
        vista_rapida: ''
    });

    useEffect(() => {
        if (data) {
            setFormData({
                id: data?.id || '',
                name: data?.name || '',
                description: data?.description || '',
                description_short: data?.description_short || '',
                modelo: data?.modelo || '',
                is_send_comerssia: data?.is_send_comerssia || false,
                title: data?.title || '',
                meta_tag_description: data?.meta_tag_description || '',
                vista_rapida: data?.vista_rapida || '',
            });
        }
    }, [data]);

    const handleChange = (field, value) => {
        setFormData({
            ...formData,
            [field]: value,
        });
    };

    const handleSave = async () => {
        try {
            const data = {
                name: formData.name,
                description: formData.description,
                description_short: formData.description_short,
                meta_tag_description: formData.meta_tag_description,
                vista_rapida: formData.vista_rapida,
                title: formData.title,
            }
            await Call('PATCH', `/api/producto_vtex/referencias/${formData.id}/`, true, data, false);
        } catch (error) {
        }
        if (onSave) onSave(formData);
        onClose();
    };

    const handleRegenerate = async () => {
        try {
          setSp(true);
          const { data } = await Call('POST', `/api/producto_vtex/referencias/generar/${formData.modelo}/`, true, null, false);
          setFormData({
            ...formData,
            name: data.name,
            description: data.description,
            description_short: data.description_short,
            meta_tag_description: data.meta_tag_description,
            vista_rapida: data.vista_rapida,
            title: data.title,
          });
          setSp(false);
        } catch (error) {
          setSp(false);
        }
      };

    return (
        <div>
        {sp ? <Spinner/> : ''}
        <Modal
            title="Editar Información"
            open={isVisible}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancelar
                </Button>,
                <Button key="save" type="primary" onClick={handleSave}>
                    Actualizar
                </Button>,
                <Button key="save" type="primary" onClick={handleRegenerate}>
                    Regenerar
                </Button>,
            ]}
            bodyStyle={{
                maxHeight: '500px',
                overflowY: 'auto',
            }}
            style={{ top: 20 }}
        >
            <div>
                <label><strong>Referencia:</strong></label>
                <Input
                    disabled
                    style={{ marginBottom: '10px' }}
                    value={formData.modelo}
                    onChange={(e) => handleChange('modelo', e.target.value)}
                />
                <label><strong>Nombre:</strong></label>
                <Input
                    style={{ marginBottom: '10px' }}
                    value={formData.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                />

                <label><strong>Titulo:</strong></label>
                <Input
                    style={{ marginBottom: '10px' }}
                    value={formData.title}
                    onChange={(e) => handleChange('title', e.target.value)}
                />


                <label><strong>Descripción:</strong></label>
                <TextArea
                    rows={8}
                    style={{ marginBottom: '10px' }}
                    value={formData.description}
                    onChange={(e) => handleChange('description', e.target.value)}
                />

                <label><strong>Descripción corta:</strong></label>
                <TextArea
                    rows={2}
                    style={{ marginBottom: '10px' }}
                    value={formData.description_short}
                    onChange={(e) => handleChange('description_short', e.target.value)}
                />
                <label><strong>Vista rápida:</strong></label>
                <TextArea
                    rows={2}
                    style={{ marginBottom: '10px' }}
                    value={formData.vista_rapida}
                    onChange={(e) => handleChange('vista_rapida', e.target.value)}
                />
                <label><strong>Tags:</strong></label>
                <TextArea
                    rows={2}
                    style={{ marginBottom: '10px' }}
                    value={formData.meta_tag_description}
                    onChange={(e) => handleChange('meta_tag_description', e.target.value)}
                />
            </div>
        </Modal>
        </div>
    );
};

export default EditModal;
