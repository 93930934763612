import React, { useEffect, useState } from 'react';
import DataTableComp from 'react-data-table-component';
import ModalEditar from "./ModalEditar";
import ModalSku from "./ModalSku";
import Spiner from "../../../base/spiner";
import Call from "../../../../config/Call";
import { Progress } from "antd";

const StepsTwo = (datos) => {
    const [sp, setSp] = useState(false);
    const [showModalEditar, setShowModalEditar] = useState(false);
    const [showModalSku, setShowModalSku] = useState(false);
    const [informationModalSku, setInformationModalSku] = useState([]);
    const [informationModalProduct, setInformationModalProduct] = useState({});
    const [columnasTable, setColumnasTable] = useState([]);
    const [percentage, setPercentage] = useState(0);
    const [info, setInfo] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [_, setHideColumn] = useState(false);

    const id = datos?.datosId?.idFile;
    const cantRef = datos?.datosId?.totalRef;
    const infoReferencia = datos?.infoReferenciaData?.data

    useEffect(() => {

    }, [datos]);

    useEffect(() => {
        if(infoReferencia?.data){
            setHideColumn(true)
        }

    }, [infoReferencia?.data]);

    useEffect(() => {
        getInfo(currentPage, rowsPerPage);
    }, [id, cantRef, currentPage, rowsPerPage]);

    useEffect(() => {
        const updateColumns = () => {
            if (window.innerWidth < 999) {
                setColumnasTable(mobileColumns);
            } else {
                setColumnasTable(columns);
            }
        };

        window.addEventListener('resize', updateColumns);
        updateColumns();

        return () => window.removeEventListener('resize', updateColumns);
    }, []);
    const getSkuInformation = async (product) => {
        try {
            setSp(true);
            const res = await Call('GET', `/api/producto_vtex/skus/?product=${product.id}`, true, null, false);
            if (res && res.data) {
                const transformedData = res.data.results.map((item, index) => ({
                    key: index,
                    name: item.name,
                    talla: item.talla,
                    color: item.color,
                    is_active: item.is_active,
                }));
                setInformationModalSku(transformedData);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSp(false);
        }
    };
    
    const infoModalProduct = (row) => {
        setShowModalEditar(true);
        setInformationModalProduct(row);
    };

    const infoModalSku = (row) => {
        setShowModalSku(true);
        getSkuInformation(row);
    };

    const accionModalSku = () => {
        setShowModalSku(false);
    };

    const accionModal = () => {
        setShowModalEditar(false);
    };

    const columns = [
        {
            name: 'Referencia',
            cell: row => (
                <div>
                    {row.modelo}
                </div>
            ),
            left: true,
        },
        {
            name: 'Titulo',
            cell: row => (
                <div>
                    {row.name}
                </div>
            ),
            left: true,
        },
        {
            name: 'Descripción',
            center: true,
            cell: row => (
                <div style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipsis' }}>
                    {row.description_short && row.description_short.slice(0, 100)}...
                </div>
            ),
        },
        {
            name: 'Tag',
            cell: row => (
                <div>
                    {row.meta_tag_description}
                </div>
            ),
            center: true,
        },
        {
            name: '',
            center: true,
            cell: row => {
                let buttonClass = "btn-general-red py-2";
                if (row.estado_general === "green") {
                    buttonClass = "btn-general-green py-2";
                } else if (row.estado_general === "yellow") {
                    buttonClass = "btn-general-yellow py-2";
                }
        
                return (
                    <div className={buttonClass} onClick={() => infoModalSku(row)}>
                        Skus
                    </div>
                );
            },
            width: '10%',
        },
        {
            name: '',
            center: true,
            cell: row => (
                !row.is_send_comerssia ? (
                    <div className="btn-general-green py-2" onClick={() => infoModalProduct(row)}>
                        Editar
                    </div>
                ) : null
            ),
            width: '10%',
        },
    ];

    const mobileColumns = [
        {
            name: 'Referencia',
            selector: row => row.modelo,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <div className="btn-general-green py-2" onClick={() => infoModalProduct(row)}>
                    Editar
                </div>
            ),
            omit: infoReferencia?.id ?true: false ,
        },

    ];

    const customStyles = {
        headCells: {
            style: {
                paddingLeft: '2px',
                paddingRight: '2px',
                fontSize: '15px',
            },
        },
        cells: {
            style: {
                paddingLeft: '2px',
                paddingRight: '2px',
            },
        },
    };

    const ExpandedComponent = ({ data }) => (
        <div className="fs-14 m-regular text-start" style={{ padding: '10px 20px' }}>
            <p className="text-capitalize m-regular"><strong>Titulo:</strong> {data.name && data.name.toLowerCase()}</p>
            <p className="text-capitalize m-regular" style={{ overflow: 'hidden', whiteSpace: 'wrap', textOverflow: 'ellipsis' }}><strong>Descripción:</strong> {data.description_short && data.description_short.slice(0, 100)}...</p>
            <p className="text-capitalize m-regular"><strong>Tag:</strong> {data.meta_tag_description}</p>
        </div>
    );

    const getInfo = async (page, limit) => {
        try {
            setSp(true);
            const res = await Call('GET', `/api/producto_vtex/referencias/?cargue_id=${id}&page=${page}&page_size=${limit}`, true, null, false);
            if (res?.data?.results) {
                const cantProcesadas = res.data?.results[0]?.cargue_id?.cantidad_referencia_processadas;
                calcPercentage(cantProcesadas);
                setInfo(res?.data?.results);
                setTotalRows(res?.data?.count);
            }
            setSp(false);
        } catch (error) {
            setSp(false);
        }
    };

    const calcPercentage = (cantProcesadas) => {

        const percentageTotal = (100 / cantRef) * cantProcesadas;
        setPercentage(percentageTotal);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const NoDataComponent= (
        <div className="ticket-empty-modal pt-4">
            <div><i className="fas fa-box-open fs-20"></i></div>
            <div className="text-empty">En este momento no hay nada para mostrar</div>
        </div>
    )

    return (
        <div>
            {sp && <Spiner/>}
            {showModalEditar &&
                <ModalEditar accionModal={accionModal} infoModalProduct={informationModalProduct}/>
            }
            {showModalSku && (
                <ModalSku
                    accionModalSku={accionModalSku}
                    infoModalProduct={informationModalSku}
                />
            )}
            <section className="d-flex flex-wrap w-100 my-2 gap-20" style={{ alignItems: 'end' }}>
                {cantRef > 0 ?
                    <>
                        <div className="w-30">
                            <div className="text-start fw-500">Referencias procesadas</div>
                            <Progress percent={percentage} status="active" />
                        </div>
                        <div className="btn-info2 mb-0 me-2 " onClick={() => getInfo(currentPage, rowsPerPage)}>Actualizar</div>
                    </>
                    :
                    (
                        ((infoReferencia === '') || ( infoReferencia === undefined)) &&
                            <div>
                                No hay datos para procesar
                            </div>

                    )

                }

            </section>
            <section>
                <style>{`header.sc-dIouRR { display: none;}`}</style>
                <DataTableComp
                    columns={columnasTable}
                    data={info}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationComponentOptions={paginationComponentOptions}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    paginationPerPage={rowsPerPage}
                    customStyles={customStyles}
                    responsive={true}
                    expandableRows={window.innerWidth < 999}
                    expandableRowsComponent={ExpandedComponent}
                    noDataComponent={NoDataComponent}
                />
            </section>
        </div>
    );
};

export default StepsTwo;
