import React from 'react';
import ConsultarReferencia from '../components/tiendas/consultar_referencia/IndexPage.js';

const ExternalRouters = () => {
    return (
           <ConsultarReferencia/>
    )

}
export {ExternalRouters};
