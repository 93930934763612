import React, {useEffect, useState} from "react";
import {message, Upload} from "antd";
import {Link} from "react-router-dom";
import Call from "../../../../config/Call";
import Spiner from "../../../base/spiner";

const ButtonUpload=({datos, setdatos} )=>{
    const [sp, setSp] = useState(false);
    const [dataFile, setDatafile] = useState({fileList:[]})

    useEffect(() => {
        if(dataFile.fileList.length>0)
            uploadingFile()
    }, [dataFile]);

    const beforeUpload = () => {
        return false;
    }


    const onChangeImg = async ({ fileList }) =>{

        let fileUpload= fileList[fileList?.length-1]
        let fileSize= (fileList[fileList?.length-1] && fileList[fileList?.length-1]?.size)
        let sizeTotal= fileList[fileList?.length-1]?.size/1024/1024
        let typeFile= fileList[fileList?.length-1]?.type

        if((fileSize) && (sizeTotal)> 8){
            message.error('El archivo debe ser menor a 8M' );
        }else if((fileUpload && typeFile !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ) || ( fileUpload && typeFile === '')){
            message.error('El formato del archivo no es permitido');
        }else{
            setDatafile({ fileList })
        }
    }
    const uploadingFile = async()=>{
        var file = dataFile.fileList[0].originFileObj
        let formdata = new FormData()
        formdata.append('file',file)

        try{
            setSp(true)
            const res= await Call('POST', '/api/producto_vtex/comparacion/', true, formdata, true, 600000)
            setdatos({
                idFile: res.data.cargue_id,
                totalRef: res.data.cantidad_referencias,
            })

            setSp(false)
        }catch (error){
            setSp(false)
        }
    }
    return(
        <div className="text-center pt-4">
            {sp && <Spiner/>}
            <h6 className="m-regular fw-600 fs-18 pt-2">Instrucciones</h6>
            <p className="m-regular fw-400"> Asegúrate de tener la herramienta necesaria para generar el reporte. </p>
            <p className="m-regular fw-400">
                Para obtener la herramienta, por favor contacta al área de sistemas.
            </p>
            <li className="m-regular">Abre la herramienta.</li>
            <li className="m-regular">Carga el archivo con las imágenes necesarias.</li>
            <li className="m-regular">Una vez procesadas las imágenes, descarga el archivo Excel generado.</li>
            <li className="m-regular">Sube el archivo Excel descargado en el campo correspondiente.</li>
            <div className="pt-4 mt-4">
                <Upload
                    accept=".xls, .xlsx"
                    maxCount={1}
                    fileList={dataFile.fileList}
                    onChange={onChangeImg}
                    beforeUpload={beforeUpload}
                    className='fit-content'
                >
                    <div
                        className='mx-0 btn-sec d-flex align-items-center justify-content-start btn-info-outline fs-16 fit-content'
                        style={{color: 'white', background: '#3abaf4', paddingLeft: '1rem', paddingRight: '1rem'}}>
                        <i className="fas fa-upload fs-16 me-2"></i> Cargar archivo
                    </div>

                </Upload>
            </div>
        </div>
    )
}
export default ButtonUpload;