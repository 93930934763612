import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// layouts
import { LayoutMain } from '../layouts/LayoutMain';
import PublicLayout from '../layouts/PublicLayout';
import {AdminRouters} from './AdminRoutes';
import { Login } from '../layouts/Login';
import Dashboard from "../components/dashboard/dashboard";

// context
import AuthContext from '../contexts/auth/AuthContext';
import Jhon from '../components/chatbot/chatbot';

const LayoutMainRoutes = () => {
    const { isAuthenticated } = useContext(AuthContext);

    const requireAuth = (Component) => {
        if (isAuthenticated) {
            return <Component />;
        } else {
            let route = "/login";
            if (window.location.pathname !== '') {
                route = route + "?next=" + window.location.pathname;
            }
            return <Navigate to={route} />;
        }
    };

    return (
       <>
            <Routes>
                <Route path='/login' element={<Login/>}/>
                <Route
                    path='/consultar-referencias'
                    element={requireAuth(PublicLayout)}
                />
                <Route path="/" element={requireAuth(LayoutMain)}>
                    <Route index  element={requireAuth(Dashboard)} />
                    <Route path="/*" element={<AdminRouters />} />
                </Route>
            </Routes>
           {isAuthenticated && <Jhon/>}
        </>
    );
};

export {
    LayoutMainRoutes
};
