import React, {useState, useEffect, useContext, useMemo } from 'react';
import Call from '../../../config/Call'
import Spinner from '../../base/spiner';
import {TitleLayout} from '../../base/TitleLayout'
import Swal from 'sweetalert2'
import {  Select,DatePicker, ConfigProvider} from 'antd';
import AuthContext from '../../../contexts/auth/AuthContext';
import es_ES from 'antd/es/locale/es_ES';
import dayjs from 'dayjs';
import moment from 'moment';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import {saveAs} from "file-saver"
import { decode } from '../../../config/crypto'

import DataTableComp from '../../produccion/autoriaLotes/dataTableComp'
import DataTableMovil from '../../produccion/autoriaLotes/dataTableCompMovil';

const { Option } = Select;
const { RangePicker } = DatePicker;
dayjs.extend(weekday)
dayjs.extend(localeData)



const Ingresos = (props)=>{

    //SEARCH REMOTE
    let timeout;
    let currentValue;
    // END SEARCH REMOTE
    let datat = {
         titulo: "Control de ingresos"
     }
    const [sp, setSp] = useState(false) // SPINNER
    const [aforo, setAforo] =useState('') // # PERSONAS
    const { user } = useContext(AuthContext) // INFORMACIÓN DE USUARIO
    const [lista, setLista] = useState('entrada') // LISTADO A MOSTRAR
    const [state, setState]= useState({data: [], value:''}) // LISTA BUSQUEDA REMOTA
    const [listadoIngresos, setListadoIngresos] = useState([])
    const [datosTable, setDatosTable] = useState([]) //DATOS DATATABLE REPORTE
    const [lAreas, setLAreas] = useState([])
    const [showExterno, setShowExterno] = useState(false)
    const [filterText, setFilterText] = useState('');
    const [infoExterno, setInfoExterno]= useState({
        nombre_completo:'',
        documento:'',
        tipo:'',
        tipo_sangre:'',
        nombre_contacto:'',
        telefono_contacto: ''
    })
   
    const [filtros, setFiltros] = useState({
        area:[],
        fecha: [],
    })

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [errores, setErrores] = useState({});

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(()=>{
       
    }, [state.value])

    useEffect(()=>{
        getAforo()
        if(user && ((user.centro_costos === 'AREA GESTION HUMANA') || (user.centro_costos === 'AREA SISTEMAS') || (user.nombre_cargo === 'DIRECTOR DE OPERACIONES'))){
            listAreas()
            ultimoSieteDias()
        }
        listPeople()
    }, [])
    

    const getAforo= async()=>{
        setSp(true)
        const res= await Call('GET', '/api/control/aforo', true, null, false)
        setAforo(res.data.data)
        setSp(false)
    }

    const columns=[
        {
            name: <span className="fw-bold">Nombre</span>,
            cell: row => (
                <div className='text-start text-capitalize'>
                    {row.usuario.nombre&& row.usuario.nombre.toLowerCase() + ' - ' + row.usuario.documento}
                </div>
            ),
            filterable: true,
            
        },
        {
            name: <span className="fw-bold">Usuario</span>,
            cell: row => (
                <div>
                    {row.usuario.tipo}
                </div>
            ),
            filterable: true,
            width:'100px'
        },
        {
            name: <span className="fw-bold">Hora Entrada</span>,
            cell: row => (
                <div className='text-start'>
                    {row.created_at}
                </div>
            ),
            filterable: true,
            width:'180px'
        },
        {
            name: <span className="text-start fw-bold">Dar salida</span>,
            cell: row => (
                <div onClick={()=>{ingresoPersona(row, 'salida')} }>
                    {row.exit_enable && <i className="fas fa-sign-out-alt fonts-20 cursor-pointer"></i>}
                </div>
            ),
            filterable: true,
            width:'100px'
        }
    ]
    const columnsMov=[
        {
            name: <span className="fw-bold">Nombre</span>,
            cell: row => (
                <div className='text-start text-capitalize'>
                    {row.usuario.nombre && row.usuario.nombre.toLowerCase()}
                </div>
            ),
            filterable: true,
        },
        {
            name: <span className="text-start fw-bold">Dar salida</span>,
            cell: row => (
                <div onClick={()=>{ingresoPersona(row)} }>
                    <i className="text-start fas fa-sign-out-alt fs-6 cursor-pointer"></i>
                </div>
            ),
            filterable: true,
            width:'100px'
        }
    ]
    const columnsSalida=[
        {
            name: 'Nombre',
            cell: row => (
                <div className='text-start text-capitalize'>
                     {row.usuario.nombre && row.usuario.nombre.toLowerCase() + ' - ' + row.usuario.documento}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Usuario',
            cell: row => (
                <div>
                    {row.usuario.tipo}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Hora Salida',
            cell: row => (
                <div>
                     {row.created_at}
                </div>
            ),
            filterable: true,
            
        }
    ]
    const columnsSalidaMov=[
        {
            name: <span className="fw-bold">Nombre</span>,
            cell: row => (
                <div className='text-start text-capitalize'>
                     {row.usuario.nombre && row.usuario.nombre.toLowerCase()}
                </div>
            ),
            filterable: true,
        },
        {
            name: <span className="text-start fw-bold">Hora salida</span>,
            cell: row => (
                <div className="text-start">
                     {row.created_at}
                </div>
            ),
            filterable: true,
            
        }
    ]
   
    const columnsReport=[
        {
            name: <span className="fw-bold">Fecha</span>,
            cell: row => (
                <div>
                    {row.fecha+ ' - '+ row.hora}
                </div>
            ),
            filterable: true,
        },
        {
            name: <span className="fw-bold">Nombre</span>,
            cell: row => (
                <div className='text-capitalize'>
                    {row.nombre && row.nombre.toLowerCase()}
                </div>
            ),
            filterable: true,
        } ,
        {
            name: <span className="fw-bold">Documento</span>,
            cell: row => (
                <div>
                    {row.documento}
                </div>
            ),
            filterable: true,
            width:'120px'
        },
        {
            name: <span className="fw-bold">Area</span>,
            cell: row => (
                <div className='text-capitalize'>
                    {row.area ? row.area.toLowerCase(): 'Externo'}
                </div>
            ),
            filterable: true,
            width:'200px'
        },
        {
            name: <span className="fw-bold">Usuario</span>,
            cell: row => (
                <div>
                    {row.tipo_usuario}
                </div>
            ),
            filterable: true,
            width:'100px',
        },
        {
            name: <span className="fw-bold">RH</span>,
            cell: row => (
                <div>
                    {row.tipo_sangre ? row.tipo_sangre.toUpperCase() : '-'}
                </div>
            ),
            filterable: true,
            width:'70px'
        },
        {
            name: <span className="fw-bold">Tipo</span>,
            cell: row => (
                <div className='d-flex'>
                    <div className={`tag ${row.tipo_ingreso === 'Entrada'? 'entrada':'salida'}`}>
                    </div>
                    {row.tipo_ingreso}
                </div>
            ),
            filterable: true,
            width:'120px',
        }      
        
    ]
    const columnsReportMov=[
        {
            name: 'Fecha',
            cell: row => (
                <div>
                    {row.fecha}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Nombre',
            cell: row => (
                <div className='text-capitalize'>
                    {row.nombre && row.nombre.toLowerCase()}
                </div>
            ),
            filterable: true,
        } ,   
    ]
    
    const fet=(value) =>{
        if(value !== ''){
            if (timeout) {
            clearTimeout(timeout);
            timeout = null;
            }
            currentValue = value;
            
            const searchUser = async ()=> {
        
            setSp(true)
            const res = await Call('GET', '/api/control?buscar='+value, true, null, false)
                    const data = [];
                    res.data.data.forEach(r => {
                    data.push({
                        value: r.id,
                        text: r.nombre_completo.toUpperCase(),
                        doc: r.documento,
                        tipo_user: r.tipo_usuario
                    });
                    });
                    getAforo()
                    // callback(data);
                // state.data= data
                // state.value = value
                setState({
                    data:data,
                    value:value
                })
                setSp(false)
            }
            timeout = setTimeout(searchUser, 800)
            // searchUser()
                
        }
        
      }
    const handleSearch = (value) => {
       
       
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
                }
                currentValue = value;
                timeout= setTimeout(()=>{   
                    if (value !== '') {
                       timeout= setTimeout(fet(value), 800)
                    }else{
                        
                        state.data= []
                        state.value = ''
                        cleanFet()
                    } 
                }, 800)
        
    }
    const cleanFet =()=>{
        state.data= []
        state.value = ''
        setState({
            data: [],
            value:''
        })
    }
    const handleChange = (value, option, tipo) => {
        
        if(tipo === 'grupos'){
            setFiltros({
                ...filtros,
                area: value
            })
            filtrar(undefined, value)
        }else{
            if(value !== ''){
                setState({ 
                    ...state, 
                    value: value 
                });
                if(value){
                    ingresoPersona(option)
                }
            }else{
                
            }
            
        }
    }

    const ingresoPersona = async(datos, tipo)=>{
        // BUSCA Y DA INGRESO A LOS USUARIOS
        let info={
            id: (datos.value?datos.value : datos.id) ,
            documento: (datos.doc? datos.doc: datos.usuario.documento),
            tipo_usuario: (datos.tipo ? datos.tipo : datos.usuario.tipo )
        }
        if(tipo === 'salida'){
            Swal.fire({
                title:'',
                html:'Confirmar salida',
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#23b991',
                cancelButtonColor: '#ee4630',
                confirmButtonText: 'Aceptar',
                cancelButtonText:'Cancelar',
                customClass:{
                popup:'w-33 pop-up33',
                cancelButton:'btn-cancel-general',
                confirmButton:'btn-cancel-general',
                // htmlContainer:'content-alert'
                }
              }).then(async(result) => {
               
                if (result.isConfirmed) {
                    setSp(true)
                    const res = await Call('POST', '/api/control?buscar=', true, {datos:info}, false )
                    
                    if(res.data.code === 1){
                        
                        setState({
                            data: [],
                            value: ''
                        })
                        if(lista === 'entrada'){
                            setListadoIngresos(res.data['listado de entradas'])
                        }else{
                            setListadoIngresos(res.data['listado_salidas'])
                        }
                        getAforo()
                    }
                    setSp(false)
                } 
              })
        }else{
            
            let result = listadoIngresos.filter(word => word.usuario.documento === (datos.doc? datos.doc: datos.usuario.documento))
           
            if(result.length > 0){
                Swal.fire({
                    title:'',
                    html:'Confirmar salida',
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#23b991',
                    cancelButtonColor: '#ee4630',
                    confirmButtonText: 'Aceptar',
                    cancelButtonText:'Cancelar',
                    customClass:{
                    popup:'w-33 pop-up33',
                    cancelButton:'btn-cancel-general',
                    confirmButton:'btn-cancel-general',
                    // htmlContainer:'content-alert'
                    }
                  }).then(async(result) => {
                   
                    if (result.isConfirmed) {
                        setSp(true)
                        const res = await Call('POST', '/api/control?buscar=', true, {datos:info}, false )
                        
                        if(res.data.code === 1){
                            
                            setState({
                                data: [],
                                value: ''
                            })
                            if(lista === 'entrada'){
                                setListadoIngresos(res.data['listado de entradas'])
                            }else{
                                setListadoIngresos(res.data['listado_salidas'])
                            }
                            getAforo()
                        }
                        setSp(false)
                    }else{
                        setState({
                            data: [],
                            value: ''
                        })
                    } 
                  })
            }else{
                setSp(true)
                const res = await Call('POST', '/api/control?buscar=', true, {datos:info}, false )
                
                if(res.data.code === 1){
                    
                    setState({
                        data: [],
                        value: ''
                    })
                    if(lista === 'entrada'){
                        setListadoIngresos(res.data['listado de entradas'])
                    }else{
                        setListadoIngresos(res.data['listado_salidas'])
                    }
                    getAforo()
                }
                setSp(false)
            }
        }
        
    }

    //OPCIONES BUSQUEDA
    const options = state.data&& state.data.map(d => (
        <Option key={d.value} tipo={d.tipo_user} doc={d.doc} value={d.value}>
            {d.text + ' - '+ d.doc}
        </Option>
    ));
    const listAreas = async()=>{
        // LISTA DE AREAS DE LA EMPRESA
        setSp(true)
        const res= await Call('GET', '/api/grupos', true, null, false)
        setLAreas(res.data.results)
        setSp(false)

    }
    const ultimoSieteDias = (limpiar) =>{
        //fecha actual
        var dt = new Date();
        let fFin=moment(dt).format('YYYY-MM-DD')
        //Fecha 7 días
        dt.setDate( dt.getDate() - 7 );
       
        let fInicio=moment(dt).format('YYYY-MM-DD')
    
        filtros.fecha=[fInicio, fFin]
        setFiltros({
        ...filtros
        })
        filtrar(filtros.fecha, filtros.area)
    }

    const handleDate =(value, mode)=>{
       
        if(value === null){
            ultimoSieteDias()
        }else{
            setFiltros({
                ...filtros,
                fecha: mode,
            })
            filtrar(mode, filtros.area)
        }

    }

    const registrarExterno = async()=>{
        // REGISTRO DE USUARIOS EXTERNOS 
        setSp(true)
        const res = await Call('POST', 'api/control/registro/externo', true, {datos: infoExterno}, false)
        
        if(res.data.code === 1){
            Swal.fire({
                title: '',
                icon: 'success',
                html: res.data.message,
                focusConfirm: false,
                confirmButtonText:'Aceptar',
            }) 
            setShowExterno(false)
            setSp(false)
            setInfoExterno({
                nombre_completo:'',
                documento:'',
                tipo:'',
                tipo_sangre:'',
                nombre_contacto:'',
                telefono_contacto: ''
            })
            //ACTUALIZACIÓN LISTA DE INGRESOS
            setListadoIngresos(res.data.data)
            getAforo()
        }else{
            Swal.fire({
                title: '',
                icon: 'error',
                html: res.data.message,
                focusConfirm: false,
                confirmButtonText:'Aceptar',
            }) 
            setSp(false)
        }
    }

    const filtrar = async(fecha, areas)=>{
        setSp(true)
        try {
            const res = await Call('GET', `/api/control/reporte?fecha1=${fecha !== undefined ? fecha[0]:(filtros.fecha && filtros.fecha[0] )}&fecha2=${fecha !== undefined? fecha[1]: (filtros.fecha && filtros.fecha[1])}${(areas.length>0 && areas !== undefined )? '&area='+areas: '' }`, true, null, false)
            if(res.data.code === 1){
                setDatosTable(res.data.data.reverse())
            }
            setSp(false)
        } catch (error) {
            Swal.fire({
                title: '',
                icon: 'error',
                html: error,
                focusConfirm: false,
                confirmButtonText:'Aceptar',
            }) 
            setSp(false)
        }
        
    }
    
    const listPeople = async(dato)=>{
        setSp(true)
        const res = await Call('GET', `/api/control/${dato? dato: lista}`, true, null, false)
        setListadoIngresos(res.data.data)
        setSp(false)
    }
    const exportar =async()=>{
        let token= decode(localStorage.getItem('authInfo')).token // SACANDO TOKEN
        // URL PARA DESCARGAR ARCHIVO
        let url= `${process.env.REACT_APP_BACKEND_URL}/api/control/reporte?fecha1=${filtros.fecha[0]}&fecha2=${filtros.fecha[1]}${(filtros.area.length>0 && filtros.area !== undefined )? '&area='+filtros.area: '' }&exportar_a_excel=True`       
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
            .then(function (response) {
                    return response.blob();
                }
            )
            .then(function(blob) {
                saveAs(blob, `reporte-control-ingresos.xlsx`);
            })
            .catch(error => {
                //whatever
            })
        
    }
    const handletext =(e)=>{
        
        setFilterText(e.target.value)
        let nuevo = (listadoIngresos.filter(item => (!item.data&& item.usuario.nombre) && item.usuario.nombre.toLowerCase().includes(e.target.value.toLowerCase())))
        
        setListadoIngresos(nuevo)
        if(e.target.value === ''){
            listPeople()
        }
    }
    const handleClear = () => {
        
        if (filterText) {
            setFilterText('');
            listPeople()
        }
    };
    const validate =()=>{
        if(user && (user.centro_costos === 'AREA GESTION HUMANA') || (user.centro_costos === 'AREA SISTEMAS') || (user.nombre_cargo === 'DIRECTOR DE OPERACIONES') ){
            listAreas()
            ultimoSieteDias()
        }
    }
    const subHeaderComponentMemo2 = React.useMemo(() => {
        return (
            <>
            <input type="text" className={`form-control form-control-sm ${isMobile ? 'w-100' : 'w-25'}`}
                    placeholder="Buscar nombre" 
                    aria-label="Buscar" 
                    aria-describedby="basic-addon1" 
                    onChange={handletext} 
                    value={filterText} 
                />
                { filterText !== '' &&
                    <div type="button" className='pl-2 cursor-pointer' 
                        onClick={handleClear} >
                        <i className="fas fa-times-circle"></i>
                    </div>
                }
    
            </>
        );
    
    });
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };  
    const expandedComponentDetalle=(row)=>{
        return(
            listadoIngresos && listadoIngresos.map((a, indexa)=>(
                   (a.id === row.data.id) && 
                    <div className='py-2 d-block text-start' key={indexa}>
                    <div className='fs-6'><span className='fw-bold fs-6'>Usuarios: </span> {a.usuario.tipo} </div>
                    <div className='fs-6'><span className='fw-bold fs-6'>Hora entrada: </span>{a.created_at }</div>
                </div>
            ))           
        )
    }
    const expandedComponentDetalleReporte=(row)=>{
        return(
            datosTable && datosTable.map((a, indexa)=>(
                   (a.fecha === row.data.fecha && a.hora === row.data.hora && a.nombre === row.data.nombre ) && 
                    <div className='py-2 d-block text-start' key={indexa}>
                    <div className='fs-6'><span className='fw-bold fs-6'>Hora: </span> {a.hora} </div>
                    <div className='fs-6'><span className='fw-bold fs-6'>Documento:</span> {a.documento}</div>
                    <div className='fs-6'><span className='fw-bold fs-6'>Área:</span><span className='text-capitalize'> {a.area ? a.area.toLowerCase() : 'Externo'} </span> </div>
                    <div className='fs-6'><span className='fw-bold fs-6'>Usuario:</span> {a.tipo_usuario}</div>
                    <div className='fs-6'><span className='fw-bold fs-6'>RH:</span> {a.tipo_sangre? a.tipo_sangre.toUpperCase() :'-'}</div>
                    <div className='d-flex'>
                        <div className='fw-bold fs-6 me-1'>Tipo:</div> 
                        <div className='d-flex'>
                            <div className={`tag ${a.tipo_ingreso === 'Entrada'? 'entrada':'salida'} `}></div>
                            <span className='fs-6'>{a.tipo_ingreso}</span>
                </div></div>
                </div>
            ))           
        )
    }
    const customStyles ={    
        headCells: {
            style: {
                fontSize: '17px',    
                paddingLeft: '16px',
                paddingRight: '16px',
                color:'#678098',
            },
        },
        cells: {
            style: {
                fontSize: '17px',  
            },
        },
    }
    const customStyles2 ={    
        headCells: {
            style: {
                fontSize: '14px',    
                paddingLeft: '16px',
                paddingRight: '16px',
                color:'#678098',
            },
        },
        cells: {
            style: {
                fontSize: '14px',  
                
            },
        },
    }


    const validarCampos = () => {
        const nuevosErrores = {};
    
        if (!infoExterno.nombre_completo.trim()) {
            nuevosErrores.nombre_completo = 'El nombre completo es obligatorio.';
        }
    
        if (!infoExterno.documento.trim()) {
            nuevosErrores.documento = 'El documento es obligatorio.';
        }
    
        if (!infoExterno.tipo.trim()) {
            nuevosErrores.tipo = 'El tipo externo es obligatorio.';
        }
    
        setErrores(nuevosErrores);
    
        return Object.keys(nuevosErrores).length === 0;
    };
    
    const handleChangeExterno = (e) => {
        const { name, value } = e.target;
    
        setInfoExterno({
            ...infoExterno,
            [name]: value,
        });
    
        if (errores[name]) {
            setErrores({
                ...errores,
                [name]: '', 
            });
        }
    };
    
    const handleRegistrarExterno = () => {
        if (validarCampos()) {
            registrarExterno();
        }
    };

    return(
        <div className='pb-4'>
            {sp ? <Spinner /> : ''}
            <TitleLayout data={datat} clase={"text-start"} icon={true} seccion={'control-ingresos'} />
            {/* ////////// INICIO MODAL EXTERNO ///////////// */}
            {
                showExterno &&
                <>
                    <div className="modal fade show" style={{display:'block'}} id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered ">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel">Nuevo usuario</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{setShowExterno(false)}}>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className="form-group ">
                                                <div className='text-dark fs-6'>Nombre completo</div>
                                                <input type="text" 
                                                    maxLength="80" 
                                                    className="form-control form-control-sm" 
                                                    id="nombre_completo" 
                                                    placeholder="Nombre completo"
                                                    name='nombre_completo'
                                                    value={infoExterno.nombre_completo}
                                                    onChange={handleChangeExterno} 
                                                />
                                                {errores.nombre_completo && (
                                                    <div className="text-danger text-start fs-6">{errores.nombre_completo}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className="form-group number">
                                                <div className='text-dark fs-6'>Documento</div>
                                                <input 
                                                    type="number" 
                                                    maxLength="15" 
                                                    className="form-control form-control-sm" 
                                                    id="documento" 
                                                    placeholder="Documento"
                                                    name='documento'
                                                    min="0"
                                                    value={infoExterno.documento}
                                                    onChange={handleChangeExterno}  
                                                />
                                                {errores.documento && (
                                                    <div className="text-danger fs-6">{errores.documento}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className="form-group">
                                                <div className='text-dark fs-6'>Tipo externo</div>
                                                <select 
                                                    className="form-select form-select-sm" 
                                                    style={{height: 'calc(2.25rem + -6px)', padding:'0'}}
                                                    id="tipo" 
                                                    name='tipo'
                                                    value={infoExterno.tipo}
                                                    onChange={handleChangeExterno}  
                                                >
                                                    <option value=""></option>
                                                    <option value="Mensajero">Mensajero</option>
                                                    <option value="Proveedor">Proveedor</option>
                                                    <option value="Visitante">Visitante</option>
                                                </select>
                                                {errores.tipo && (
                                                    <div className="text-danger fs-6">{errores.tipo}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className="form-group">
                                                <div className='text-dark fs-6'>Tipo de sangre</div>
                                                <select 
                                                    className="form-select form-select-sm" 
                                                    style={{height: 'calc(2.25rem + -6px)', padding:'0'}}
                                                    id="tipo_sangre" 
                                                    name='tipo_sangre'
                                                    value={infoExterno.tipo_sangre}
                                                    onChange={handleChangeExterno}  
                                                >
                                                    <option value=""></option>
                                                    <option value="o+">O+</option>
                                                    <option value="o-">O-</option>
                                                    <option value="a+">A+</option>
                                                    <option value="a-">A-</option>
                                                    <option value="b+">B+</option>
                                                    <option value="b-">B-</option>
                                                    <option value="ab+">AB+</option>
                                                    <option value="ab-">AB-</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className='text-center'>
                                        <div className='cursor-pointer btn-buscar m-3 height-fit mx-auto px-3' onClick={handleRegistrarExterno}>Registrar</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            }
            {/* ////////// FIN MODAL EXTERNO ///////////// */}
            { /* //////////// CONTENIDO ///////////////// */ }
            <div className='bg-white p-4 col-lg-10 col-md-10 col-sm-12 mx-auto' style={{height:'auto'}}>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a className="nav-link active fs-6 fw-bold " id="home-tab" data-bs-toggle="tab" href="#home" role="tab" data-bs-target="#home" aria-selected="true">Control Ingresos</a>
                    </li>
                    {
                        user && ((user.centro_costos === 'AREA GESTION HUMANA')|| (user.centro_costos === 'AREA SISTEMAS') || (user.nombre_cargo === 'DIRECTOR DE OPERACIONES')) &&
                        <li className="nav-item" role="presentation">
                            <a className="nav-link fs-6 fw-bold" id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab" data-bs-target="#profile" aria-selected="false" onClick={validate}>Reportes</a>
                        </li>
                    }
                    
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className='mt-4 d-flex flex-wrap text-center mx-auto head-ingresos'>

                            { lista === 'entrada' && <div className='cursor-pointer btn-buscar der m-3 height-fit fs-6' onClick={()=>{setShowExterno(true)}}>Crear Externo</div>}
                            {/* ////////////// ESTADISTICAS //////////////////////*/}
                            <div className='izq p-2'>
                                <div className='aforo'>
                                    <div>
                                        <img src="/assets/images/user.png" alt="" width="40" className='opacity5' />
                                    </div>
                                    <div className='text-center content-aforo text-dark fs-6'>
                                        Aforo
                                        <div className='fw-bold'>{aforo && aforo}</div>
                                    </div>
                                </div> 
                                
                            </div>
                            {/* //////////////  FIN ESTADISTICAS //////////////////////*/}
                        </div>
                        {/* ////////////// CONTENIDO //////////////////////*/}
                        <div className='text-center mt-4'>
                            <div>
                                <div className='fw-bold text-dark fs-6'>Ingrese nombre o documento</div>
                            <Select
                                className='select-size'
                                showSearch
                                value={state.value}
                                placeholder={''}
                                showArrow={true}
                                filterOption={false}
                                onSearch={handleSearch}
                                onChange={(value, option)=>handleChange(value, option)}
                                notFoundContent={<div className='text-center'>No hay datos</div>}
                            >
                            {options}
                            </Select>
                        </div>
                            <div className='d-flex justify-content-center mt-5 '>
                                <div className={`ingreso ${lista === 'entrada' ? 'activa' : 'text-dark'} fs-6 `} onClick={()=>{setLista('entrada');listPeople('entrada'); setFilterText('')}}>Listado Entrada</div>
                                <div className={`ingreso ${lista === 'salida' ? 'activa' : 'text-dark'} fs-6 `} onClick={()=>{setLista('salida'); listPeople('salida'); setFilterText('')}}>Listado Salida</div>
                            </div>
                            <div className='container-size mx-auto'>
                                <div className='ocultar-movil'>
                                    <DataTableComp 
                                        columns={ lista === 'entrada' ? columns : columnsSalida} 
                                        data={listadoIngresos && listadoIngresos} 
                                        paginationPerPage={20} 
                                        subHeaderComponent={subHeaderComponentMemo2}
                                        paginationComponentOptions={paginationComponentOptions}
                                        customStyles={customStyles2}
                                        noDataComponent={<span className="fs-16 text-center">En este momento no hay nada para mostrar</span>}
                                    />
                                </div>
                                <div className='mostrar-movil mt-4'>
                                <DataTableMovil  
                                    columns={lista === 'entrada' ? columnsMov : columnsSalidaMov} 
                                    data={listadoIngresos && listadoIngresos} 
                                    expandedComponent={expandedComponentDetalle} 
                                    paginationComponentOptions={paginationComponentOptions}
                                    customStyles={customStyles}
                                    subHeaderComponent={subHeaderComponentMemo2}
                                    paginationPerPage={20} 
                                />
                                </div>
                            </div>
                            </div>
                            
                            {/* ////////////// FIN CONTENIDO //////////////////////*/}
                    </div>
                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div className='d-flex flex-wrap w-100'>
                            <div className='m-2'>
                                <ConfigProvider locale={es_ES}>
                                    <RangePicker 
                                        style={{width:'100%'}}
                                        name='fecha'
                                        allowClear
                                        showToday
                                        onChange={(value, mode)=>{handleDate(value, mode)}}
                                        value={filtros.fecha.length>0 && [dayjs(filtros.fecha[0]), dayjs(filtros.fecha[1])]}
                                    />
                                </ConfigProvider>
                            </div>
                            <div className='m-2 w-auto'>
                                <Select
                                    style={{width:'200px'}}
                                    mode="multiple"
                                    allowClear
                                    className="select-filtros"
                                    showSearch
                                    placeholder="Seleccionar áreas"
                                    notFoundContent={<div className='text-center'>No hay datos</div>}
                                    optionFilterProp="children"
                                    value={filtros.area}
                                    name='grupo'
                                    onChange={(value, options)=>handleChange(value, options, 'grupos')}
                                    filterOption={(input, option) =>{
                                        if(option.children){
                                        return option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                        }else if(option.label){
                                        return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                        }
                                        }}
                                >
                                    {
                                        lAreas &&
                                        lAreas.map((u, indexu)=>( 
                                            <Option key={indexu} name="grupos" value={u.name}>{u.name}</Option>
                                         ))
                                    }
                                </Select>
                            </div>
                            <div className='m-2 d-flex'>
                                {/* <div className='mx-2 btn-filter fs-16' onClick={filtrar}>Filtrar</div> */}
                                <div className='mx-2 fs-6 btn-info2' onClick={exportar}>Exportar</div>
                                {/* <div className='mx-2 pt-1' onClick={cleanInfo}> <i className="fas fa-times fs-18 text-danger cursor-pointer"></i> </div> */}
                            </div>
                            
                        </div>
                        <div className='ocultar-movil'>
                            <DataTableComp 
                                columns={columnsReport} 
                                data={datosTable&& datosTable} 
                                paginationComponentOptions={paginationComponentOptions}
                                paginationPerPage={20} 
                                customStyles={customStyles2}
                                noDataComponent={<span className="fs-16 text-center">En este momento no hay nada para mostrar</span>}
                            />
                        </div>
                        <div className='mostrar-movil'>
                            <DataTableMovil  
                                columns={columnsReportMov } 
                                data={datosTable && datosTable}
                                expandedComponent={expandedComponentDetalleReporte} 
                                pagination={true}
                                paginationComponentOptions={paginationComponentOptions}
                                paginationPerPage={20}
                                customStyles={customStyles}
                            />
                        </div>
                        
                    </div>
                    
                    </div>
                </div>
            {/* ////////////// FIN CONTENIDO /////////////////////////// */}
        </div>
    )
}
export default Ingresos;