import './App.css';


import { LayoutMainRoutes } from './Routes/LayoutMainRoutes.js'

function App() {
  return (
    <LayoutMainRoutes/>
  );
}

export default App;
