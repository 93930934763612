import React, { useState, useEffect, useRef } from 'react';
import { CloseOutlined, ReloadOutlined, SendOutlined, MessageOutlined,MinusOutlined , LikeOutlined, DislikeOutlined } from '@ant-design/icons';
import { Button, Input, Tooltip, Divider } from "antd";
import Call from '../../config/Call';
import Swal from 'sweetalert2';
export const DEFAULT_MESSAGE = '¡Hola! Soy tu asistente virtual. 😊\nEstoy aquí para ayudarte con cualquier pregunta o duda relacionada con el sistema de Comerssia.\n¿Cómo puedo asistirte hoy?';

const { TextArea } = Input;
const Jhon = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [currentThreadId, setCurrentThreadId] = useState(null);
  const messagesEndRef = useRef(null);
  const [skipScroll, setSkipScroll] = useState(false)

  const typingMessages = [
    "Buena pregunta, pensando…",
    "Procesando tu consulta…",
    "Interesante, un momento…",
    "Analizando, espera poco…",
    "Revisando, dame tiempo…",
    "Pensando, un momento…",
    "Analizando tu idea…",
    "Procesando, espera poco…",
    "Interesante, revisando…",
    "Trabajando en ello…",
    "Dame un instante…",
    "Revisando, paciencia…",
    "Espera un momento…",
    "En proceso, espera…",
    "Un momento, pensando…",
    "Estoy revisando esto…",
    "Dame unos segundos…",
    "Analizando, ya voy…",
    "Procesando, dame tiempo…",
    "Espera un instante…"
  ];

  const getRandomTypingMessage = () => {
    const randomIndex = Math.floor(Math.random() * typingMessages.length);
    return typingMessages[randomIndex];
  };

  const showConfirmationAlert = async (title, text, action) => {
    const result = await Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    });
  
    if (result.isConfirmed) {
      try {
        if (currentThreadId) {
          const response = await Call('POST', '/api/openai/closethread/', true, { thread_id: currentThreadId }, false);
  
          if (response.status === 200) {
            action(); 
          } else {
            Swal.fire('Error', 'No se pudo realizar la acción. Inténtalo más tarde.', 'error');
          }
        } else {
          action();
        }
      } catch (error) {
        Swal.fire('Error', 'Hubo un problema al comunicarse con el servidor. Inténtalo nuevamente.', 'error');
      }
    }
  };
  
  const resetConversation = () => {
    showConfirmationAlert(
      '¿Estás seguro?',
      'Si reinicias el chat, perderás la conversación actual y se iniciará una nueva.',
      () => {
        setMessages([
          { id: 1, text: DEFAULT_MESSAGE, sender: 'assistant' }
        ]);
      }
    );
  };
  
  const handleClose = () => {
    showConfirmationAlert(
      '¿Estás seguro?',
      'Si cierras el chat, perderás la conversación actual.',
      () => {
        setIsOpen(false);
        setMessages([]);
      }
    );
  };

  const fetchMessages = async () => {
    try {
      
      const result = await Call('GET', '/api/openai/assistant/1/', true, null, false);
      const fetchedMessages = result?.data?.data || [];
      const threadId = result?.data?.thread_id;

      setCurrentThreadId(threadId)

      const formattedMessages = fetchedMessages.map((msg) => ({
        id: msg.id,
        message_id: msg.message_id,
        feedback: msg.status === null ? null : msg.status ? 1 : 0,
        text: msg.content || 'Sin contenido',
        isUser: msg.sender === 'user',
      }));

  
      setMessages([
        { id: 'default', text: DEFAULT_MESSAGE, isUser: false },
        ...formattedMessages,
      ]);
  
    } catch (error) {
      console.error('Error al obtener los mensajes:', error);
    }
  };
  
  const handleSend = async () => {
    if (input.trim()) {

      const userMessage = { id: Date.now(), text: input, isUser: true };
      setMessages((prev) => [...prev, userMessage]);
      setInput('');
      setIsTyping(true);
  
      try {
        const result = await Call('POST', '/api/openai/assistant/1/', true, { question: input }, false);
        const responseMessages = result?.data?.data || [];
        const filteredMessages = responseMessages.filter((msg) => msg.sender !== 'user');
        const threadId = result?.data?.thread_id;
        setCurrentThreadId(threadId)
  
        setMessages((prev) => [
          ...prev,
          ...filteredMessages.map((msg) => ({
            id: msg.id,
            message_id: msg.message_id,
            text: msg.content || 'Lo siento, no tengo una respuesta en este momento.',
            isUser: false,
            feedback: null,
          })),
        ]);
      } catch (error) {
        console.error('Error al enviar el mensaje:', error);
        setMessages((prev) => [
          ...prev,
          {
            text: 'Hubo un error al procesar tu pregunta. Por favor, intenta nuevamente.',
            isUser: false,
          },
        ]);
      } finally {
        setIsTyping(false);
      }
    }
  };

  const handleLikeClick = async (messageId, feedback) => {
    try {
      setSkipScroll(true); 

      const result = await Call('POST', '/api/openai/feedback/', true, {
        message_id: messageId,
        feedback: feedback,
      }, false);
  
      if (result.status === 200) {
        setMessages((prevMessages) =>
          prevMessages.map((msg) => 
            msg.message_id === messageId ? { ...msg, feedback } : msg
          )
        ); 
      }
    } catch (error) {
      console.error('Error al enviar feedback:', error);
    }  finally {
        setSkipScroll(false);
    }
  };
  
  const scrollToBottom = () => {
    if (!skipScroll) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchMessages();
    }
  }, [isOpen]);
  

  useEffect(() => {
    scrollToBottom();
    setSkipScroll(false);
  }, [messages]);
  

  if (!isOpen) {
    return (
      <button
        className="btn rounded-circle shadow position-fixed d-flex align-items-center justify-content-center"
        style={{ width: "56px", height: "56px", bottom: "10rem", right: "0.9rem", background: '#dc3545', color: 'white', zIndex: '2' }}
        onClick={() => setIsOpen(true)}
        aria-label="Iniciar chat"
      >
        <MessageOutlined style={{ fontSize: "24px" }} />
      </button>
    );
  }

  return (
    <div className='containerchat position-fixed bottom-0 end-0 m-3'>
      <div className="text-white p-2 d-flex justify-content-between align-items-center" style={{ background: '#dc3545' }}>
        <span className="fs-6" style={{ fontWeight: 'bold' }}>John</span>
        <div>
          <Tooltip title="Minimizar conversación">
              <Button
                type="text"
                icon={<MinusOutlined  />}
                onClick={() => setIsOpen(false)}
                style={{ color: 'white' }}
              />
          </Tooltip>
          <Tooltip title="Reiniciar conversación" placement="topRight">
            <Button
              type="text"
              icon={<ReloadOutlined />}
              onClick={resetConversation}
              style={{ color: 'white' }}
            />
          </Tooltip>
          <Tooltip title="Cerrar chat" placement="topRight">
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={handleClose}
              style={{ color: 'white' }}
            />
          </Tooltip>
        </div>
      </div>

      <div className='TextChat text-chat-container'>
        {messages.map((message, index) => (
          <div
            key={index}
            style={{
              display: message.isUser ? 'flex' : '',
              flexDirection: message.isUser ? 'row-reverse' : 'row',
              marginBottom: '10px',
            }}
          >
            <div
              style={{
                maxWidth: '90%',
                padding: message.isUser ? '8px' : '15px',
                borderRadius: message.isUser ? '16px 2px 16px 16px' : '2px 16px 16px',
                backgroundColor: message.isUser ? '#dc3545' : '#00000014',
                overflowWrap: 'break-word',
                whiteSpace: 'pre-wrap',
                color: message.isUser ? 'white' : 'black',
                wordBreak: 'break-word',
                fontSize: '16px',
                lineHeight: '1.5',
              }}
            >
              {/* Verifica si el mensaje contiene HTML */}
              {message.text.includes('<') ? (
                <div
                dangerouslySetInnerHTML={{ __html: message.text }}
                style={{
                  margin: 0,        
                  padding: 0,          
                  lineHeight: '1.5',
                  whiteSpace: 'normal',   
                }} />
              ) : (
                message.text.split('\n').map((line, idx) => (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                ))
              )}
            </div>

            {!message.isUser && message.text !== DEFAULT_MESSAGE && (
               <div>
                <LikeOutlined
                  className={`likeoutlined ${message.feedback === 1 ? 'selected' : ''}`}
                  onClick={() => handleLikeClick(message.message_id, 1)}
                  style={{
                    color: message.feedback === 1 
                      ? 'green' 
                      : message.feedback === 0 
                        ? 'rgba(0, 0, 0, 0.3)'
                        : 'black',
                    cursor: message.feedback === 1 ? 'not-allowed' : 'pointer',
                    pointerEvents: message.feedback !== null ? 'none' : 'auto',

                  }}
                />

                <DislikeOutlined
                  className={`dislikeoutlined ${message.feedback === 0 ? 'selected' : ''}`}
                  onClick={() => handleLikeClick(message.message_id, 0)}
                  style={{
                    color: message.feedback === 0 
                      ? 'red' 
                      : message.feedback === 1 
                        ? 'rgba(0, 0, 0, 0.3)'
                        : 'black',
                    cursor: message.feedback === 0 ? 'not-allowed' : 'pointer',
                    pointerEvents: message.feedback !== null ? 'none' : 'auto',
                  }}
                />

             </div>
            )}
          </div>
        ))}

        {isTyping && (
          <div className="message-bubble">
              <em>{getRandomTypingMessage()}</em>
              <div className="skeleton-container">
                <div className="skeleton-line"></div>
                <div className="skeleton-line"></div>
                <div className="skeleton-line short"></div>
              </div>
          </div>
        )}

        <div ref={messagesEndRef} />
      </div>

      <Divider style={{ margin: 0, border: 'none' }} />

      <div className="TextUser">
        <TextArea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onPressEnter={(e) => {
            if (!e.shiftKey) {
              handleSend();
              e.preventDefault();
            }
          }}
          placeholder="Escribe un mensaje..."
          autoSize={{ minRows: 1, maxRows: 3}}
        />
        <Button
          className="btn btn-danger"
          type="primary"
          icon={<SendOutlined style={{ fontSize: '18px' }} />}
          onClick={handleSend}
          disabled={!input.trim()}
        />
      </div>
    </div>
  );
};

export default Jhon;