import React, {useState, useEffect} from 'react';
import SignatureCanvas from 'react-signature-canvas';


export const Firma = ( props )=> {

    let initialState =  {firmaBase64:props.firmaBase64?props.firmaBase64:null,
                        penColor: props.penColor?props.penColor:'black', 
                        className:props.className?props.className:'sigCanvas border canvafirma',
                        ClassName_btnlimpiar:props.ClassName_btnlimpiar?props.ClassName_btnlimpiar:'btn btn-icon btn-info-clear',
						ClassName_btnguardar:props.ClassName_btnguardar?props.ClassName_btnguardar:'btn btn-icon btn-info-guardar'
                        }
    let sigPad = {}
    const [showfirma, setShowFirma] = useState(false)
    const {handleInputChange, indextp, pregunta, cambioDatos} = props

    const [config, setConfig] = useState(initialState)
    
    const clear = () => {
        if(showfirma){
            sigPad.clear()
        }else{
            sigPad.clear()
        }
        
        setConfig({...config,
            firmaBase64: null
          });
        handleInputChange(pregunta,indextp,{target: null})
    }

    const handleFirmSave = () => {
        setConfig({...config,
            firmaBase64: sigPad.getTrimmedCanvas().toDataURL("image/png")
          });
          handleInputChange(pregunta,indextp,{target: sigPad.getTrimmedCanvas().toDataURL("image/png")})
          cambioDatos()
    }

    useEffect(()=>{
        if (props.clear && props.clear ===true){
            clear()
        }
    }, [])
    // console.log(inputRef, 'sigPad')

    return (
        <>
        
            <div onClick={()=>{setShowFirma(true); clear()}} className="mb-2 text-danger-sub mostrar-firma cursor-pointer">Firmar</div>
            {
                showfirma ?
                <>
                    <SignatureCanvas 
                    penColor={config.penColor} 
                    canvasProps={{className: config.className}}
                    ref={(ref) => { sigPad = ref }}
                    backgroundColor={'rgb(255, 255, 255)'}
                    />
                    <span className={config.ClassName_btnguardar}
                        onClick={()=>{handleFirmSave(); setShowFirma(false)}}>
                        Guardar
                    </span>
                </>
                :
                <div className="mostrar-firma-des">
                    <SignatureCanvas 
                    penColor={config.penColor} 
                    canvasProps={{className: config.className, width: 1100}}
                    ref={(ref) => { sigPad = ref }}
                    resizeCanvas={false}
                    backgroundColor={'rgb(255, 255, 255)'}
                    // clearOnResize={false}
                    />
                    <span className={config.ClassName_btnguardar}
                        onClick={()=>{handleFirmSave(); setShowFirma(false)}}>
                        Guardar
                    </span>
                </div>
            }
          
           <div className='text-end w-100 mostrar-firma-des' >
				<span className={config.ClassName_btnlimpiar}
					onClick={clear} style={{marginTop: '-65px'}}>
					Limpiar Firma
				</span>
			</div>
            {
                (showfirma || config.firmaBase64 ) &&
                <div className='text-end w-100 mostrar-firma' >
                    <span className={config.ClassName_btnlimpiar}
                        onClick={clear} style={{marginTop: '-65px'}} >
                        Limpiar Firma
                    </span>
                </div>
            }
            
          <br/>
         
            {config.firmaBase64 ? (<> <label className="w-100">Vista Previa</label> <img className='img-prev img-fluid' alt='firma'  src={config.firmaBase64} /></>) : null}
          
        </>
    )
}